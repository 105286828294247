import React from 'react';
import Flex from 'components/Flex';
import { FormInput } from 'components/Input';
import { Title } from 'components/Typography';
import { observer } from 'mobx-react';
import { sceneStore } from 'stores';

const SceneSearch = observer(() => {
  return (
    <Flex
      direction="row-responsive"
      alignItems="center"
      justifyContent="space-between"
      margin={{ mobile: { bottom: 30 } }}
    >
      <Title size="sm">The Places You’ll Go!!!</Title>
      <FormInput
        type="text"
        placeholder="Search tags..."
        onChange={(event) => sceneStore.setFilterKeyword(event.target.value)}
      />
    </Flex>
  );
});

export default SceneSearch;
