import React from 'react';
import Page from 'containers/Page';
import SubscriptionSection from 'containers/SubscriptionSection';
import { Helmet } from 'react-helmet';

const Subscribe = () => {
  return (
    <Page>
      <Helmet>
        <title>Subscribe to MyWorld</title>
      </Helmet>
      <SubscriptionSection isPage />
    </Page>
  );
};

export default Subscribe;
