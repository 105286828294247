import React from 'react';
import Flex from 'components/Flex';
import { FormInput } from 'components/Input';
import { observer } from 'mobx-react';
import { usersStore } from 'stores';

const Search = observer(() => {
  return (
    <Flex
      direction="row-responsive"
      alignItems="center"
      justifyContent="flex-end"
      margin={{ vertical: 20, mobile: { bottom: 30 } }}
      padding={10}
    >
      <Flex flex={1}></Flex>
      <FormInput
        type="text"
        placeholder="Search members..."
        onChange={(event) => usersStore.setFilterKeyword(event.target.value)}
      />
    </Flex>
  );
});

export default Search;
