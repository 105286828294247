import React, { InputHTMLAttributes } from 'react';
import { FormGroup, FormInput, FormLabel } from 'components/Input';
import { IFlex } from 'components/Flex';
import type ValidationBaseStore from 'stores/validationBase';
import { observer } from 'mobx-react';
import { DataListOptions, PhoneDropDown } from 'components/Dropdown';
import { authStore } from 'stores';
interface IObserverFormGroup extends InputHTMLAttributes<HTMLInputElement> {
  containerProps?: IFlex & { withMargin?: boolean };
  flex?: string | number;
  children?: any;
  label?: boolean;
  whiteLabel?: boolean;
  labelFromPlaceholder?: boolean;
  store: ValidationBaseStore;
  field: string;
}

interface IObserverFormInput extends InputHTMLAttributes<HTMLInputElement> {
  store: ValidationBaseStore;
  field: string;
}

const TFormGroup = ({
  children,
  label,
  containerProps,
  flex,
  store,
  field,
  labelFromPlaceholder,
  whiteLabel,
  ...inputProps
}: IObserverFormGroup) => {
  const fieldObject = store.form.fields[field];
  const firebaseError = authStore.firebaseError ? authStore.firebaseError : false;
  const error =
    fieldObject.type === 'phone' ? fieldObject.error || firebaseError : fieldObject.error;
  const { required, ...inputBinderFields } = store.bindInput(field);
  return (
    <FormGroup flex={flex} {...containerProps} error={error}>
      {label || labelFromPlaceholder ? (
        <FormLabel htmlFor={field} white={whiteLabel} required={required}>
          {error || label || fieldObject.placeholder}
        </FormLabel>
      ) : null}
      {fieldObject.type === 'phone' ? (
        <PhoneDropDown
          id={field}
          {...inputBinderFields}
          {...inputProps}
          withCountry={!!store.form.fields.country}
          country={!store.form.fields.country?.error && store.form.fields.country?.value}
        />
      ) : (
        <FormInput id={field} {...inputBinderFields} {...inputProps} />
      )}
      {fieldObject?.listData && fieldObject?.list && (
        <DataListOptions id={fieldObject.list} listData={fieldObject.listData} />
      )}
      {children}
    </FormGroup>
  );
};

TFormGroup.defaultProps = {
  flex: 1,
};

export const ObservedFormGroup = observer(TFormGroup);
export const ObservedFormInput = observer(({ store, field, ...inputProps }: IObserverFormInput) => {
  return <FormInput id={field} {...store.bindInput(field)} {...inputProps} />;
});
