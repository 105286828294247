import { makeAutoObservable } from 'mobx';
import { hubsAuthModalStore, hubsAuthPopupModalStore } from './ui/modal';
export class HubsAuthStore {
  private iframe: Window | null = null;
  isAuthenticatedToHubs = false;
  private storageKey = '_isauthhubs';

  constructor() {
    makeAutoObservable(this);
    this.isAuthenticatedToHubs = !!localStorage.getItem(this.storageKey);
  }

  /**
   * @Deprecated
   * @private
   */
  private attachFrame() {
    const openFrame = () => {
      const iframe = document.createElement('iframe');
      iframe.src = `${process.env.REACT_APP_HUBS_URL}/signin?mw_auth=true&&mw_email=muhammad@polarstork.com`;
      iframe.id = 'hubs-iframe';
      document.body.prepend(iframe);
      return iframe;
    };
    this.iframe = openFrame().contentWindow;
  }

  authenticate(id: number | string) {
    return new Promise((resolve, reject) => {
      const onClose = () => {
        this.isAuthenticatedToHubs = true;
        localStorage.setItem(this.storageKey, 'true');
        hubsAuthModalStore.close();
        hubsAuthPopupModalStore.close();
        resolve(this.isAuthenticatedToHubs);
      };

      hubsAuthModalStore.show();

      const features = 'menubar=no, location=no, resizable=no,width=400,height=400,toolbar=no';
      const w = window.open(
        `${process.env.REACT_APP_HUBS_URL}/signin?mw_auth=true&&mw_email=myworld%2B${id}@polarstork.com`,
        'hubs_window',
        features,
      );

      const ifWindowOpened = () => {
        if (w) {
          w.focus();
          const timer = setInterval(function () {
            if (w.closed) {
              onClose();
              clearInterval(timer);
            }
          }, 1000);
          try {
            w.onbeforeunload = onClose;
          } catch (e) {}
        } else {
          hubsAuthModalStore.close();
          hubsAuthPopupModalStore.show();
          reject();
        }
      };

      ifWindowOpened();
    });
  }
}

export default new HubsAuthStore();
