import { authStore } from 'stores';
import { Resource } from 'services/api';
import { JsonObject } from 'types/general';
import ValidationBaseStore, { isUrl } from 'stores/validationBase';
import { countryNames, getCountryNames } from 'utils/countries';

const resource = new Resource('users');

export default class ProfileForm extends ValidationBaseStore {
  form = {
    fields: {
      first_name: {
        value: authStore.user?.firstName || '',
        rule: 'required|min:2',
        type: 'text',
        placeholder: 'First Name',
        error: false,
      },
      email: {
        value: authStore.user?.email || '',
        rule: 'required|email',
        type: 'email',
        placeholder: 'Email',
        error: false,
      },
      country: {
        value: authStore.user?.country || '',
        error: false,
        rule: `required|in:${countryNames}`,
        placeholder: 'Country',
        type: 'country',
        list: 'country-list',
        listData: getCountryNames(),
      },
      state: {
        value: authStore.user?.state || '',
        error: false,
        rule: 'required|min:4',
        placeholder: 'State / Province',
        type: 'text',
      },
      city: {
        value: authStore.user?.city || '',
        error: false,
        rule: 'required|min:4',
        placeholder: 'City',
        type: 'text',
      },
      website: {
        value: authStore.user?.website || '',
        error: false,
        rule: 'url',
        placeholder: 'Personal Website',
        type: 'text',
      },
      facebook: {
        value: authStore.user?.socialEntities.facebook || '',
        error: false,
        rule: 'social_media',
        placeholder: 'Facebook',
        type: 'text',
      },
      instagram: {
        value: authStore.user?.socialEntities.instagram || '',
        error: false,
        rule: 'social_media',
        placeholder: 'Instagram',
        type: 'text',
      },
      discord: {
        value: authStore.user?.socialEntities.discord || '',
        error: false,
        rule: 'social_media_username',
        placeholder: 'Discord',
        type: 'text',
      },
      twitter: {
        value: authStore.user?.socialEntities.twitter || '',
        error: false,
        rule: 'social_media',
        placeholder: 'Twitter',
        type: 'text',
      },
      linkedIn: {
        value: authStore.user?.socialEntities.linkedin || '',
        error: false,
        rule: 'social_media',
        placeholder: 'LinkedIn',
        type: 'text',
      },
      youtube: {
        value: authStore.user?.socialEntities.youtube || '',
        error: false,
        rule: 'social_media',
        placeholder: 'Youtube',
        type: 'text',
      },
    },
  };

  onSubmit = (e: any) => {
    console.log('Submitting');
    e.preventDefault();
    this.validateForm();
    if (authStore.user)
      if (this.isValid)
        resource.update(authStore.user?.id, this.serializeData()).then((resp) => {
          if (resp.ok) {
            authStore.user?.consume(resp.data as JsonObject);
          }
        });
  };

  serializeSocialField = (attribute: string, value: string) => {
    return { type: attribute, ...(isUrl(value) ? { uri: value } : { username: value }) };
  };

  serializeData = () => {
    return {
      email: this.form.fields['email'].value,
      info: {
        first_name: this.form.fields['first_name'].value,
        city: this.form.fields['city'].value,
        state: this.form.fields['state'].value,
        country: this.form.fields['country'].value,
        social_media: [
          this.form.fields['website'].value.length > 0 && {
            uri: this.form.fields['website'].value,
            type: 'personal',
          },
          this.form.fields['facebook'].value.length > 0 &&
            this.serializeSocialField('facebook', this.form.fields['facebook'].value),
          this.form.fields['instagram'].value.length > 0 &&
            this.serializeSocialField('instagram', this.form.fields['instagram'].value),
          this.form.fields['discord'].value.length > 0 && {
            username: this.form.fields['discord'].value,
            type: 'discord',
          },
          this.form.fields['twitter'].value.length > 0 &&
            this.serializeSocialField('twitter', this.form.fields['twitter'].value),
          this.form.fields['linkedIn'].value.length > 0 &&
            this.serializeSocialField('linkedin', this.form.fields['linkedIn'].value),
          this.form.fields['youtube'].value.length > 0 &&
            this.serializeSocialField('youtube', this.form.fields['youtube'].value),
        ].filter((f) => typeof f != 'boolean'),
      },
    };
  };
}
