import React from 'react';
import { observer } from 'mobx-react';
import Page from 'containers/Page';
import Welcome from './Sections/Welcome';
import Features from './Sections/Features';
import Platforms from './Sections/Platforms';
import Divider from 'components/Divider';
import Romulus from './Sections/Romulus';
import Mission from './Sections/Mission';
const Home = observer(() => {
  return (
    <Page isHome>
      <Welcome />
      <Platforms />
      <Features />
      <Divider />
      <Mission />
      <Romulus />
    </Page>
  );
});

export default Home;
