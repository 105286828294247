import { computed, makeObservable, observable } from 'mobx';
import { BaseListStore, BaseStore } from './base';
import { JsonObject } from 'types/general';
import { Resource } from 'services/api';
import { RootStore } from '../index';
import { AuthStore } from '../auth';

const service = new Resource('users');

export interface IUserSocials {
  type: string;
  username: string;
  uri: string;
}

export class UserModel extends BaseStore {
  city = '';
  country = '';
  gamerTag = '';
  phoneNumber = '';
  socialMedia?: IUserSocials[];
  socialEntities: { [name in string]: string } = {};

  constructor(parent: BaseListStore<UserModel> | AuthStore, data?: JsonObject) {
    super(parent);

    makeObservable(this, {
      city: observable,
      country: observable,
      gamerTag: observable,
      phoneNumber: observable,
      socialMedia: observable,
      socialEntities: observable,
      website: computed,
      socials: computed,
    });

    if (data) this.consume(data);
    this.bindService(service);
  }

  serialize: () => JsonObject = () => ({
    city: this.city,
    country: this.country,
    gamer_tag: this.gamerTag,
    created_at: this.createdAt,
    phone_number: this.phoneNumber,
    social_media: this.socialMedia,
  });

  consume(data: JsonObject): void {
    this.id = data.id;
    this.city = data.info.city;
    this.gamerTag = data.gamer_tag;
    this.country = data.info.country;
    this.phoneNumber = data.phone_number;
    this.createdAt = data.info.created_at;
    this.socialMedia = data.info.social_media;
    this.socialMedia?.forEach((s) => {
      if (s.type) this.socialEntities[s.type] = s.username.length > 0 ? s.username : s.uri;
    });
  }

  get website() {
    return this.socialMedia?.find((e) => e.type === 'personal')?.uri;
  }

  get socials() {
    return this.socialMedia?.filter((e) => e.type !== 'personal' && e.uri.length > 0);
  }
}

class UsersStore extends BaseListStore<UserModel> {
  sortKey = 'gamer_tag';
  constructor(parent: RootStore) {
    super(parent);
    this.bindService(service);
  }

  createNewObject(entry: any): UserModel {
    return new UserModel(this, entry);
  }
}

export default UsersStore;
