import React from 'react';
import colors from 'theme/colors';
import styled, { css } from 'styled-components';
import { Card } from 'components/Cards';
import { breakpointStyle } from 'utils/style';

const TitleContainer = styled.div`
  position: relative;
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  margin-bottom: 68px;
  ${breakpointStyle({
    content: css`
      margin-bottom: 0;
    `,
  })}
`;

const TitleContent = styled.div<{ content: string }>`
  position: absolute;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 80px;
  line-height: 125%;
  top: 0;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Press Start 2P';
  ::before {
    content: '${({ content }) => content}';
    margin-left: -30rem;
    top: 0;
  }

  ::after {
    content: '${({ content }) => content}';
    margin-right: -30rem;
    top: 0;
  }

  ${breakpointStyle({
    content: css`
      font-size: 32px;
      width: auto;
      ::before {
        margin-left: 0;
      }

      ::after {
        margin-right: 0;
      }
    `,
  })}
`;

export const OverlapTitle = (props: { content: string }) => {
  return (
    <TitleContainer>
      <TitleContent content={props.content}>{props.content}</TitleContent>
    </TitleContainer>
  );
};

export const Icon = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 25px;
  ${breakpointStyle({
    content: css`
      width: 75px;
      height: 75px;
    `,
  })}
`;

/**
 * @category Styled Component
 */
export const CardPlatforms = styled(Card)`
  width: 411px;
  height: 408px;
  border: 4px solid ${colors.card.borderSecondary};

  ${breakpointStyle({
    content: css`
      width: 100%;
      height: 308px;
    `,
  })}
`;
