import styled, { css } from 'styled-components';
import colors from 'theme/colors';
import { breakpointStyle, edgeStyle, EdgeStyleData } from 'utils/style';
import listIcon from 'assets/icons/bullet_list_orange.svg';

type sizeInsetData = {
  desktop?: [number, number];
  tablet?: [number, number];
  mobile?: [number, number];
};

type SizeStyleData = {
  xs?: sizeInsetData;
  sm?: sizeInsetData;
  md?: sizeInsetData;
  lg?: sizeInsetData;
};

type sizeType = 'xs' | 'sm' | 'md' | 'lg' | 'inherit';
type weightType = boolean | 'semi';
type alignmentType = 'start' | 'end' | 'center' | 'left' | 'right';

export interface ITypographyBase {
  size?: sizeType;
  bold?: weightType;
  align?: alignmentType;
  margin?: EdgeStyleData;
  padding?: EdgeStyleData;
  alignMobile?: alignmentType;
}
export interface ITypography {
  size?: sizeType;
  bold?: weightType;
  align?: alignmentType;
  margin?: EdgeStyleData;
  padding?: EdgeStyleData;
  alignMobile?: alignmentType;
  defaultSizes: SizeStyleData;
}

const alignmentStyle = css<ITypography>`
  text-align: ${({ align }) => align};
  ${({ alignMobile }) =>
    alignMobile &&
    breakpointStyle({
      content: css`
        text-align: ${alignMobile};
      `,
    })}
`;

// TODO: Handle default font-height to be 120.8% if screen value is not an array.
const sizeStyle = (defaultSizes: SizeStyleData, sizeType: sizeType) => {
  if (sizeType === 'inherit') {
    return css`
      font-size: inherit;
      line-height: inherit;
    `;
  }

  const setSize = ([fontSize, lineHeight]: [number, number]) => {
    return css`
      font-size: ${fontSize}px;
      line-height: ${lineHeight === 100 ? '27px' : lineHeight + '%'};
    `;
  };

  const result = [];
  if (Object.keys(defaultSizes).length === 0) {
    console.error('[sizeStyle]: No size type(s) are provided');
    return setSize([0, 0]);
  }

  const fontSizes = defaultSizes[sizeType];
  if (!fontSizes) {
    console.error(`[sizeStyle]: (${sizeType}) does not exists as default sizes`);
    return setSize([0, 0]);
  } else if (Object.keys(fontSizes).length === 0) {
    console.error(`[sizeStyle]: No screen type values is provided for this sizeType: ${sizeType}`);
    return setSize([0, 0]);
  }

  for (const [key, value] of Object.entries(fontSizes)) {
    if (!value) continue;
    const tmp = setSize(value);
    switch (key) {
      case 'mobile':
      case 'tablet':
        result.push(
          breakpointStyle({
            content: tmp,
          }),
        );
        break;
      default:
        result.push(tmp);
    }
  }

  return result;
};

const weightStyle = (weight?: weightType) => {
  const setWeight = (type: string) => css`
    font-weight: ${type};
  `;

  if (weight === 'semi') {
    return setWeight('semi-bold');
  } else if (weight === true) {
    return setWeight('bold');
  } else return setWeight('normal');
};

const Typography = styled.p<ITypography>`
  font-style: normal;
  font-family: Poppins;

  ${({ bold }) => weightStyle(bold)};
  color: ${({ color }) => color && color};
  ${({ margin }) => margin && edgeStyle('margin', margin)};
  ${({ padding }) => padding && edgeStyle('padding', padding)};
  ${({ defaultSizes, size }) => size && sizeStyle(defaultSizes, size)};

  ${alignmentStyle}
`;

Typography.defaultProps = {
  align: 'start',
  color: colors.text.primary,
};

/**
 * @category Styled Component
 */
export const RetroTitle = styled(Typography).attrs({
  as: 'h2',
  defaultSizes: {
    lg: { desktop: [80, 120.8], mobile: [32, 120.8] },
    md: { desktop: [50, 120.8], mobile: [32, 120.8] },
    sm: { desktop: [32, 120.8], mobile: [22, 120.8] },
    xs: { desktop: [22, 120.8] },
  },
})`
  font-family: 'Press Start 2P';
  text-transform: uppercase;
`;

RetroTitle.defaultProps = {
  size: 'md',
};

/**
 * @category Styled Component
 */
export const Title = styled(Typography).attrs((props) => ({
  as: 'h2',
  defaultSizes: {
    md: { desktop: [42, 120.8], mobile: [26, 125] },
    sm: { desktop: [26, 125] },
  },
  ...props,
}))`
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  ${({ margin }) => margin && edgeStyle('margin', margin)};
`;

Title.defaultProps = {
  size: 'md',
  bold: 'semi',
};

/**
 * @category Styled Component
 */
export const Subtitle = styled(Typography).attrs({
  as: 'h3',
  defaultSizes: {
    lg: { desktop: [26, 125] },
    md: { desktop: [20, 100], mobile: [18, 100] },
    sm: { desktop: [18, 100] },
  },
})``;

Subtitle.defaultProps = {
  size: 'md',
};

/**
 * @category Styled Component
 */
export const Text = styled(Typography).attrs((props) => ({
  as: 'p',
  ...props,
  defaultSizes: {
    lg: { desktop: [26, 125], mobile: [20, 125] },
    md: { desktop: [18, 100] },
    sm: { desktop: [14, 100] },
  },
}))``;

Text.defaultProps = {
  size: 'md',
};

/**
 * @category Styled Component
 */
export const TextHighlight = styled(Typography).attrs({
  as: 'h4',
  defaultSizes: {
    md: { desktop: [20, 125] },
    sm: { desktop: [18, 100] },
  },
})``;

TextHighlight.defaultProps = {
  bold: 'semi',
};

/**
 * @category Styled Component
 */
export const TextListItem = styled(Text).attrs({
  as: 'div',
  size: 'lg',
})`
  position: relative;
  padding-left: 42px;
  margin-bottom: 32px;
  ::before {
    top: 0;
    left: 0;
    content: '';
    width: 24px;
    height: 31px;
    position: absolute;
    background-image: url(${listIcon});
  }
`;

/**
 * @category Styled Component
 */
export const TextAction = styled(Text).attrs((props) => ({
  as: 'span',
  size: 'sm',
  margin: { left: 4 },
  ...props,
}))`
  cursor: pointer;
  text-decoration: underline;
`;

/**
 * @category Styled Component
 */
export const Paragraph = styled(Typography).attrs({
  as: 'p',
  defaultSizes: {
    lg: { desktop: [28, 120.8], mobile: [24, 120.8] },
    md: { desktop: [20, 125], mobile: [18, 100] },
    sm: { desktop: [14, 100] },
  },
})``;

Paragraph.defaultProps = {
  size: 'md',
  bold: 'semi',
};

/**
 * @category Styled Component
 */
export const Span = styled(Typography).attrs({
  as: 'span',
  defaultSizes: {
    lg: { desktop: [18, 100] },
    md: { desktop: [16, 100] },
  },
})``;

export default Typography;
