import Flex from 'components/Flex';
import Button from 'components/Button';
import styled, { css } from 'styled-components';
import { breakpointMobile, breakpointStyle } from 'utils/style';
import colors from 'theme/colors';

export const TutorialContainer = styled(Flex)`
  padding: 30px;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 100px;
  flex-direction: column;
  justify-contents: center;
  background-color: ${colors.card.bg};
  border: 1px solid ${colors.card.borderPrimary};

  ${breakpointMobile(css`
    flex-direction: column;
    margin-bottom: 40px;
    padding: 30px 20px;
  `)}
`;

interface ITutorialThumbnail {
  src?: string;
}

export const TutorialThumbnail = styled.div<ITutorialThumbnail>`
  width: 590px;
  height: 360px;
  display: block;
  box-sizing: border-box;
  border: 1px solid ${colors.card.borderPrimary};
  background: url('${(props) => props.src && props.src}');

  background-size: cover;

  ${breakpointMobile(css`
    width: 100%;
    height: calc((100vw - 92px) / 1.8);
  `)}
`;

export const TutorialTitleContainer = styled(Flex)`
  margin-bottom: 20px;

  * {
    text-align: center !important;
  }

  ${breakpointMobile(css`
    margin-bottom: 30px;
  `)}
`;

export const TutorialButton = styled(Button)`
  min-width: 145px;
  margin-top: 10px;

  ${breakpointStyle({
    content: css`
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    `,
  })}
`;

export const TutorialImage = styled.img`
  height: 100px;

  ${breakpointStyle({
    content: css`
      width: 100%;
      height: calc((100vw - 82px) / 1.5);
      margin-right: 0;
    `,
  })}
`;
