import styled, { css } from 'styled-components';
import { breakpointStyle, edgeStyle, EdgeStyleData } from 'utils/style';
import colors from 'theme/colors';

export interface IButton {
  wide?: boolean;
  light?: boolean;
  margin?: EdgeStyleData;
  padding?: EdgeStyleData;
}

const wideStyle = css<IButton>`
  min-width: 270px;
  ${breakpointStyle({
    content: css`
      min-width: 100%;
    `,
  })}
`;

const lightStyle = css<IButton>`
  color: #2cad52;
  background: #040733;
  border: 1px solid #2cad52;
`;

/**
 * Main button used through the project
 * @category Styled Component
 */
const Button = styled.button<IButton>`
  padding: 10px 0;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  border: none;
  /* identical to box height */

  color: #000000;
  background-color: ${colors.button.primary};
  cursor: pointer;

  transition: opacity 0.3s, color 0.3s;

  &:hover {
    opacity: 0.6;
    color: white;
  }

  ${({ wide }) => wide && wideStyle}
  ${({ light }) => light && lightStyle}

  ${({ margin }) => margin && edgeStyle('margin', margin)}
  ${({ padding }) => padding && edgeStyle('padding', padding)}
`;

Button.defaultProps = {
  wide: true,
};

export default Button;
