import React, { useState, useEffect } from 'react';
import { FormInput } from './Input';
import styled from 'styled-components';
import { getCountryExtNames, getCountryExtension } from 'utils/countries';
import Flex from './Flex';
import type { InputHTMLAttributes } from 'react';
const DataList = styled.datalist`
  option {
    font-size: 14px;
    padding: 8px 16px;
    line-height: 21px;
    font-family: 'Poppins';
  }
`;

interface IDataListOptions {
  id: string;
  listData: Array<any>;
}

export const DataListOptions = (props: IDataListOptions) => {
  return (
    <DataList id={props.id}>
      {props?.listData.map(({ key, value, text }) => (
        <option key={key} value={value}>
          {text}
        </option>
      ))}
    </DataList>
  );
};

/**
 * @category Styled Component
 */
const ExtensionInput = styled(FormInput)`
  width: 60px;
  padding: 9px 0 9px 8px;
  background-color: white;
  border-right: 1px solid black;
  transition: all 0.3s ease-out;
  &:focus {
    width: 180px;
  }
`;

/**
 * @category Styled Component
 */
const PhoneInput = styled(FormInput)`
  width: 100%;
  overflow: hidden;
`;

interface IPhoneDropDown extends InputHTMLAttributes<HTMLInputElement> {
  onChange: (e: any) => void;
  country?: string;
  withCountry?: boolean;
}

export class PhoneDropDown extends React.Component<IPhoneDropDown, { ext: string; phone: string }> {
  constructor(props: IPhoneDropDown) {
    super(props);
    this.state = { ext: '+1', phone: '' };
  }

  componentDidUpdate(prevProps: Readonly<IPhoneDropDown>) {
    if (this.props.withCountry && this.props.country)
      if (prevProps.country !== this.props.country) {
        console.log(this.props.country);
        this.setState({ ext: '+' + getCountryExtension(this.props.country) });
      }
    const { ext, phone } = this.state;
    if (ext !== '' || phone !== '') this.props.onChange({ target: { value: ext + phone } });
  }

  handleChange = (type: string) => (e: any) => {
    const { value } = e.target;
    if (type === 'phone' || type === 'ext') {
      if (type === 'phone') this.setState({ phone: value });
      else this.setState({ ext: value });
    }
  };
  render() {
    const { value, withCountry, country, onChange, ...restProps } = this.props;
    return (
      <Flex direction="row">
        <ExtensionInput
          type="text"
          value={this.state.ext}
          disabled={this.props.withCountry}
          list="extension-list"
          placeholder="Ext."
          onChange={this.handleChange('ext')}
        />
        <PhoneInput
          type="phone"
          pattern="[0-9]+"
          defaultValue={this.state.phone}
          placeholder="Phone"
          onChange={this.handleChange('phone')}
          {...restProps}
        />
        <DataListOptions id="extension-list" listData={getCountryExtNames()} />
      </Flex>
    );
  }
}

/**
 * @deprecated
 * @category Component
 * @param withCountry boolean represent wether or not the extension input is active or not. undefined by default
 * @param country string value of a country. undefined by default
 * @param onChange function that update parent state based on input data change
 * @param props additional props provided by {HTMLInputElement}
 */
export const DPhoneDropDown = ({
  value,
  withCountry,
  country,
  onChange,
  ...props
}: IPhoneDropDown) => {
  const [state, setstate] = useState({ ext: '+1', phone: '' });

  useEffect(() => {
    if (withCountry && country) updateState('ext', '+' + getCountryExtension(country));
  }, [country]);

  useEffect(() => {
    if (state.ext !== '' || state.phone !== '')
      onChange({ target: { value: state.ext + state.phone } });
  }, [state]);

  const updateState = (type: string, value: string) => {
    setstate((prevState) => ({ ...prevState, [type]: value }));
  };

  const handleChange = (type: string) => (e: any) => {
    const { value } = e.target;
    if (type === 'phone' || type === 'ext') {
      updateState(type, value);
    }
  };

  // Find another way for linting xD
  value == value;
  return (
    <Flex direction="row">
      <ExtensionInput
        type="text"
        defaultValue={state.ext}
        disabled={withCountry}
        list="extension-list"
        placeholder="Ext."
        onChange={handleChange('ext')}
      />
      <PhoneInput
        type="phone"
        pattern="[0-9]+"
        defaultValue={state.phone}
        placeholder="Phone"
        {...props}
        onChange={handleChange('phone')}
      />
      <DataListOptions id="extension-list" listData={getCountryExtNames()} />
    </Flex>
  );
};
