import Flex from './Flex';
import colors from 'theme/colors';
import styled, { css } from 'styled-components';
import { breakpointMobile, breakpointStyle, breakpointTab } from 'utils/style';

interface ICard {
  withShadow?: boolean;
}

const shadowStyle = css<ICard>`
  box-shadow: -10px 10px ${colors.card.shadowColor};
  ${breakpointStyle({
    content: css`
  box-shadow: -7px 7px ${colors.card.shadowColor};}`,
  })}
`;

/**
 * @category Styled Component
 */
export const Card = styled(Flex)<ICard>`
  ${({ withShadow }) => withShadow && shadowStyle};
  background-color: ${colors.card.bg};
`;

Card.defaultProps = {
  withShadow: false,
};

export const ShadowCard = styled(Card).attrs((props) => ({
  ...props,
  withShadow: true,
}))`
  border: 1px solid ${colors.text.success};
`;

/**
 * @category Styled Component
 */
export const CardCitizen = styled(Card)<ICard>`
  width: 720px;
  min-height: 214px;
  background: rgba(204, 204, 204, 0.05);
  margin-bottom: 40px;

  :nth-child(odd) {
    margin-right: 48px;
  }

  ${breakpointTab(css`
    width: 526px;
  `)}

  ${breakpointMobile(css`
    width: 100%;
    :nth-child(odd) {
      margin-right: 0;
    }
  `)}
`;
