import React from 'react';
import Link from 'components/Link';
import {
  FooterContainer,
  FooterSubContainer,
  FooterWrapper,
  ParentContainer,
  Text,
} from './styled';
export interface IFooter {
  size?: 'medium' | 'large';
}

const Footer = (props: IFooter) => {
  return (
    <FooterWrapper>
      <ParentContainer>
        <FooterContainer as="footer" {...props}>
          <Text as={Link} href="https://myworldglobal.com" target="_blank" margin="0">
            Powered by myworldglobal.com
          </Text>
          <FooterSubContainer>
            <Text as={Link} href="http://www.myworldglobal.info/" target="_blank" margin="0">
              About Us
            </Text>
            <Text as={Link} href="/terms-and-conditions" margin="0">
              Terms of Services
            </Text>
            <Text as={Link} href="/privacy-policy" margin="0">
              Privacy Policy
            </Text>
          </FooterSubContainer>
        </FooterContainer>
      </ParentContainer>
    </FooterWrapper>
  );
};

Footer.defaultProps = {
  size: 'medium',
};

export default Footer;
