import styled, { css } from 'styled-components';
import { Card } from 'components/Cards';
import colors from 'theme/colors';
import { breakpointStyle } from 'utils/style';

/**
 * @category Styled Component
 */
export const CardFeatures = styled(Card).attrs({
  withShadow: true,
  justifyContent: 'center',
})`
  width: 365px;
  height: 174px;
  border: 2px solid ${colors.card.borderPrimary};
  padding: 0 40px;
  margin-right: 38px;
  margin-bottom: 43px;
  ${breakpointStyle({
    content: css`
      width: 268px;
      height: 124px;
      padding: 0 26px;
      margin-right: 0;
      margin-bottom: 25px;
    `,
  })}
`;
