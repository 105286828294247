import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import {
  breakpointStyle,
  BREAK_POINTS,
  edgeStyle,
  EdgeStyleData,
  parseStyleValue,
  breakpointTab,
} from 'utils/style';

type itemParams =
  | 'baseline'
  | 'center'
  | 'end'
  | 'flex-end'
  | 'flex-start'
  | 'start'
  | 'normal'
  | 'auto'
  | 'stretch';
type contentParams =
  | 'center'
  | 'end'
  | 'flex-end'
  | 'flex-start'
  | 'start'
  | 'normal'
  | 'stretch'
  | 'right'
  | 'left'
  | 'space-around'
  | 'space-between'
  | 'space-evenly';
type directionParams = 'row' | 'column' | 'row-responsive';
type wrapParams = 'wrap' | 'no-wrap' | 'wrap-reverse';
type platformParams = 'mobile' | 'desktop' | 'tab-below';

export interface IFlex {
  direction?: 'row' | 'column' | 'row-responsive';
  flex?: number | string;
  flexMobile?: number | string;
  alignItems?: itemParams;
  alignSelf?: itemParams;
  justifyItems?: itemParams;
  alignContent?: contentParams;
  justifyContent?: contentParams;
  padding?: EdgeStyleData;
  margin?: EdgeStyleData;
  wrap?: wrapParams;
  maxWidth?: number | string;
  minWidth?: number | string;
  width?: number | string;
  maxWidthBase?: number;
  breakPointWidth?: { [index: string]: number };
  hide?: platformParams;
}

const flexStyle = css<IFlex>`
  flex: ${(props) => props.flex};
  ${(props) =>
    props.flexMobile !== (undefined || null) &&
    breakpointStyle({
      content: css`
        flex: ${props.flexMobile};
      `,
    })}
`;
const flexDirectionStyle = (direction: directionParams) => {
  const styleArray = [
    css<IFlex>`
      flex-direction: ${(props) =>
        props.direction === 'row-responsive' ? 'row' : props.direction};
    `,
  ];

  if (direction === 'row-responsive') {
    styleArray.push(
      breakpointStyle({
        content: css`
          flex-direction: column;
          flex-basis: auto;
          justify-content: flex-start;
          align-items: stretch;
          flex-shrink: 0;
        `,
      }),
    );
  }

  return styleArray;
};

const alignItemsStyle = css<IFlex>`
  align-items: ${(props) => props.alignItems};
`;
const alignContentStyle = css<IFlex>`
  align-content: ${(props) => props.alignContent};
`;
const alignSelfStyle = css<IFlex>`
  align-self: ${(props) => props.alignSelf};
`;
const justifyItemsStyle = css<IFlex>`
  justify-items: ${(props) => props.justifyItems};
`;
const justifyContentStyle = css<IFlex>`
  justify-content: ${(props) => props.justifyContent};
`;
const flowStyled = css<IFlex>`
  flex-wrap: ${(props) => props.wrap};
`;
const maxWidthStyle = css<IFlex>`
  max-width: ${(props) => props.maxWidth && parseStyleValue(props.maxWidth)};
`;
const minWidthStyle = css<IFlex>`
  min-width: ${(props) => props.minWidth && parseStyleValue(props.minWidth)};
`;

const widthStyle = css<IFlex>`
  width: ${(props) => props.width && parseStyleValue(props.width)};
`;
const breakPointWidthStyle = ({ breakPointWidth }: IFlex) => {
  const styles: FlattenSimpleInterpolation[] = [];

  for (const key in breakPointWidth) {
    if (parseInt(key) > BREAK_POINTS.mobile) {
      styles.push(
        breakpointStyle({
          breakpoint: {
            type: 'min',
            value: parseInt(key),
          },
          content: css`
            width: ${breakPointWidth[key]}px;
          `,
        }),
      );
    }
  }

  return styles;
};

const hiddenStyle = (props: IFlex) => {
  const style = css`
    display: none;
  `;
  switch (props.hide) {
    case 'mobile':
      return breakpointStyle({
        content: style,
      });
    case 'tab-below':
      return breakpointTab(style);
    case 'desktop':
      return [
        style,
        breakpointStyle({
          content: css`
            display: flex !important;
          `,
        }),
      ];
    default:
      return;
  }
};

/**
 * Flex Box
 * @category Styled Component
 * @desc component that is based on flex box present a div with display: flex;
 */
const Flex = styled.div<IFlex>`
  display: flex;
  box-sizing: border-box;
  ${(props) => props.flex && flexStyle}
  ${(props) => props.direction && flexDirectionStyle(props.direction)}
  ${(props) => props.alignItems && alignItemsStyle}
  ${(props) => props.justifyItems && justifyItemsStyle}
  ${(props) => props.alignContent && alignContentStyle}
  ${(props) => props.alignSelf && alignSelfStyle}
  ${(props) => props.justifyContent && justifyContentStyle}
  ${(props) => props.wrap && flowStyled}
  ${(props) => props.padding && edgeStyle('padding', props.padding)}
  ${(props) => props.margin && edgeStyle('margin', props.margin)}
  ${(props) => props.maxWidth && maxWidthStyle}
  ${(props) => props.minWidth && minWidthStyle}
  ${(props) => props.width && widthStyle}
  ${(props) => props.breakPointWidth && breakPointWidthStyle(props)}
  ${hiddenStyle}
`;

Flex.defaultProps = {
  direction: 'column',
};

export default Flex;
