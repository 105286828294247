import Flex from 'components/Flex';
import styled, { css } from 'styled-components';
import { breakpointStyle, BREAK_POINTS } from 'utils/style';

/**
 * @category Styled Component
 */
export const GamerTag = styled.h3`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 100%;
  color: #ffffff;
  margin-top: 31px;
  margin-bottom: 10px;
  ${breakpointStyle({
    content: css`
      margin-top: 20px;
    `,
  })}
`;

/**
 * @category Styled Component
 */
export const InfoBlock = styled(Flex).attrs({
  direction: 'row',
  alignItems: 'center',
})`
  ${breakpointStyle({
    breakpoint: { type: 'min', value: BREAK_POINTS.mobile },
    content: css`
      :not(:last-of-type) {
        &:after {
          content: '';
          width: 0;
          height: 16px;
          margin: 0px 14px;
          border-right: 1px solid #ffffff;
        }
      }
    `,
  })}
  ${breakpointStyle({
    content: css`
      :last-of-type {
        margin-bottom: 10px;
      }
    `,
  })}
`;

// export default SocialHolder;
