import styled, { css } from 'styled-components';
import Flex from 'components/Flex';
import { breakpointStyle, breakpointTab } from 'utils/style';
import closeIcon from 'assets/images/close_icon.svg';
import menuIcon from 'assets/images/menu_icon.svg';
import logo from 'assets/icons/mwg_logo.svg';
import colors from 'theme/colors';

export const HeaderContainer = styled(Flex)`
  top: 0;
  left: 0;
  width: 100%;
  position: absolute;
  //padding: 60px 100px;
  box-sizing: border-box;
`;

export const MobileNavContainer = styled(Flex).attrs({ direction: 'row' })<{ active: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.sidebar.test};
  z-index: 999;
  ${({ active }) =>
    !active
      ? css`
          display: none;
        `
      : null}
`;

export const MobilNavSubContainer = styled(Flex)`
  background-color: ${colors.sidebar.bg};
  height: 100vh;
  width: 80%;
`;

export const CloseButton = styled.div`
  width: 18px;
  height: 18px;
  background: url('${closeIcon}');
`;
export const MenuButton = styled(Flex)`
  width: 26px;
  height: 26px;
  background: url('${menuIcon}');
`;

export const MobileLink = styled(Flex)`
  font-family: Poppins;
  font-style: normal;
  font-size: 18px;
  color: white;
  padding: 16px 0 16px 0;
  line-height: 27px;
  text-decoration: none;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  &.active {
    font-weight: bold;
    color: #2cad52;

    &:before {
      content: '';
      margin-right: 5px;
      height: 6px;
      width: 6px;
      border-radius: 50%;
      display: inline-block;
      vertical-align: 1px;
      background-color: ${colors.text.success};
    }
  }
`;

export const HeaderLogo = styled.img.attrs({
  src: logo,
  alt: 'MWG Logo',
})`
  height: 67px;

  ${breakpointTab(css`
    height: calc(67px - ((67px - 37px) / 2));
  `)}
  ${breakpointStyle({
    content: css`
      height: 37px;
    `,
  })}
`;
