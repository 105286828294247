import styled, { css } from 'styled-components';
import Flex from 'components/Flex';
import type { IFooter } from '.';
import { breakpointStyle } from 'utils/style';
import Container from 'components/Container';

export const FooterWrapper = styled(Flex)`
  position: relative;
`;

export const ParentContainer = styled(Container)`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

export const FooterContainer = styled(Flex).attrs({
  direction: 'row-responsive',
})<IFooter>`
  height: 80px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  justify-content: space-between;
  align-items: center;
  ${breakpointStyle({
    content: css`
      justify-content: center;
      height: 170px;
      margin-top: 0;
    `,
  })}
`;

export const FooterSubContainer = styled(Flex).attrs({
  direction: 'row-responsive',
})<IFooter>`
  a:after {
    content: '|';
    margin: 0 7.5px;
  }

  a:last-child:after {
    content: '';
    margin: 0;
  }

  align-items: center;
  ${breakpointStyle({
    content: css`
      a:after {
        content: none;
      }
      justify-content: center;
    `,
  })}
`;

export const Text = styled.div`
  font-size: 14px;
  line-height: 21px;
  font-family: Poppins;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.8);
  ${breakpointStyle({
    content: css`
      :not(:last-child) {
        margin-bottom: 25px;
      }
    `,
  })}
`;
