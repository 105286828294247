import { observer } from 'mobx-react';
import Modal from '../components/Modal';
import { Paragraph, Title } from '../components/Typography';
import React from 'react';
import { hubsAuthModalStore, hubsAuthPopupModalStore } from '../stores/ui/modal';
import Flex from '../components/Flex';
import Button from '../components/Button';
import hubsAuth from '../stores/hubsAuth';
import { authStore, navStore } from '../stores';

const HubAuthModal = observer(() => {
  return (
    <Modal show={hubsAuthModalStore.open}>
      <Modal.Header>
        <Title size="sm" margin="0">
          Configuring MyWorld Identity
        </Title>
      </Modal.Header>
      <Modal.Body>
        <Paragraph>Please wait while identity configuration is done</Paragraph>
        <Paragraph>
          Do not refresh the page or close it, you will be redirected when configuration is complete
        </Paragraph>
      </Modal.Body>
    </Modal>
  );
});

export const HubAuthPopupModal = observer(() => {
  return (
    <Modal show={hubsAuthPopupModalStore.open}>
      <Modal.Header>
        <Title size="sm" margin="0">
          Please allow popups on this page
        </Title>
      </Modal.Header>
      <Modal.Body>
        <Paragraph>In order for us to be able to fully authenticate you</Paragraph>
        <Paragraph>You should allow popups on this site and try again</Paragraph>
      </Modal.Body>
      <Modal.Footer direction="row-responsive" justifyContent="space-between">
        <Flex padding={{ mobile: { right: 0, bottom: 10 } }} width="100%">
          <Button
            wide={false}
            light
            onClick={() =>
              hubsAuth.authenticate(authStore.userId).then(() => {
                navStore.history.replace('/portal');
              })
            }
          >
            Retry
          </Button>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
});

export default HubAuthModal;
