import { create, ApisauceInstance } from 'apisauce';
import { IRegBody } from 'types/user';
import { JsonObject } from 'types/general';

const MW_BASE_URL = process.env.REACT_APP_MW_BASE_URL;

export default class Api {
  static _api: ApisauceInstance;

  static createNewApi(props?: any) {
    Api._api = create({
      baseURL: MW_BASE_URL,
      ...props,
    });

    Api._api.setHeader('Access-Control-Allow-Origin', '*');
  }

  static get client() {
    return Api._api;
  }

  static clearToken() {
    Api.createNewApi();
  }

  static addAuthHeader(token: string) {
    Api._api.setHeader('PS-Authorization', `Bearer ${token}`);
  }

  static getUserInfo() {
    return Api._api.get('/api/users/me');
  }

  static registerUser(data: IRegBody) {
    return Api._api.post('/register', data);
  }

  static getRooms() {
    return Api._api.get('/api/rooms');
  }

  static getRoom(id: number) {
    return Api._api.get(`/api/rooms/${id}`);
  }

  static getSessionToken() {
    return Api._api.post('/api/hubs/request_session');
  }

  static checkAvailability(params: { gamer_tag?: string; email?: string; phone_number?: string }) {
    return Api._api.get('/api/users/availability', params);
  }
}

export class Resource {
  resource: string;

  constructor(resource: string) {
    this.resource = resource;
  }

  get uri() {
    return `/api/${this.resource}`;
  }

  uriSingle(id: number | string) {
    return `${this.uri}/${id}`;
  }

  getAll = (sortKey?: string, sortType: 'ASC' | 'DESC' = 'ASC') =>
    Api._api.get(this.uri, sortKey && { sort: `["${sortKey}","${sortType}"]` });
  get = (id: number | string) => Api._api.get(this.uriSingle(id));
  update = (id: number | string, data: JsonObject) => Api._api.put(this.uriSingle(id), data);
  create = (data: JsonObject) => Api._api.post(this.uri, data);
  delete = (id: number | string) => Api._api.delete(this.uriSingle(id));
}
