import React from 'react';
import Flex from 'components/Flex';
import Section, { BACKGROUNDS } from 'components/Section';
import { Paragraph, Title } from 'components/Typography';

function Mission() {
  return (
    <Section
      id="future"
      direction="column"
      alignItems="flex-end"
      bg={BACKGROUNDS.girl}
      justifyContent="center"
      container
    >
      <Flex
        maxWidth={660}
        direction="column"
        alignItems="start"
        margin={{ bottom: 80, mobile: { bottom: 60 } }}
      >
        <Paragraph size="sm">In MyWorld - Privacy is your Right, not an Option</Paragraph>
        <Title margin="0">The MyWorld Mission</Title>
        <Paragraph size="md">
          - To Ethically rebuild and redistribute the world wide web with 20/20 hindsight
        </Paragraph>
        <Paragraph size="md">
          - To Build the future of tech with a larger emphasis on the future humanity
        </Paragraph>
        <Paragraph size="md">
          - To Curate the world&apos;s largest online Global Community, to build a world without
          borders and relationships with no boundaries
        </Paragraph>
      </Flex>
      {/* <Button>{section_5.buttonText}</Button> */}
    </Section>
  );
}

export default React.memo(Mission);
