import type { RootStore } from './index';
import { History, createBrowserHistory } from 'history';
import { authRoutes, routes, toObject, toPaths } from 'router/routes';
import { when } from 'mobx';

const flatRoutes = toPaths(routes);
const flatAuthRoutes = toPaths(authRoutes);
const objectRoutes = toObject(routes);

export class NavStore {
  history: History;
  parent: RootStore;

  constructor(parent: RootStore) {
    this.history = createBrowserHistory();
    this.parent = parent;
    this.monitorRouteAccess();
  }

  push = (route: string, isHard = false) => {
    if (isHard) {
      window.location.href = route;
    } else {
      this.history.push(route);
    }
  };

  replace = (route: string, isHard = false) => {
    if (isHard) {
      window.location.replace(route);
    } else {
      this.history.replace(route);
    }
  };

  monitorRouteAccess = async () => {
    const { authStore } = this.parent;
    const { pathname } = this.history.location;
    if (flatRoutes.includes(pathname)) {
      if (flatAuthRoutes.includes(pathname)) {
        if (!authStore.isInitialized) {
          await when(() => authStore.isInitialized);
        }
        if (!authStore.isLoggedIn) {
          this.replace(objectRoutes['signup'].path);
        }
      }
    }
    this.history.listen(() => {
      if (this.parent.appStore.menuActive) {
        this.parent.appStore.closeMenu();
      }
    });
  };
}
