import { computed, makeObservable, observable } from 'mobx';
import { Resource } from 'services/api';
import { ITags, JsonObject } from 'types/general';
import { BaseListStore, BaseStore } from './base';
import { RootStore } from '../index';

const service = new Resource('scenes?sort=["name","ASC"]');

export enum SceneStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
}

export interface ISceneTutorial {
  data?: SceneModel;
}

export class SceneModel extends BaseStore {
  url = '';
  name = '';
  owner = '';
  hubsId = '';
  snapshot = '';
  isFeatured = false;
  status?: SceneStatus;
  tags?: Array<ITags>;

  constructor(parent: BaseListStore<SceneModel>, data?: JsonObject) {
    super(parent);

    makeObservable(this, {
      url: observable,
      name: observable,
      tags: observable,
      status: observable,
      hubsId: observable,
      owner: observable,
      snapshot: observable,
      isFeatured: observable,
      visibleTags: computed,
      visibleName: computed,
    });

    if (data) this.consume(data);
    this.bindService(service);
  }

  serialize = () => ({
    name: this.name,
    tags: this.tags,
    status: this.status,
    is_featured: this.isFeatured,
    owner: this.owner,
  });

  consume(data: JsonObject): void {
    this.id = data.id;
    this.url = data.url;
    this.name = data.name;
    this.tags = data.tags;
    this.status = data.status;
    this.hubsId = data.hubs_id;
    this.snapshot = data.snapshot;
    this.isFeatured = data.is_featured;
    this.owner = data.owner.gamer_tag;
  }

  openScene = (): void => {
    window.open(this.url);
  };

  get visibleTags(): string | undefined {
    if (this.tags) {
      return this.tags
        ?.slice(0, 3)
        .map((element) => element.name)
        ?.join(', ');
    }
    return undefined;
  }

  get visibleName(): string | undefined {
    return this.name?.length > 42 ? this.name.substring(0, 42) + '...' : this.name;
  }
}

class SceneStore extends BaseListStore<SceneModel> {
  constructor(parent: RootStore) {
    super(parent);
    this.bindService(service);
    makeObservable(this, {
      featured: computed,
      public: computed,
    });
  }

  createNewObject(entry: any): SceneModel {
    return new SceneModel(this, entry);
  }

  // TODO: We can improve or combine the below functions into one using parameters and rules

  get featured() {
    return this.rawData.filter((scene) => scene.isFeatured);
  }

  get public(): SceneModel[] {
    return this.data.filter((scene) => !scene.isFeatured && scene.status === SceneStatus.PUBLISHED);
  }
}

export default SceneStore;
