import Flex from './Flex';
import theme from 'theme/colors';
import React, { useState } from 'react';
import { IconOpen, IconClosed } from './Icon';
import styled, { css } from 'styled-components';
import { breakpointStyle } from 'utils/style';

interface IAccordion {
  title: string;
  children?: React.ReactNode;
}

interface IAccordionTab {
  active: boolean;
}

const tabActiveStyle = css<IAccordionTab>`
  background: ${theme.accordion.bgSecondary};
  box-shadow: inset 0 0 1px 1px ${theme.accordion.shadowSecondary};
`;

const Tab = styled(Flex).attrs({
  as: 'button',
  direction: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
})<IAccordionTab>`
  border: 0;
  cursor: pointer;
  background: ${theme.accordion.bgPrimary};
  box-shadow: inset 0 0 1px 1px ${theme.accordion.shadowPrimary};

  :hover {
    opacity: 0.6;
  }

  margin-top: 40px;
  padding: 15px 34px 15px 20px;

  // TODO: USE TYPOGRAPHY INSTEAD
  color: white;
  font-size: 20px;
  font-weight: 600;
  line-height: 125%;
  font-style: normal;
  font-family: Poppins;

  ${breakpointStyle({
    content: css`
      margin-top: 30px;
      padding: 8px 16px 8px 16px;
      // TODO: USE TYPOGRAPHY INSTEAD
      font-size: 18px;
    `,
  })}

  ${({ active }) => active && tabActiveStyle}
`;

const panelActiveStyle = css<IAccordionTab>`
  display: block;
`;

const Panel = styled.div<IAccordionTab>`
  margin-top: 30px;
  overflow: hidden;
  display: none;

  transition: all 0.3s ease-out;
  ${({ active }) => active && panelActiveStyle}
`;

const Accordion = (props: IAccordion) => {
  const [active, setActive] = useState(false);
  return (
    <>
      <Tab active={active} onClick={() => setActive(!active)}>
        <div>{props.title}</div>
        {active ? <IconClosed /> : <IconOpen />}
      </Tab>
      <Panel active={active}>{props.children}</Panel>
    </>
  );
};

export default Accordion;
