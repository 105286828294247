import { computed, makeObservable, observable } from 'mobx';
import { Resource } from 'services/api';
import { ITags, JsonObject } from 'types/general';
import { BaseListStore, BaseStore } from './base';
import { RootStore } from '../index';

const service = new Resource('rooms?sort=["name","ASC"]');

export enum RoomType {
  PAID = 'paid',
  PUBLIC = 'public',
  PRIVATE = 'private',
}

export enum RoomStatus {
  PUBLISHED = 'published',
  DRAFT = 'draft',
}

export interface IRoomTutorial {
  data?: RoomModel;
}

export class RoomModel extends BaseStore {
  url = '';
  size = 0;
  name = '';
  lobby = 0;
  image = '';
  hubsId = '';
  type?: RoomType;
  isFeatured = false;
  isTutorial = false;
  status?: RoomStatus;
  tags?: Array<ITags>;

  constructor(parent: BaseListStore<RoomModel>, data?: JsonObject) {
    super(parent);

    makeObservable(this, {
      url: observable,
      name: observable,
      type: observable,
      size: observable,
      tags: observable,
      lobby: observable,
      status: observable,
      image: observable,
      hubsId: observable,
      isFeatured: observable,
      isTutorial: observable,
      visibleTags: computed,
      visibleName: computed,
    });

    if (data) this.consume(data);
    this.bindService(service);
  }

  serialize = () => ({
    name: this.name,
    size: this.size,
    type: this.type,
    tags: this.tags,
    status: this.status,
    is_featured: this.isFeatured,
    is_tutorial: this.isTutorial,
  });

  consume(data: JsonObject): void {
    this.id = data.id;
    this.url = data.url;
    this.name = data.name;
    this.size = data.size;
    // TODO: update on lobby counter implementation
    this.type = data.type;
    this.tags = data.tags;
    this.lobby = data.size;
    this.status = data.status;
    this.image = data.snapshot;
    this.hubsId = data.hubs_id;
    this.isFeatured = data.is_featured;
    this.isTutorial = data.is_tutorial;
  }

  openRoom = (): void => {
    window.open(this.url);
  };

  get visibleTags(): string | undefined {
    if (this.tags) {
      return this.tags
        ?.slice(0, 3)
        .map((element) => element.name)
        ?.join(', ');
    }
    return undefined;
  }

  get visibleName(): string | undefined {
    return this.name?.length > 42 ? this.name.substring(0, 42) + '...' : this.name;
  }
}

class RoomStore extends BaseListStore<RoomModel> {
  constructor(parent: RootStore) {
    super(parent);
    this.bindService(service);
    makeObservable(this, {
      featured: computed,
      tutorial: computed,
      public: computed,
    });
  }

  createNewObject(entry: any): RoomModel {
    return new RoomModel(this, entry);
  }

  // TODO: We can improve or combine the below functions into one using parameters and rules
  get featured() {
    return this.data.filter((room) => room.isFeatured);
  }

  get public(): RoomModel[] {
    return this.data.filter(
      (room) =>
        !room.isTutorial && room.status === RoomStatus.PUBLISHED && room.type === RoomType.PUBLIC,
    );
  }

  get tutorial(): IRoomTutorial {
    // TODO: Sort Tutorial by latest updated
    const tut = this.data.filter((room) => room.isTutorial);
    return {
      data: tut[0] || undefined,
    };
  }
}

export default RoomStore;
