import { action, makeObservable } from 'mobx';
import { authStore } from 'stores';
import ValidationBaseStore from 'stores/validationBase';

class LoginForm extends ValidationBaseStore {
  form = {
    fields: {
      phone: {
        value: '',
        error: false,
        rule: 'required|phone|registered_phone',
        placeholder: 'Phone',
        type: 'phone',
      },
    },
  };

  constructor() {
    super();
    makeObservable(this, {
      onSubmit: action.bound,
    });
  }

  onSubmit = (e: any) => {
    e.preventDefault();
    this.validateForm();
    console.log(this.isValid, this.form);
    if (this.isValid) authStore.requestOTP(this.form.fields['phone'].value);
  };
}

export default LoginForm;
