import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import Flex, { IFlex } from './Flex';
import { breakpointStyle, breakpointTab } from 'utils/style';
import userIcon from 'assets/icons/users.svg';
import diamondIcon from 'assets/icons/premium_room.svg';
import { Span, TextHighlight } from './Typography';
import { ITags } from 'types/general';

export interface IContainer extends HTMLAttributes<HTMLDivElement> {
  thumbnail?: string;
  base?: 'dynamic' | number;
  size?: 'small' | 'medium';
}

export const LobbyContainer = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'space-between',
  direction: 'row',
})`
  position: absolute;
  right: 13px;
  top: 10px;
  height: 27px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.8);
  padding-left: 5px;
  padding-right: 10px;
  min-width: 64px;
  box-sizing: border-box;
`;

export const StatusIcon = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: green;
`;

export const UserIcon = styled.img`
  width: 18.33px;
  height: 12.88px;
`;

export const DiamondIcon = styled.img`
  left: 10px;
  top: 10px;
  position: inherit;
  width: 36px;
  height: 36px;
`;

const containerStyle = css<IContainer>`
  background-image: url(${(props) => props.thumbnail});
  background-size: cover;
`;

const containerSizing = ({ base, size }: IContainer) => {
  if (base == 'dynamic') {
    if (size == 'small') {
      return css`
        width: 278px;
        height: 170px;
      `;
    }
    return css`
      width: 467px;
      height: 263px;

      ${breakpointTab(css`
        width: 373px;
        height: 210px;
      `)}
    `;
  } else if (typeof base == 'number') {
    return css`
      width: calc((100% / ${base}) - 35px);
      padding-top: calc((((100% / ${base}) - 35px) * 56.25) / 100);
      margin-right: 35px;
    `;
  }
};

export const RoomContainer = styled(Flex)<IContainer>`
  margin-bottom: 47px;
  ${breakpointTab(css`
    margin-bottom: 30px;
  `)}

  ${({ size }) =>
    size === 'small'
      ? css`
          margin-bottom: 30px;
        `
      : null}
  
  ${breakpointStyle({
    content: css`
      margin-right: 0;
    `,
  })}
`;

export const ThumbnailContainer = styled(Flex)<IContainer>`
  cursor: pointer;
  ${containerSizing}
  position: relative;
  box-sizing: border-box;
  border: 1px solid #2cad52;
  ${(props) => props.thumbnail && containerStyle}
  ${breakpointStyle({
    content: css`
      width: 100%;
      height: calc((100vw - 44px) / 1.8);
      margin-right: 0;
    `,
  })}
`;

ThumbnailContainer.defaultProps = {
  base: 'dynamic',
  margin: { bottom: 0 },
};

export const Content = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

type hubType = 'premium' | 'freemium';

interface IHub extends IContainer, IFlex {
  tags?: Array<ITags>;
  visibleTags?: string;
  visibleName?: string;
  children?: React.ReactNode;
  showDetails?: boolean;
  thumbnail: string;
  showLobby?: boolean;
  lobby?: number;
  type?: hubType;
  name: string;
  owner?: string;
}

const Hub = ({
  tags,
  visibleTags,
  visibleName,
  thumbnail,
  onClick,
  showLobby,
  lobby,
  type,
  children,
  showDetails,
  name,
  owner,
  ...containerProps
}: IHub) => {
  return (
    <RoomContainer id={name} className="gtm-scene-tracker">
      <ThumbnailContainer {...containerProps} onClick={onClick} thumbnail={thumbnail}>
        {showLobby && (
          <Flex alignItems="baseline" justifyContent="space-between">
            <LobbyContainer>
              <StatusIcon />
              <UserIcon src={userIcon} />
              <p style={{ color: 'black', fontWeight: 600 }}>{lobby}</p>
            </LobbyContainer>
          </Flex>
        )}
        <Content>
          {type && type === 'premium' && <DiamondIcon src={diamondIcon} />}
          {children}
        </Content>
      </ThumbnailContainer>
      {showDetails && (
        <>
          <TextHighlight size="sm" bold={false} margin={{ top: 20, bottom: 0 }}>
            {visibleName}
          </TextHighlight>
          {owner && (
            <Span color="#d3d3d3" size="lg">
              @{owner}
            </Span>
          )}
          <Span margin="0" size="md">
            {visibleTags}
          </Span>
        </>
      )}
    </RoomContainer>
  );
};

export default Hub;
