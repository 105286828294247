import React from 'react';
import Flex from 'components/Flex';
import Section from 'components/Section';
import { Title } from 'components/Typography';
import { CardPlatforms, Icon, OverlapTitle } from './styled';
import icon_vr from 'assets/icons/platforms/vr.svg';
import icon_pc from 'assets/icons/platforms/pc.svg';
import icon_phone from 'assets/icons/platforms/phone.svg';

export const SECTION_TEXT = {
  title: 'Interactive Internet Where Virtuality Meets Actuality',
  bgTitle: '.Connecting.',
  cards_content: [
    {
      src: icon_vr,
      text: 'VR',
    },
    {
      src: icon_pc,
      text: 'PC',
    },
    {
      src: icon_phone,
      text: 'Mobile',
    },
  ],
};

function Platforms() {
  return (
    <Section id="mission" direction="column" justifyContent="space-between" container={false}>
      <Flex alignItems="center" margin={{ vertical: 40, mobile: { bottom: 54 } }}>
        <Title bold alignMobile="center" padding={{ mobile: { horizontal: 20 } }}>
          {SECTION_TEXT.title}
        </Title>
      </Flex>
      <OverlapTitle content={SECTION_TEXT.bgTitle} />
      <Flex
        direction="row"
        wrap="wrap"
        justifyContent="center"
        style={{ alignSelf: 'center', textAlign: 'center' }}
        padding={{ horizontal: 100, mobile: { horizontal: 24 } }}
        margin={{ bottom: 200, mobile: { bottom: 100 } }}
      >
        {SECTION_TEXT.cards_content.map((content, index) => {
          return (
            <CardPlatforms
              key={`platform-${index}`}
              justifyContent="center"
              margin={{ mobile: { bottom: 24, top: '0' }, ...(index === 1 ? { top: 20 } : {}) }}
            >
              <Flex alignItems="center" padding={{ horizontal: 50 }}>
                <Icon src={content.src} alt={content.text} />
                <Title bold margin="0">
                  {content.text}
                </Title>
              </Flex>
            </CardPlatforms>
          );
        })}
      </Flex>
    </Section>
  );
}

export default React.memo(Platforms);
