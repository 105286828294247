import { observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { IFlex } from 'components/Flex';
import { authStore, subscriptionStore } from 'stores';
import { loginUIStore, sceneUIStore } from 'stores/ui/modal';
import Hub, { IContainer } from 'components/Hub';
import { SceneModel } from 'stores/domain/scenes';

import TagManager from 'react-gtm-module';

interface ISceneThumbnail extends IContainer, IFlex {
  children?: ReactNode;
  scene: SceneModel;
  showDetails?: boolean;
}

const Scene = observer(({ scene, ...props }: ISceneThumbnail) => {
  const { snapshot, id, ...restScene } = scene;

  const onClick = () => {
    if (!authStore.isLoggedIn) {
      loginUIStore.show();
    } else if (!subscriptionStore.isPremium) {
      sceneUIStore.show(scene.name);
    } else {
      TagManager.dataLayer({
        dataLayer: {
          event: 'scene_redirect',
          userId: authStore.userId,
          sceneName: scene.name,
          url: scene.url,
        },
      });
      scene.openScene();
    }
  };
  return (
    <Hub
      type={scene.isFeatured ? 'premium' : undefined}
      visibleTags={scene.visibleTags}
      visibleName={scene.visibleName}
      thumbnail={snapshot}
      onClick={onClick}
      {...restScene}
      {...props}
    />
  );
});

export default Scene;
