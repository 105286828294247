import { makeAutoObservable, observable } from 'mobx';
import { roomStore, sceneStore, RootStore } from './index';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/react';

class AppStore {
  menuActive = false;
  parent: RootStore;
  isInitialized = false;

  constructor(parent: RootStore) {
    this.parent = parent;
    makeAutoObservable(this, {
      menuActive: observable,
    });
  }

  closeMenu = () => {
    this.menuActive = false;
  };

  openMenu = () => {
    this.menuActive = true;
  };

  initialize = () => {
    if (!this.parent.roomStore.isInitialized) {
      roomStore.load();
    }

    if (!this.parent.sceneStore.isInitialized) {
      sceneStore.load();
    }

    const env = process.env.REACT_APP_ENV;

    if (env !== 'dev')
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        autoSessionTracking: true,
        environment: env,
        integrations: [new Integrations.BrowserTracing()],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
      });
  };
}

export default AppStore;
