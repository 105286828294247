import React from 'react';
import { observer } from 'mobx-react';
import Flex from 'components/Flex';
import Link from 'components/Link';
import { GamerTag, InfoBlock } from '../styled';
import { CardCitizen } from 'components/Cards';
import { LineSeparator } from 'components/Divider';
import { IUserSocials, UserModel } from 'stores/domain/users';

import {
  FaFacebookF,
  FaInstagram,
  FaYoutube,
  FaDiscord,
  FaLinkedin,
  FaTwitter,
} from 'react-icons/fa';

const IconSocial: { [key: string]: any } = {
  discord: FaDiscord,
  youtube: FaYoutube,
  facebook: FaFacebookF,
  instagram: FaInstagram,
  linkedin: FaLinkedin,
  twitter: FaTwitter,
};

// TODO: Move this to utils file
const formatDate = (d?: Date) => {
  if (d) {
    const date = new Date(d);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  }
  return undefined;
};

// TODO: Move this to utils file
const formatWebsite = (link?: string) => {
  if (link) {
    return link.substring(link.indexOf('//') + 2, link.length);
  }
  return undefined;
};

const isEmpty = (value?: any[]): boolean => {
  if (value) {
    return value.length === 0;
  } else return true;
};

const Card = observer((props: { model: UserModel }) => {
  const { city, country, gamerTag, createdAt, website, socials } = props.model;

  return (
    <CardCitizen padding={{ horizontal: 25, mobile: { vertical: 20 } }}>
      <GamerTag>@{gamerTag}</GamerTag>
      <Flex direction="row-responsive">
        <InfoBlock>
          Country: {city}, {country}
        </InfoBlock>
        <InfoBlock>Joined: {formatDate(createdAt)}</InfoBlock>
      </Flex>
      {website && (
        <Flex margin={{ top: 10 }} direction="row-responsive">
          <div>Personal Website:</div>
          <Link
            margin={{ left: 5, mobile: { left: 0 } }}
            color="#2CAD52"
            target="_blank"
            href={website}
          >
            {formatWebsite(website)}
          </Link>
        </Flex>
      )}
      {socials && !isEmpty(socials) && (
        <>
          <LineSeparator alignment="horizontal" />
          <Flex direction="row" alignItems="center">
            {socials.map((el: IUserSocials) => {
              const Icon = IconSocial[el.type];
              return (
                <Link
                  key={el.uri}
                  href={el.uri}
                  target="_blank"
                  color="#2cad52"
                  margin={{ right: 15 }}
                >
                  <Icon size={16} />
                </Link>
              );
            })}
          </Flex>
        </>
      )}
    </CardCitizen>
  );
});

export default Card;
