import { navStore } from '../index';
import { observable } from 'mobx';

export const sceneUIStore = observable({
  open: false,
  name: '',

  close() {
    sceneUIStore.open = false;
  },
  redirect(url: string) {
    navStore.push(url);
    sceneUIStore.close();
  },
  show(name: string) {
    sceneUIStore.name = name;
    sceneUIStore.open = true;
  },
  toggle() {
    sceneUIStore.open = !sceneUIStore.open;
  },
});

export enum LOGIN_MODAL_TYPE {
  portal,
  subscribe,
}

export const loginUIStore = observable({
  open: false,
  type: LOGIN_MODAL_TYPE.portal,

  close() {
    loginUIStore.open = false;
    loginUIStore.type = LOGIN_MODAL_TYPE.portal;
  },
  redirect(url: string) {
    navStore.push(url);
    loginUIStore.close();
  },
  show(type: LOGIN_MODAL_TYPE = LOGIN_MODAL_TYPE.portal) {
    loginUIStore.type = type;
    loginUIStore.open = true;
  },
  toggle() {
    loginUIStore.open = !loginUIStore.open;
  },
});

export const hubsAuthModalStore = observable({
  open: false,
  show() {
    hubsAuthModalStore.open = true;
  },
  close() {
    hubsAuthModalStore.open = false;
  },
  toggle() {
    hubsAuthModalStore.open = !hubsAuthModalStore.open;
  },
});

export const hubsAuthPopupModalStore = observable({
  open: false,
  show() {
    hubsAuthPopupModalStore.open = true;
  },
  close() {
    hubsAuthPopupModalStore.open = false;
  },
  toggle() {
    hubsAuthPopupModalStore.open = !hubsAuthPopupModalStore.open;
  },
});
