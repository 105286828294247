import React from 'react';
import Flex from 'components/Flex';
import Button from 'components/Button';
import Section, { BACKGROUNDS } from 'components/Section';
import { RetroTitle, Paragraph } from 'components/Typography';
import { authStore, navStore } from 'stores';
import { observer } from 'mobx-react';

function Romulus() {
  return (
    <Section
      id="inevitable"
      direction="column"
      alignItems="center"
      bg={BACKGROUNDS.holo}
      justifyContent="center"
      container
    >
      <Flex alignItems="center" justifyContent="center">
        <Flex maxWidth={800}>
          <RetroTitle align="center" size="md">
            PROJECT ROMULUS
          </RetroTitle>
        </Flex>
        <Flex alignItems="center" maxWidth={660} margin={{ bottom: 40 }}>
          <Paragraph align="center" size="md">
            To create a Virtual World Out of Our Actual World.
          </Paragraph>
        </Flex>
        {!authStore.isLoggedIn && (
          <Flex alignItems="center" minWidth="100%">
            <Button onClick={() => navStore.push('/login', true)}>Login</Button>
          </Flex>
        )}
      </Flex>
    </Section>
  );
}

export default observer(Romulus);
