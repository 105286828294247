import { RootStore } from './index';
import { flow, makeAutoObservable } from 'mobx';
import firebase, { firebaseApp, firestoreDB } from 'config/firebase';
import { loadStripe } from '@stripe/stripe-js';

export class SubscriptionStore {
  parent: RootStore;
  loading = false;
  status = '';

  constructor(parent: RootStore) {
    makeAutoObservable(this);
    this.parent = parent;
  }

  /// This is to be used to check if a user is stripe subscribed
  /// NOT TO BE CONFUSED WITH isPremium!
  get isSubscribed(): boolean {
    return (
      this._getMaybeStatus() === 'paid' || this.status === 'premium' || this.status === 'active'
    );
  }

  /// This is to be used to check if a user has premium privileges
  get isPremium(): boolean {
    const status = this._getMaybeStatus();
    return (
      this.isSubscribed ||
      this.parent.authStore.isAdmin ||
      status === 'paid' ||
      status === 'trialing'
    );
  }

  /// This is just a description of the user's current plan/status
  get userPlan(): string {
    if (this.isSubscribed) {
      return this.status || this._getMaybeStatus() || '';
    }

    return this._getMaybeStatus() || 'Unpaid';
  }

  get buttonText(): string {
    return this.loading ? 'Loading...' : this.isSubscribed ? 'Manage Plan' : 'Subscribe Now';
  }

  verifySubscription = flow(function* (this: SubscriptionStore) {
    const decodedToken = yield firebase.auth().currentUser?.getIdTokenResult();
    this.status = decodedToken?.claims.stripeRole;
  });

  subscriptionAction = () => {
    if (this.isSubscribed) {
      this.redirectToCustomerPortal();
    } else {
      this.sendToCheckout();
    }
  };

  clearSubscriptionStatus = () => {
    this.status = '';
  };

  sendToCheckout = flow(function* (this: SubscriptionStore) {
    this.loading = true;

    const currentUser = firebase.auth().currentUser;

    const docRef = yield firestoreDB
      .collection('customers')
      .doc(currentUser?.uid)
      .collection('checkout_sessions')
      .add({
        price: process.env.REACT_APP_STRIPE_PRICE_ID,
        success_url: window.location.origin + '/profile',
        cancel_url: window.location.origin + '/profile',
        metadata: { mwId: this.parent.authStore.user?.id },
      });

    docRef.onSnapshot((snap: any) => {
      const { error, sessionId }: any = snap.data();
      if (error) {
        alert(`An error occurred: ${error.message}`);
        this.loading = false;
      }
      if (sessionId) {
        loadStripe(process.env.REACT_APP_STRIPE_PK || '').then((stripe) => {
          stripe?.redirectToCheckout({ sessionId });
        });
      }
    });
  });

  redirectToCustomerPortal = flow(function* (this: SubscriptionStore) {
    this.loading = true;
    const functionRef = firebaseApp
      .functions(process.env.REACT_APP_STRIPE_FUNCTION_REGION)
      .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');

    const { data } = yield functionRef({
      returnUrl: window.location.origin + '/profile',
    });
    window.location.assign(data.url);
  });

  _getMaybeStatus = () => {
    return this.parent.authStore.user?.subscription?.status?.toLowerCase();
  };
}
