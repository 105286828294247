import Flex, { IFlex } from './Flex';
import React, { HTMLAttributes, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { breakpointStyle, breakpointTab } from 'utils/style';

interface IContainer extends IFlex {
  children?: ReactNode;
  Content?: ReactNode;
}

const ContainerContent = styled(Flex)`
  max-width: 1600px;
  flex: 1;
  padding-right: 50px;
  padding-left: 50px;
  ${breakpointTab(css`
    max-width: 1280px;
    padding-right: 50px;
    padding-left: 50px;
  `)}

  ${breakpointStyle({
    content: css`
      padding-right: 20px;
      padding-left: 20px;
    `,
  })}
`;

const Container = ({ children, ...props }: IContainer & HTMLAttributes<HTMLDivElement>) => {
  return (
    <Flex direction="row" alignItems="center" justifyContent="center" flex={1}>
      <ContainerContent {...props}>{children}</ContainerContent>
    </Flex>
  );
};
Container.Content = ContainerContent;
export default Container;
