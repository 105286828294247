import Flex, { IFlex } from './Flex';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import React, { useCallback, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { AiOutlineClose } from 'react-icons/ai';
import { breakpointStyle } from 'utils/style';

export interface IModal {
  show: boolean;
  onClose?: () => void;
  children: any;
}

interface IModalCompound extends IFlex {
  children?: React.ReactNode;
}

const ModalContainer = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'center',
})`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  position: fixed;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;

  &.enter-done {
    opacity: 1;
    pointer-events: visible;
  }
  &.exit {
    opacity: 0;
  }
`;

const ModalContent = styled(Flex)`
  width: 585px;
  padding: 30px;
  background-color: #040733;

  ${breakpointStyle({
    content: css`
      width: 350px;
    `,
  })}
`;

const ModalBody = styled(Flex)`
  padding: 30px 0;
`;

const ModalCloseContainer = styled(Flex).attrs({
  alignItems: 'flex-end',
})`
  > svg {
    cursor: pointer;
  }
`;

const ModalHeader = styled(Flex)`
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`;

const ModalFooter = styled(Flex)``;

function Modal(props: IModal) {
  const closeOnEscapeKeyDown = useCallback(
    (e) => {
      if ((e.charCode || e.keyCode) === 27) {
        if (props.onClose) props.onClose();
      }
    },
    [props],
  );

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
    };
  }, [closeOnEscapeKeyDown]);

  const rootDiv = document.getElementById('modal-root');
  if (!rootDiv) throw new Error("The element #modal-root wasn't found");

  return ReactDOM.createPortal(
    <CSSTransition in={props.show} unmountOnExit timeout={{ enter: 0, exit: 0 }}>
      <ModalContainer onClick={props.onClose}>
        <ModalContent onClick={(e) => e.stopPropagation()}>
          {props.onClose && (
            <ModalCloseContainer>
              <AiOutlineClose onClick={props.onClose} size={18} />
            </ModalCloseContainer>
          )}
          {props.children}
        </ModalContent>
      </ModalContainer>
    </CSSTransition>,
    rootDiv,
  );
}

Modal.Header = function Header({ children, ...rest }: IModalCompound) {
  return <ModalHeader {...rest}>{children}</ModalHeader>;
};

Modal.Body = function Bodoy({ children, ...rest }: IModalCompound) {
  return <ModalBody {...rest}>{children}</ModalBody>;
};

Modal.Footer = function Footer({ children, ...rest }: IModalCompound) {
  return <ModalFooter {...rest}>{children}</ModalFooter>;
};

export default Modal;

/* Implementation Sample
  const [show, setShow] = useState(false);
  <button onClick={() => setShow(true)}>Show Modal</button>
  <Modal onClose={() => setShow(false)} show={show}>
    <Modal.Header>
      <Title size="sm" margin="0">
        This is a title of modal
      </Title>
    </Modal.Header>
    <Modal.Body>
      <p>This is modal body</p>
    </Modal.Body>
    <Modal.Footer direction="row-responsive" justifyContent="space-between">
      <Flex padding={{ right: 15, mobile: { right: 0, bottom: 10 } }} width="100%">
        <Button wide={false} light onClick={() => setShow(false)}>
          Cancel
        </Button>
      </Flex>
      <Flex padding={{ left: 15, mobile: { left: 0, top: 10 } }} width="100%">
        <Button wide={false}>Subscribe</Button>
      </Flex>
    </Modal.Footer>
  </Modal>
*/
