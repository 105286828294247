import { observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { IFlex } from 'components/Flex';
import { RoomModel, RoomType } from 'stores/domain/rooms';
import Hub, { IContainer } from 'components/Hub';

interface IRoomThumbnail extends IContainer, IFlex {
  children?: ReactNode;
  room: RoomModel;
  showDetails?: boolean;
}
const Room = observer(({ room, ...props }: IRoomThumbnail) => {
  const { image, type, size, id, ...restRoom } = room;
  return (
    <Hub
      thumbnail={image}
      type={type === RoomType.PAID ? 'premium' : 'freemium'}
      showLobby
      visibleName={room.visibleName}
      visibleTags={room.visibleTags}
      onClick={room.openRoom}
      {...restRoom}
      {...props}
    />
  );
});

export default Room;
