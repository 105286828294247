import React from 'react';
import { authStore } from 'stores';
import { observer } from 'mobx-react';

/**
 * @category Styled Component
 */
const RecaptchaHolder = observer(() => {
  return (
    <div key={authStore.reCaptchaKey}>
      <div id="recaptcha-container" />
    </div>
  );
});

export default RecaptchaHolder;
