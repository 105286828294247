import React from 'react';
import { Helmet } from 'react-helmet';
import Page from 'containers/Page';
import Link from 'components/Link';
import Section from 'components/Section';
import { RetroTitle } from 'components/Typography';

export default function TermsAndConditions() {
  return (
    <Page>
      <Helmet>
        <title>MyWorld Terms And Conditions</title>
      </Helmet>
      <Section>
        <RetroTitle size="sm">Terms And Conditions</RetroTitle>
        <h1>
          <strong>Terms of Service for MyWorld Global Inc.</strong>
        </h1>
        <p>Version 1.0, Effective May 1, 2021</p>
        <p>
          <u>MyWorld Virtual Inc</u> is a real-time communications platform for Virtual Reality,
          Augmented Reality, Desktop, Laptop, Mobile, or however else you browse the internet.
          MyWorld Global Inc. to be referred to as MyWorld Virtual herein;
        </p>
        <ul>
          <li>
            <Link href="#terms">Terms of service</Link>
          </li>
          <li>
            <Link href="#conditions-of-use">Condition of Use</Link>
          </li>
          <li>
            <Link href="#community-participation">
              Community Participation Guidelines for MyWorld Global Inc.
            </Link>
          </li>
          <li>
            <Link href="#terms-of-service">
              Terms of Service for Create VR Spoke by Mozilla within MyWorld Virtual
            </Link>
          </li>
        </ul>
        <p id="terms">
          These Terms of Service explain your rights and responsibilities when you use MyWorld
          Virtual.
        </p>
        <h3>
          <strong>1. Privacy Policy</strong>
        </h3>
        <p>
          The MyWorld Virtual <u>Privacy Notice</u> explains what information we collect when you
          use MyWorld Virtual and how that information is handled and shared.
        </p>
        <h3>
          <strong>2. Communications and Content</strong>
        </h3>
        <p>
          MyWorld Virtual allows users to send information (such as audio, video, text, images, 3D
          models, and scenes) to other users. You can also use MyWorld Virtual to customize your
          in-room Avatar.
        </p>
        <p>
          By using MyWorld Virtual you agree to give Mozilla all rights necessary to operate MyWorld
          Virtual. This includes, but is not limited to, a license and permission to process,
          transmit, and display the information you send through MyWorld Virtual. It also includes
          permission to gather and share information as described in the <u>Privacy Notice</u> for
          MyWorld Virtual{' '}
        </p>
        <p>
          When you submit information to MyWorld Virtual, you grant us a worldwide, royalty-free,
          perpetual, irrevocable, non-exclusive, transferable, and sublicensable license to use,
          copy, modify, adapt, prepare derivative works from, distribute, perform, and display that
          information, audio, video, images, or 3D models for the purpose of operating MyWorld
          Virtual. You also agree that we may remove metadata associated with the information or
          data you submit.
        </p>
        <p>
          You also represent and warrant that you have the authority to grant MyWorld Virtual all
          rights and permissions necessary for the operation of MyWorld Virtual.
        </p>
        <p>
          Any ideas, suggestions, and feedback about MyWorld Virtual that you provide to us are
          entirely voluntary, and you agree that MyWorld Virtual may use such ideas, suggestions,
          and feedback without compensation or obligation to you.
        </p>
        <p>
          You are solely responsible for the information you send, create, or edit using MyWorld
          Virtual and the consequences of sending, creating, or editing that information.
        </p>
        <h3>
          <strong>3. Conditions of Use</strong>
        </h3>
        <p>
          By using MyWorld Virtual, you agree that your use will comply with MyWorld Virtual{' '}
          <u>Conditions of Use</u>. MyWorld Virtual reserves the right to remove any content,
          suspend any users, and shut down any room it reasonably believes has violated these
          conditions.
        </p>
        <p>
          Please also be aware of MyWorld Virtual<u> Community Participation Guidelines</u>, which
          address participation in MyWorld Virtual communities.
        </p>
        <h3>
          <strong>4. MyWorld Virtual’s Rights</strong>
        </h3>
        <p>
          MyWorld Virtual does not grant you any intellectual property rights in MyWorld Virtual or
          unless these Terms specifically say otherwise. For example, these Terms do not provide the
          right to use any of MyWorld Virtual’s copyrights, trade names, trademarks, service marks,
          logos, domain names, or other distinctive brand features.
        </p>
        <p>MyWorld Virtual distributes under an open source license. </p>
        <p>
          <strong>5. Services Interruption; Term; Termination</strong>
        </p>
        <p>
          We are continuing to develop MyWorld Virtual. As a result, we plan to upgrade and change
          them over time. To do this, we might have to temporarily suspend their service and it is
          not always possible for us to give notice. You will not be entitled to claim expenses or
          damages for such suspension or limitation of the use of MyWorld Virtual.
        </p>
        <p>
          These Terms apply to your use of MyWorld Virtual and will continue to apply until ended by
          either you or upon notice from MyWorld Virtual. You can choose to end them at any time for
          any reason by discontinuing your use of MyWorld Virtual.
        </p>
        <p>
          We may cut off your access to MyWorld Virtual, either temporarily or permanently at any
          time for any reason. This includes, but is not limited to, situations where we reasonably
          believe: (i) you have violated these Terms (ii) you create risk or possible legal exposure
          for MyWorld Virtual; or (iii) providing and operating MyWorld Virtual is no longer
          commercially viable. If possible, we will make reasonable efforts to notify you through
          the relevant program, either MyWorld Virtual.
        </p>
        <p>
          In all such cases, these Terms shall terminate, including, without limitation, your
          license to use MyWorld Virtual, except that the sections with the following titles shall
          continue to apply: Indemnification, Disclaimer; Limitation of Liability and Miscellaneous.
        </p>
        <h3>
          <strong>6. Indemnification</strong>
        </h3>
        <p>
          You agree to defend, indemnify and hold harmless MyWorld Virtual, and its respective
          parent and affiliate companies, contractors, contributors, licensors, partners, directors,
          officers, employees and agents (&quot;Indemnified Parties&quot;) from and against any and
          all third party claims and expenses, including attorneys&apos; fees, arising out of or
          related to your use of MyWorld Virtual. This includes, but is not limited to, claims and
          expenses from any content you transmit, edit, or create using MyWorld Virtual.
        </p>
        <h3>
          <strong>7. Disclaimer; Limitation of Liability</strong>
        </h3>
        <p>
          THE SERVICES ARE PROVIDED &quot;AS IS&quot; WITH ALL FAULTS. TO THE EXTENT PERMITTED BY
          LAW, THE INDEMNIFIED PARTIES, HEREBY DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED,
          INCLUDING WITHOUT LIMITATION WARRANTIES THAT THE SERVICES ARE FREE OF DEFECTS,
          MERCHANTABLE, FIT FOR A PARTICULAR PURPOSE, AND NON-INFRINGING.
        </p>
        <p>
          YOU BEAR THE ENTIRE RISK AS TO SELECTING THE SERVICES FOR YOUR PURPOSES AND AS TO THE
          QUALITY AND PERFORMANCE OF THE SERVICES, INCLUDING WITHOUT LIMITATION THE RISK THAT YOUR
          CONTENT IS DELETED OR CORRUPTED. THIS LIMITATION WILL APPLY NOTWITHSTANDING THE FAILURE OF
          ESSENTIAL PURPOSE OF ANY REMEDY. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
          LIMITATION OF IMPLIED WARRANTIES, SO THIS DISCLAIMER MAY NOT APPLY TO YOU.
        </p>
        <p>
          EXCEPT AS REQUIRED BY LAW, THE INDEMNIFIED PARTIES, WILL NOT BE LIABLE FOR ANY INDIRECT,
          SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES ARISING OUT OF OR IN ANY WAY
          RELATING TO THESE TERMS OR THE USE OF OR INABILITY TO USE THE SERVICES, INCLUDING WITHOUT
          LIMITATION DIRECT AND INDIRECT DAMAGES FOR LOSS OF GOODWILL, WORK STOPPAGE, LOST PROFITS,
          LOSS OF DATA, AND COMPUTER FAILURE OR MALFUNCTION, EVEN IF ADVISED OF THE POSSIBILITY OF
          SUCH DAMAGES AND REGARDLESS OF THE THEORY (CONTRACT, TORT, OR OTHERWISE) UPON WHICH SUCH
          CLAIM IS BASED. THE COLLECTIVE LIABILITY OF THE INDEMNIFIED PARTIES, UNDER THIS AGREEMENT
          WILL NOT EXCEED $500 (FIVE HUNDRED DOLLARS). SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION
          OR LIMITATION OF INCIDENTAL, CONSEQUENTIAL, OR SPECIAL DAMAGES, SO THIS EXCLUSION AND
          LIMITATION MAY NOT APPLY TO YOU.
        </p>
        <h3>
          <strong>8. Modifications to These Terms</strong>
        </h3>
        <p>
          MyWorld Virtual may update these Terms from time to time. We will post the updated Terms
          online. If the changes are substantive, we may announce the update through MyWorld
          Virtual&apos;s usual channels for such announcements such as blog posts, forums, or in the
          particular service itself, in this case: MyWorld Virtual .
        </p>
        <p>
          Your continued use of MyWorld Virtual after we post the new Terms constitutes your
          acceptance of the new Terms. To make your review more convenient, we will post an
          effective date at the top of this page.
        </p>
        <h3>
          <strong>9. Miscellaneous</strong>
        </h3>
        <p>
          These Terms make up the entire agreement between you and MyWorld Virtual concerning
          MyWorld Virtual. The laws of the state of Delaware, U.S.A (excluding its conflict of law
          provisions) govern this agreement.
        </p>
        <p>
          If any portion of these Terms is held to be invalid or unenforceable, the remaining
          portions remain in full force and effect. If there is a conflict or ambiguity between a
          translated version of these terms and the English language version, the English language
          version applies.
        </p>
        <h3>
          <strong>10. Contact Us</strong>
        </h3>
        <p>
          For support, to provide feedback, or to report abuse of MyWorld Virtual violations of the
          Conditions of Use, you can email us at creator@myworldglobal.com.
        </p>
        <h1 id="conditions-of-use">
          <strong>Conditions of Use for MyWorld Global Inc.</strong>
        </h1>
        <h2>Paid Events </h2>
        <ul>
          <li>To host a paid event, you must contact creator@myworldglobal.com.</li>
          <li>
            You are not permitted to collect payment for events without prior written consent.{' '}
          </li>
          <li>
            You may not use any of MyWorld Virtual’s copyrights, trade names, trademarks, service
            marks, logos, domain names, or other distinctive brand features on any branded or
            co-branded event materials without prior review and permission.{' '}
          </li>
        </ul>
        <h2>Prohibited Uses</h2>
        <p>
          You agree not to use the MyWorld Virtual to collect, upload, transmit, display, link to,
          send, store, process or distribute any User Content:
        </p>
        <ul>
          <li>
            that violates or infringes any third-party right, including any copyright, trademark,
            patent, trade secret, moral right, privacy right, right of publicity, or any other
            intellectual property or proprietary right;
          </li>
          <li>
            that is unlawful, harassing, bullying, embarrassing, abusive, tortious, threatening,
            harmful, invasive of another’s privacy, vulgar, defamatory, false, intentionally
            misleading, defamatory, libelous, pornographic, obscene, patently offensive,
            discriminatory, promotes racism, bigotry, hatred, or physical harm of any kind against
            any group or individual or is otherwise objectionable;
          </li>
          <li>that is harmful to minors in any way;</li>
          <li>
            that is in violation of any law, regulation, or obligations or restrictions imposed by
            this Agreement or any third party; or
          </li>
          <li>
            that includes credit, debit or other payment card data, other personal financial account
            numbers, or identifiable medical or health information.
          </li>
        </ul>
        <p>In addition, you agree not to:</p>
        <ul>
          <li>
            Transmit, whether intentionally or unintentionally, to the MyWorld Virtual any computer
            viruses, malware, spyware, Trojan horses, worms, time bombs, corrupted files, or any
            other harmful software that can damage, interfere with, disrupt or alter a computer
            system or data;
          </li>
          <li>
            send through the MyWorld Virtual unsolicited or unauthorized advertising, promotional
            materials, spam, junk mail, pyramid schemes, chain letters, or any other form of
            duplicative or unsolicited messages, whether commercial or otherwise;
          </li>
          <li>
            use the MyWorld Virtual to harvest, collect, gather or assemble information or data
            regarding other users, including e-mail addresses, without their consent;
          </li>
          <li>
            interfere with, whether intentionally or unintentionally, disrupt, or create an undue
            burden on servers or networks connected to the MyWorld Virtual, or violate the
            regulations, policies or procedures of such networks;
          </li>
          <li>
            attempt to bypass or break any security or rate limiting mechanism on any of the MyWorld
            Virtual;
          </li>
          <li>
            attempt to gain unauthorized access to the MyWorld Virtual (or to other computer systems
            or networks connected to or used together with the MyWorld Virtual), whether through
            phishing, password mining or any other means;
          </li>
          <li>
            harass or interfere with any other user’s use and enjoyment of the MyWorld Virtual;
          </li>
          <li>
            engage in any activity that disturbs the peaceful, fair and respectful environment of
            the MyWorld Virtual;
          </li>
          <li>
            use software or automated agents or scripts to produce multiple accounts on the MyWorld
            Virtual, or to generate automated searches, requests, or queries to (or to strip,
            scrape, copy, or mine data from) the MyWorld Virtual; or
          </li>
          <li>
            modify or remove any copyright or other proprietary notices or labels on the MyWorld
            Virtual (or on any content displayed on the MyWorld Virtual).
          </li>
        </ul>
        <p>
          We reserve the right (but have no obligation) to review any User Content, and to
          investigate and take appropriate action against you in our sole discretion for any
          violation of these Terms or if we believe that you otherwise create actual or potential
          liability for us or any other person. We may, in our sole discretion, remove, edit, modify
          or disable, all or any part of your User Content for any reason, terminate your account in
          accordance with Term and Termination below, and/or report you to law enforcement
          authorities. We do not assume any responsibility or liability for User Content, for
          removing it, or not removing it. We do not pre-screen all User Content and do not endorse
          or approve any User Content available on the MyWorld Virtual.
        </p>
        <h2>Linked Websites and Third-Party Applications</h2>
        <p>
          We have not reviewed all of the websites linked to the MyWorld Virtual, third-party
          applications or ads accessible through the MyWorld Virtual and are not responsible for the
          content of any third-party websites, applications or ads available through the MyWorld
          Virtual. Nothing in the MyWorld Virtual, including any links to other websites, should be
          construed as an endorsement by MyWorld Virtual of any products, services or information of
          any other persons or companies. Your choice to link to or click through a link to any
          other websites is at your own risk, and you should review and comply with all terms
          relating to such websites.
        </p>
        <p>
          MyWorld Virtual reserves the right not to link, or to remove the link, to any website or
          third-party application at any time without notice. Any links to third party websites are
          provided as a convenience to you; any such third-party websites are neither owned nor
          operated by MyWorld Virtual. We have no control over and make no representations or
          warranties with respect to these linked websites. Your viewing and/or use of any
          third-party websites is in your sole discretion and at your own risk.
        </p>
        <p>You may not use any of MyWorld Virtual’s services to</p>
        <ul>
          <li>Build a similar or competitive website, product, or service; and</li>
          <li>Do anything illegal or otherwise violate applicable law,</li>
          <li>
            Threaten, harass, or violate the privacy rights of others; send unsolicited
            communications; or intercept, monitor, or modify communications not intended for you,
          </li>
          <li>
            Harm users such as by using viruses, spyware or malware, worms, trojan horses, time
            bombs or any other such malicious codes or instructions,
          </li>
          <li>Deceive, mislead, defraud, phish, or commit or attempt to commit identity theft,</li>
          <li>Engage in or promote illegal gambling,</li>
          <li>
            Degrade, intimidate, incite violence against, or encourage prejudicial action against
            someone or a group based on age, gender, race, ethnicity, national origin, religion,
            sexual orientation, disability, geographic location or other protected category,
          </li>
          <li>Exploit or harm children,</li>
          <li>Sell, purchase, or advertise illegal or controlled products or services,</li>
          <li>
            Upload, download, transmit, display, or grant access to content that includes graphic
            depictions of sexuality or violence,
          </li>
          <li>
            Collect or harvest personally identifiable information without permission. This
            includes, but is not limited to, account names and email addresses,
          </li>
          <li>
            Engage in any activity that interferes with or disrupts MyWorld Virtual’s services or
            products (or the servers and networks which are connected to MyWorld Virtual’s
            services),
          </li>
          <li>
            Violate the copyright, trademark, patent, or other intellectual property rights of
            others,
          </li>
          <li>Violate any person’s rights of privacy or publicity,</li>
        </ul>
        <p>
          You may not use any MyWorld Virtual service in a way that violates the Terms of Use,
          Conditions of Use, or license that applies to the particular service. You also may not
          sell, resell, or duplicate any MyWorld Virtual product or service without written
          permission from MyWorld Virtual.
        </p>
        <p>
          These are only examples. You should not consider this a complete list, and we may update
          the list from time to time. MyWorld Virtual reserves the right to remove any content or
          suspend any users that it deems in violation of these conditions.
        </p>
        <p>
          Please also be aware of MyWorld Virtual’s <u>Community Participation Guidelines</u>, which
          address participation in MyWorld Virtual communities.
        </p>
        <p id="community-participation">
          <strong>Community Participation Guidelines for MyWorld Global Inc.</strong>
        </p>
        <p>
          The heart of MyWorld Virtual is people. We put people first and do our best to recognize,
          appreciate and respect the diversity of our global contributors. MyWorld Virtual welcomes
          contributions from everyone who shares our goals and wants to contribute in a healthy and
          constructive manner within our community. As such, we have adopted this code of conduct
          and require all those who participate to agree and adhere to these Community Participation
          Guidelines in order to help us create a safe and positive community experience for all.
        </p>
        <p>
          These guidelines aim to support a community where all people should feel safe to
          participate, introduce new ideas and inspire others, regardless of:
        </p>
        <ul>
          <li>Background</li>
          <li>Family status</li>
          <li>Gender</li>
          <li>Gender identity or expression</li>
          <li>Marital status</li>
          <li>Sex</li>
          <li>Sexual orientation</li>
          <li>Native language</li>
          <li>Age</li>
          <li>Ability</li>
          <li>Race and/or ethnicity</li>
          <li>Caste</li>
          <li>National origin</li>
          <li>Socioeconomic status</li>
          <li>Religion</li>
          <li>Geographic location</li>
          <li>Any other dimension of diversity</li>
        </ul>
        <p>
          Openness, collaboration and participation are core aspects of our work. We gain strength
          from diversity and actively seek participation from those who enhance it. These guidelines
          exist to enable diverse individuals and groups to interact and collaborate to mutual
          advantage. This document outlines both expected and prohibited behavior.
        </p>
        <h2>
          <strong>When and How to Use These Guidelines</strong>
        </h2>
        <p>
          These guidelines outline our behavior expectations as members of the MyWorld Virtual
          community in all MyWorld Virtual activities, both offline and online. Your participation
          is contingent upon following these guidelines in all MyWorld Virtual activities.
        </p>
        <p>
          While these guidelines / code of conduct are specifically aimed at MyWorld Virtual’s work
          and community, we recognize that it is possible for actions taken outside of MyWorld
          Virtual’s online spaces to have a deep impact on community health. We anticipate
          wide-ranging discussions among our communities about appropriate boundaries.
        </p>
        <h2>
          <strong>Expected Behavior</strong>
        </h2>
        <p>The following behaviors are expected of all members. </p>
        <h3>
          <strong>Be Respectful</strong>
        </h3>
        <p>
          Value each other’s ideas, styles and viewpoints. We may not always agree, but disagreement
          is no excuse for poor manners. Be open to different possibilities and to being wrong. Be
          respectful in all interactions and communications, especially when debating the merits of
          different options. Be aware of your impact and how intense interactions may be affecting
          people. Be direct, constructive and positive. Take responsibility for your impact and your
          mistakes – if someone says they have been harmed through your words or actions, listen
          carefully, apologize sincerely, and correct the behavior going forward.
        </p>
        <h3>
          <strong>Be Direct but Professional</strong>
        </h3>
        <p>
          We are likely to have some discussions about if and when criticism is respectful and when
          it’s not. We <i>must</i> be able to speak directly when we disagree and when we think we
          need to improve. We cannot withhold hard truths. Doing so respectfully is hard, doing so
          when others don’t seem to be listening is harder, and hearing such comments when one is
          the recipient can be even harder still. We need to be honest and direct, as well as
          respectful.
        </p>
        <h3>
          <strong>Be Inclusive</strong>
        </h3>
        <p>
          Seek diverse perspectives. Diversity of views and of people on teams powers innovation,
          even if it is not always comfortable. Encourage all voices. Help new perspectives be heard
          and listen actively. If you find yourself dominating a discussion, it is especially
          important to step back and encourage other voices to join in. Be aware of how much time is
          taken up by dominant members of the group. Provide alternative ways to contribute or
          participate when possible.
        </p>
        <p>
          Be inclusive of everyone in an interaction, respecting and facilitating people’s
          participation whether they are:
        </p>
        <ul>
          <li>Remote (on video or phone)</li>
          <li>Not native language speakers</li>
          <li>Coming from a different culture</li>
          <li>Using pronouns other than “he” or “she”</li>
          <li>Living in a different time zone</li>
          <li>Facing other challenges to participate</li>
        </ul>
        <p>
          Think about how you might facilitate alternative ways to contribute or participate. If you
          find yourself dominating a discussion, step back. Make way for other voices and listen
          actively to them.
        </p>
        <h3>
          <strong>Understand Different Perspectives</strong>
        </h3>
        <p>
          Our goal should not be to “win” every disagreement or argument. A more productive goal is
          to be open to ideas that make our own ideas better. Strive to be an example for inclusive
          thinking. “Winning” is when different perspectives make our work richer and stronger.
        </p>
        <h3>
          <strong>Appreciate and Accommodate Our Similarities and Differences</strong>
        </h3>
        <p>
          Members come from many cultures and backgrounds. Cultural differences can encompass
          everything from official religious observances to personal habits to clothing. Be
          respectful of people with different cultural practices, attitudes and beliefs. Work to
          eliminate your own biases, prejudices and discriminatory practices. Think of others’ needs
          from their point of view. Use preferred titles (including pronouns) and the appropriate
          tone of voice. Respect people’s right to privacy and confidentiality. Be open to learning
          from and educating others as well as educating yourself; it is unrealistic to expect
          members to know the cultural practices of every ethnic and cultural group, but everyone
          needs to recognize one’s native culture is only part of positive interactions.
        </p>
        <h3>
          <strong>Lead by Example</strong>
        </h3>
        <p>
          By matching your actions with your words, you become a person others want to follow. Your
          actions influence others to behave and respond in ways that are valuable and appropriate
          for our organizational outcomes. Design your community and your work for inclusion. Hold
          yourself and others accountable for inclusive behaviors.{' '}
        </p>
        <h2>
          <strong>Behavior That Will Not Be Tolerated</strong>
        </h2>
        <p>The following behaviors are considered to be unacceptable under these guidelines.</p>
        <h3>
          <strong>Violence and Threats of Violence</strong>
        </h3>
        <p>
          Violence and threats of violence are not acceptable - online or offline. This includes
          incitement of violence toward any individual, including encouraging a person to commit
          self-harm. This also includes posting or threatening to post other people’s personally
          identifying information (“doxxing”) online.
        </p>
        <h3>
          <strong>Personal Attacks</strong>
        </h3>
        <p>
          Conflicts will inevitably arise, but frustration should never turn into a personal attack.
          It is not okay to insult, demean or belittle others. Attacking someone for their opinions,
          beliefs and ideas is not acceptable. It is important to speak directly when we disagree
          and when we think we need to improve, but such discussions must be conducted respectfully
          and professionally, remaining focused on the issue at hand.
        </p>
        <h3>
          <strong>Derogatory Language</strong>
        </h3>
        <p>Hurtful or harmful language related to:</p>
        <ul>
          <li>Background</li>
          <li>Family status</li>
          <li>Gender</li>
          <li>Gender identity or expression</li>
          <li>Marital status</li>
          <li>Sex</li>
          <li>Sexual orientation</li>
          <li>Native language</li>
          <li>Age</li>
          <li>Ability</li>
          <li>Race and/or ethnicity</li>
          <li>Caste</li>
          <li>National origin</li>
          <li>Socioeconomic status</li>
          <li>Religion</li>
          <li>Geographic location</li>
          <li>Other attributes</li>
        </ul>
        <p>
          is not acceptable. This includes deliberately referring to someone by a gender that they
          do not identify with, and/or questioning the legitimacy of an individual’s gender
          identity. If you’re unsure if a word is derogatory, don’t use it. This also includes
          repeated subtle and/or indirect discrimination; when asked to stop, stop the behavior in
          question.
        </p>
        <h3>
          <strong>Unwelcome Sexual Attention or Physical Contact</strong>
        </h3>
        <p>
          Unwelcome sexual attention or unwelcome physical contact is not acceptable. This includes
          sexualized comments, jokes or imagery in interactions, communications or presentation
          materials, as well as inappropriate touching, groping, or sexual advances. Additionally,
          touching a person without permission, including sensitive areas such as their hair,
          pregnant stomach, mobility device (wheelchair, scooter, etc) or tattoos is unacceptable.
          This includes physically blocking or intimidating another person. Physical contact or
          simulated physical contact (such as emojis like “kiss”) without affirmative consent is not
          acceptable. The sharing or distribution of sexualized images or text is unacceptable.
        </p>
        <h3>
          <strong>Disruptive Behavior</strong>
        </h3>
        <p>
          Sustained disruption of events, forums, or meetings, including talks and presentations,
          will not be tolerated. This includes:
        </p>
        <ul>
          <li>‘Talking over’ or ‘heckling’ speakers.</li>
          <li>
            Drinking alcohol to excess or using recreational drugs to excess, or pushing others to
            do so.
          </li>
          <li>
            Making derogatory comments about those who abstain from alcohol or other substances,
            pushing people to drink, talking about their abstinence or preferences to others, or
            pressuring them to drink - physically or through jeering.
          </li>
          <li>Otherwise influencing crowd actions that cause hostility in the session.</li>
        </ul>
        <h3>
          <strong>Influencing Unacceptable Behavior</strong>
        </h3>
        <p>
          We will treat influencing or leading such activities the same way we treat the activities
          themselves, and thus the same consequences apply.
        </p>
        <h2>
          <strong>Consequences of Unacceptable Behavior</strong>
        </h2>
        <p>
          Bad behavior from any member, including those with decision-making authority, will not be
          tolerated. Intentional efforts to exclude people (except as part of a consequence of the
          guidelines or other official action) from MyWorld Virtual activities are not acceptable
          and will be dealt with appropriately.
        </p>
        <p>
          Reports of harassment/discrimination will be promptly and thoroughly investigated by the
          people responsible for the safety of the space, event or activity. Appropriate measures
          will be taken to address the situation.
        </p>
        <p>
          In addition, any participants who abuse the reporting process will be considered to be in
          violation of these guidelines and subject to the same consequences. False reporting,
          especially to retaliate or exclude, will not be accepted or tolerated.
        </p>
        <h2>
          <strong>Reporting</strong>
        </h2>
        <p>
          If you believe you’re experiencing unacceptable behavior that will not be tolerated as
          outlined above, please contact creator@myworldglobal.com.
        </p>
        <p>
          After receiving a concise description of your situation, they will review and determine
          the next steps. In addition to conducting any investigation, they can provide a range of
          resources, from a private consultation to other community resources. They will involve
          other colleagues or outside specialists (such as legal counsel), as needed to
          appropriately address each situation.
        </p>
        <p>
          Please also report to us if you observe a potentially dangerous situation, someone in
          distress, or violations of these guidelines, even if the situation is not happening to
          you.
        </p>
        <p>
          If you feel you have been unfairly accused of violating these guidelines, please follow
          the same reporting process.
        </p>
        <h2>
          <strong>Ask Questions</strong>
        </h2>
        <p>
          Everyone is encouraged to ask questions about these guidelines. If you are organizing an
          event or activity, reach out for tips for building inclusion for your event, activity or
          space. Your input is welcome.
        </p>
        <p>
          <strong>Modifications to these Guidelines</strong>
        </p>
        <p>
          MyWorld Virtual may amend the guidelines from time to time and may also vary the
          procedures it sets out where appropriate in a particular case. Your agreement to comply
          with the guidelines will be deemed agreement to any changes to it.{' '}
        </p>

        <p id="terms-of-service">
          <strong>Terms of Service for Create VR Spoke by Mozilla within MyWorld Virtual</strong>
        </p>
        <p>Version 1.0, Effective May 1, 2020</p>

        <p>
          Welcome Creators! Thank you for joining the Virtual Revolution. Your creations, vision and
          experiences will play an integral role within our community of creators, world builders,
          storytellers and explorers. Together, we will engineer the future by creating the next
          generation upgrade and replacement for the world wide web as we know it. In MyWorld, no
          individual contributor is more important than the community. We believe that, “Nobody can
          do everything, but everyone can do something”. Every interaction is an opportunity to
          learn, so we say to the community, “each one, teach one”. We are extremely excited and
          proud to be able to share your creations with our global community. As we watch our scenes
          and our creators grow and evolve, we only hope to inspire you as much as we have been
          inspired by our community and the incredible open-source community as a whole! This is our
          opportunity to rebuild and redistribute the web with 20/20 hindsight. Together we will
          build the 3D web. Let’s get started.{' '}
        </p>

        <p>
          Spoke by Mozilla is a tool used to arrange 3D models, architecture, images, videos, and
          more into scenes which can be used or experienced, within the MyWorld Virtual community as
          well as guests. Spoke by Mozilla makes 3D content from across the web, available for
          consumption for anyone with a PC, VR gear or a connected mobile device. Spoke by Mozilla
          has licensed the use of their technology to MyWorld Virtual and its users. The terms and
          conditions set forth by MyWorld Virtual are specific to using Spoke by Mozilla technology
          within the MyWorld Virtual platform. By using Create VR with Spoke by Mozilla through
          MyWorld Virtual you agree to the terms and conditions stated by MyWorld Virtual.{' '}
        </p>

        <p>
          In MyWorld, you can share your story by creating 3 dimensional spaces, web pages, and
          experiences.
        </p>
        <p>
          {' '}
          IMPORTANT: When you publish a scene using Create VR with Spoke by Mozilla, your scene will
          be accessible to the entirety of the MyWorld Virtual community and guests. Only you can
          decide to publish your scenes on the MyWorld Virtual platform. Your scenes are a
          reflection of you and only you are responsible for the content you decide to share. Do not
          share your login information with others as you will be responsible for the information
          transmitted by others while using your account. Publishing a “scene” is similar to making
          a public post on Facebook, Instagram, Twitter or any social media platform (only cooler
          and in 3D). If you don’t want others to see your work, do not publish your content using
          create VR with Spoke by Mozilla. MyWorld Virtual proudly promotes freedom of expression,
          creativity, and sharing information on and across our digital plane. We prefer good vibes,
          truthful content, and genuine interaction, please read and review our{' '}
          <u>Community Participation Guidelines</u>. Try to keep it positive, informative, and
          honest while using the MyWorld Virtual Platform to publish your individual contributions.
        </p>

        <p>
          These <u>Terms of Service</u> explain your rights and responsibilities when you use Create
          VR with Spoke by Mozilla.
        </p>
        <p>
          <strong>1. Privacy Policy</strong>
        </p>
        <p>
          The MyWorld Virtual. <u>Privacy Notice</u> explains what information we collect when you
          use MyWorld Virtual. or Create VR Spoke by Mozilla and how that information is handled and
          shared.
        </p>
        <p>
          <strong>2. Communications and Content</strong>
        </p>
        <p>
          MyWorld Virtual allows users to send information (such as audio, video, text, images, 3D
          models, and scenes) to other users.
        </p>

        <p>
          Create VR Spoke by Mozilla allows users to arrange 3D models into scenes that can be seen,
          visited, or customized by users. Published scenes can be found on MyWorld Virtual member
          portal and or within the MyWorld Matrix scene finder.{' '}
        </p>

        <p>
          By using Create VR Spoke by Mozilla, you agree to give MyWorld Virtual all rights
          necessary to operate MyWorld Virtual and Create VR Spoke by Mozilla. This includes, but is
          not limited to a license and permission to process, transmit, and display the information
          you send through MyWorld Virtual and Create VR Spoke by Mozilla. It also includes
          permission to gather and share information as described in the <u>Privacy Notice </u>for
          MyWorld Virtual.
        </p>

        <p>
          When you submit information through MyWorld Virtual or Create VR Spoke by Mozilla, you
          continue to own the rights to your content. MyWorld Virtual does not claim ownership of
          your scene or your intellectual property. In order for us to share your creation with the
          MyWorld Virtual community, you grant us a worldwide, royalty-free, perpetual, irrevocable,
          non-exclusive, transferable, and sublicensable license to use, copy, modify, adapt,
          prepare derivative works from, distribute, perform, and display that information, audio,
          video, images, or 3D models for the purpose of operating MyWorld Virtual and Create VR
          Spoke by Mozilla. You also agree that we may remove metadata associated with the
          information or data you submit. When you publish a scene, you agree to allow remixing of a
          scene or avatar you create. MyWorld Virtual is an open- source community where we all
          create, learn, and grow together in order to build a better tomorrow. When you publish a
          scene, you understand that others can remix and create a new personal project based on
          your completed project/ scene. This will not affect your original project which you can
          edit and republish at any time as a member in good standing. Create VR Spoke by Mozilla
          will automatically give and display the original credit to the attribution that was given
          when the original scene was published.
        </p>
        <p>
          You also represent and warrant that you have the authority to grant MyWorld Virtual all
          rights and permissions necessary for the operation of MyWorld Virtual and Create VR Spoke
          by Mozilla.
        </p>
        <p>
          You are solely responsible for the information you send, create, or edit using MyWorld
          Virtual or Create VR Spoke by Mozilla, and the consequences of sending, creating, or
          editing that information.
        </p>
        <p>
          <strong>3. Conditions of Use</strong>
        </p>
        <p>
          By using MyWorldV irtual and Create VR Spoke by Mozilla, you agree that your use will
          comply with MyWorld Virtual. <u>Conditions of Use</u>. MyWorld Virtual reserves the right
          to remove any content, suspend any users, and shut down any scene or room it reasonably
          believes has violated these conditions.{' '}
        </p>

        <p>
          Please also be aware of MyWorld Virtual <u>Community Participation Guidelines</u>, which
          address participation in MyWorld Virtual communities.{' '}
        </p>
        <p> </p>
        <p>
          <strong>4. Remixing</strong>
        </p>
        <p>
          In MyWorld You Can, create a new project based on a previous scene published by other
          MyWorld creators. You can customize previous scenes or build upon them to fit your needs.
          This process is defined as remixing.
        </p>
        <p> </p>
        <p>
          When you remix an existing scene, the original scene remains as is, and a new scene is
          published with a credit given to the original author/publisher. This credit will be
          derivative from the name you give in the attribution section when publishing a scene.
        </p>
        <p> </p>
        <p>
          Attribution is the author. Attribution is optional. We recommend using your MyWorld user
          name; however, you can use your given name or an alias as your attribution to get credit
          for the scene. The name you disclose in the attribution section will be recognized as the
          original publisher when others remix that scene.
        </p>

        <p>
          When you publish a scene using Create VR with Spoke by Mozilla, that scene will be
          automatically be a candidate for remixing. You agree and understand that your
          &quot;published scenes&quot; can be used by, remixed and modified strictly within and by
          the MyWorld Global Community. However, redistribution of your scenes, IP, or personal
          assets by users outside of the platform is prohibited. Within our community, MyWorld users
          will be able to open a private room with your scene and your scene may be made searchable
          on the member portal and inside of the application. Outside use & remixing of published
          scenes, community assets, and trademarks is prohibited without prior written consent of
          MyWorld Global and the original author. If you import a personal scene or any personal
          assets, you remain the owner and reserve the rights to your intellectual property and are
          free to use outside of the community. Importing of any scenes, assets or any content that
          is not your own, without permission or proper required attribution to the original author
          is strictly prohibited. If you find any of your published content outside of the MyWorld
          Global platform, please contact us and we will do our best to help.
        </p>

        <p>
          It is an honor to have your scene available for remixing. Unfortunately most published
          scenes will not meet the requirements to be available to the community. The deciding
          factors are based upon originality, file size, and loading efficiency. For example, if you
          create a new scene using the My Office project and a creator simply changes the wall art
          or decor, this will not pass the originality test. If a creator adds another floor or
          additional rooms, this is now an original scene. Efficiency is based on load time, file
          size, Polygon count, materials, and textures used.
        </p>

        <p>
          <strong>The Importance of Remixing </strong>
        </p>
        <p>
          To inspire the MyWorld Creator Community - Working together toward a common goal is the
          best way to build the community as a whole. Together we will build the 3 dimensional web
          of the future! Each new scene created represents the evolution of the platform and
          community as a whole{' '}
        </p>
        <p>
          To teach and nourish the growth of new creators - “Each one teach one,” Each new scene and
          creator are the keys to the evolution of our community and society a whole.{' '}
        </p>
        <p> </p>
        <p>
          <strong>5. Scene Promotion</strong>
        </p>

        <p>
          Scene promotion means your scene will be available for use by the MyWorld Virtual
          community. Your published scene will be searchable within the MyWorld Matrix choose a
          scene feature in the menu, and/or the member portal scene finder.{' '}
        </p>
        <p>
          When a scene is published, it will always be a candidate for scene promotion. However,
          most scenes will not meet the parameters stated above in the remixing section. The same as
          remixing, scene promotion will be determined by originality and efficiency.{' '}
        </p>
        <p>
          Allow promotion of my Scene – By unchecking this box when publishing a scene you are
          choosing to publish your scene anonymously. You are choosing to forfeit the attribution
          credit given to the author.{' '}
        </p>
        <p>
          Attribution is the author. Attribution is optional. We recommend using your MyWorld user
          name, however you can use your given name or an alias as your attribution to get credit
          for the scene. The name you disclose in the attribution section will be recognized as the
          original publisher when others remix that scene.{' '}
        </p>
        <p>
          <strong>6. MyWorld Virtual’s Rights</strong>
        </p>
        <p>
          MyWorld Virtual does not grant you any intellectual property rights in MyWorld Virtual or
          Create VR Spoke by Mozilla unless these Terms specifically say otherwise. For example,
          these Terms do not provide the right to use any of MyWorld Virtual.’s copyrights, trade
          names, trademarks, service marks, logos, domain names, or other distinctive brand
          features.
        </p>

        <p>
          MyWorld Virtual, distributes the Create VR Spoke by Mozilla software under an open-source
          license. Using any derivative works or content found within MyWorld Virtual platform,
          outside of MyWorld Virtual, is strictly prohibited. Using any derivative work or content
          published by MyWorld Virtual users and our creator community outside of the platform is
          strictly prohibited. MyWorld Virtual has set forth these terms and code of conduct in good
          faith, and we expect our users to adhere to these terms and expected behavior. The content
          published by individual contributors does not reflect on the beliefs, morals and standards
          of MyWorld Virtual. We offer our customers a digital medium to share their work. If you
          believe any scenes violate these terms or code of conduct, please notify us and we will
          review your inquiry as soon as possible.{' '}
        </p>
        <p>
          <strong>7. Services Interruption; Term; Termination</strong>
        </p>
        <p>
          We are continuing to develop MyWorld Virtual. and Create VR Spoke by Mozilla. As a result,
          we plan to upgrade and change them over time. To do this, we might have to temporarily
          suspend their service and it is not always possible for us to give notice. You will not be
          entitled to claim expenses or damages for such suspension or limitation of the use of
          MyWorld Virtual and Create VR Spoke by Mozilla.
        </p>

        <p>
          These Terms apply to your use of MyWorld Virtual and Create VR Spoke by Mozilla and will
          continue to apply until ended by either you or upon notice from MyWorld Virtual. You can
          choose to end them at any time for any reason by discontinuing your use of MyWorld Virtual
          and Create VR Spoke by Mozilla.
        </p>

        <p>
          We may cut off your access to MyWorld Virtual and Create VR Spoke by Mozilla, either
          temporarily or permanently at any time for any reason. This includes, but is not limited
          to, situations where we reasonably believe: (i) you have violated these Terms (ii) you
          create risk or possible legal exposure for MyWorld Virtual; or (iii) providing and
          operating MyWorld Virtual. is no longer commercially viable. If possible, we will make
          reasonable efforts to notify you through the relevant program, either MyWorld Virtual and
          Create VR Spoke by Mozilla.
        </p>

        <p>
          In all such cases, these Terms shall terminate, including, without limitation, your
          license to use MyWorld Virtual and Create VR Spoke by Mozilla., except that the sections
          with the following titles shall continue to apply: Indemnification, Disclaimer; Limitation
          of Liability and Miscellaneous.
        </p>
        <p>
          <strong>8. Indemnification</strong>
        </p>
        <p>
          You agree to defend, indemnify and hold harmless MyWorld Virtual, and its respective
          parent and affiliate companies, contractors, contributors, licensors, partners, directors,
          officers, employees and agents (&quot;Indemnified Parties&quot;) from and against any and
          all third party claims and expenses, including attorneys&apos; fees, arising out of or
          related to your use of MyWorld Virtual and Create VR Spoke by Mozilla. This includes, but
          is not limited to, claims and expenses from any content you transmit, edit, or create
          using MyWorld Virtual and Create VR Spoke by Mozilla.
        </p>
        <p>
          <strong>9. Disclaimer; Limitation of Liability</strong>
        </p>
        <p>
          THE SERVICES ARE PROVIDED &quot;AS IS&quot; WITH ALL FAULTS. TO THE EXTENT PERMITTED BY
          LAW, THE INDEMNIFIED PARTIES, HEREBY DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED,
          INCLUDING WITHOUT LIMITATION WARRANTIES THAT THE SERVICES ARE FREE OF DEFECTS,
          MERCHANTABLE, FIT FOR A PARTICULAR PURPOSE, AND NON-INFRINGING.
        </p>
        <p>
          YOU BEAR THE ENTIRE RISK AS TO SELECTING THE SERVICES FOR YOUR PURPOSES AND AS TO THE
          QUALITY AND PERFORMANCE OF THE SERVICES, INCLUDING WITHOUT LIMITATION THE RISK THAT YOUR
          CONTENT IS DELETED OR CORRUPTED. THIS LIMITATION WILL APPLY NOTWITHSTANDING THE FAILURE OF
          ESSENTIAL PURPOSE OF ANY REMEDY. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
          LIMITATION OF IMPLIED WARRANTIES, SO THIS DISCLAIMER MAY NOT APPLY TO YOU.
        </p>

        <p>
          EXCEPT AS REQUIRED BY LAW, THE INDEMNIFIED PARTIES, WILL NOT BE LIABLE FOR ANY INDIRECT,
          SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES ARISING OUT OF OR IN ANY WAY
          RELATING TO THESE TERMS OR THE USE OF OR INABILITY TO USE THE SERVICES, INCLUDING WITHOUT
          LIMITATION DIRECT AND INDIRECT DAMAGES FOR LOSS OF GOODWILL, WORK STOPPAGE, LOST PROFITS,
          LOSS OF DATA, AND COMPUTER FAILURE OR MALFUNCTION, EVEN IF ADVISED OF THE POSSIBILITY OF
          SUCH DAMAGES AND REGARDLESS OF THE THEORY (CONTRACT, TORT, OR OTHERWISE) UPON WHICH SUCH
          CLAIM IS BASED. THE COLLECTIVE LIABILITY OF THE INDEMNIFIED PARTIES, UNDER THIS AGREEMENT
          WILL NOT EXCEED $500 (FIVE HUNDRED DOLLARS). SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION
          OR LIMITATION OF INCIDENTAL, CONSEQUENTIAL, OR SPECIAL DAMAGES, SO THIS EXCLUSION AND
          LIMITATION MAY NOT APPLY TO YOU.
        </p>
        <p>
          <strong>10. Modifications to These Terms</strong>
        </p>
        <p>
          MyWorld Virtual may update these Terms from time to time. We will post the updated Terms
          online. If the changes are substantive, we may announce the update through MyWorld
          Virtual’s usual channels for such announcements such as posts, forums, or in the
          particular service itself, in this case: MyWorld Virtual and Create VR Spoke by Mozilla.
        </p>
        <p>
          Your continued use of MyWorld Virtual. and Create VR Spoke by Mozilla after we post the
          new Terms constitutes your acceptance of the new Terms. To make your review more
          convenient, we will post an effective date at the top of this page.
        </p>
        <p>
          <strong>11. Miscellaneous</strong>
        </p>
        <p>
          These Terms make up the entire agreement between you and MyWorld Virtual and concerning
          MyWorld Virtual and Create VR Spoke by Mozilla. The laws of the state of Delaware, U.S.A
          (excluding its conflict of law provisions) govern this agreement.
        </p>
        <p>
          If any portion of these Terms is held to be invalid or unenforceable, the remaining
          portions remain in full force and effect. If there is a conflict or ambiguity between a
          translated version of these terms and the English language version, the English language
          version applies.
        </p>
        <p>
          <strong>12. Contact Us</strong>
        </p>
        <p>
          For support, to provide feedback, or to report abuse of MyWorld Virtual. or Create VR by
          Spoke or violations of the Conditions of Use, you can email us at{' '}
          <u>Creator@myworldglobal.com</u>.
        </p>
      </Section>
    </Page>
  );
}
