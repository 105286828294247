import styled, { css } from 'styled-components';
import negative from 'assets/icons/accordion/closed.svg';
import positiveSmall from 'assets/icons/accordion/open_small.svg';
import positiveLarge from 'assets/icons/accordion/open_large.svg';
import { breakpointStyle } from 'utils/style';

interface IIcon {
  icon?: string;
}

/**
 * @category Styled Component
 */
const IconStyle = css<IIcon>`
  ${({ icon }) =>
    `::before {
      content: '';
      display: block;
      background: url(${icon}) no-repeat;
      width: 16px;
      height: 14px;
      margin: 0 8px 0 0;
    `}
`;

/**
 * @category Styled Component
 */
export const IconBefore = styled.i<IIcon>`
  ${({ icon }) => icon && IconStyle}
`;

/**
 * @category Styled Component
 */
export const IconClosed = styled.i`
  background: url(${negative}) no-repeat;
  width: 20px;
  height: 5px;

  ${breakpointStyle({
    content: css`
      width: 13.33px;
      height: 3.33px;
    `,
  })}
`;

/**
 * @category Styled Component
 */
export const IconOpen = styled.i`
  background: url(${positiveLarge}) no-repeat;
  width: 20px;
  height: 20px;
  ${breakpointStyle({
    content: css`
      width: 13.33px;
      height: 13.33px;
      background: url(${positiveSmall}) no-repeat;
    `,
  })}
`;

/**
 * @category Styled Component
 */
const Icon = styled.i<IIcon>`
  ${({ icon }) => css`
    background: url(${icon}) no-repeat;
  `}
  width: 20px;
  height: 20px;
`;

export default Icon;
