import React from 'react';
import { CardFeatures } from './styled';
import Flex from 'components/Flex';
import { Title, Text } from 'components/Typography';
import Section, { BACKGROUNDS } from 'components/Section';

export const SECTION_TEXT = {
  title: 'In MyWorld You Can ...',
  cards_content: [
    'Video -Voice -Text Chat In 3D Environments',
    `Attend Meetups & Virtual Events`,
    'Stream Movies Music & Media Together',
    'Create VR With Scene Editor Tools',
    'View 360 VR Videos & Web 3D On Any Device',
  ],
};

function Features() {
  return (
    <Section
      container
      id="romulus"
      direction="column"
      alignItems="center"
      justifyContent="center"
      bg={BACKGROUNDS.romulus}
    >
      <Title align="center" bold size="md" margin={{ bottom: 60, mobile: { bottom: 50 } }}>
        {SECTION_TEXT.title}
      </Title>
      <Flex
        wrap="wrap"
        direction="row"
        justifyContent="center"
        margin={{ right: -38, mobile: { right: '0' } }}
      >
        <CardFeatures>
          <Text size="lg" align="center">
            Video -Voice -Text Chat In 3D Environments
          </Text>
        </CardFeatures>
        <CardFeatures>
          <Text size="lg" align="center">
            Attend Meetups <br /> & <br /> Virtual Events
          </Text>
        </CardFeatures>
        <CardFeatures>
          <Text size="lg" align="center">
            Stream Movies Music & Media Together
          </Text>
        </CardFeatures>
        <CardFeatures>
          <Text size="lg" align="center">
            Create VR With Scene Editor Tools
          </Text>
        </CardFeatures>
        <CardFeatures>
          <Text size="lg" align="center">
            View 360 VR Videos & Web 3D On Any Device
          </Text>
        </CardFeatures>
      </Flex>
    </Section>
  );
}

export default React.memo(Features);
