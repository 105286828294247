import React, { Fragment, useEffect } from 'react';
import Button from 'components/Button';
import Flex from 'components/Flex';
import Section, { BACKGROUNDS } from 'components/Section';
import { Paragraph, RetroTitle, Text } from 'components/Typography';
import Page from 'containers/Page';
import { observer } from 'mobx-react';
import { authStore } from 'stores';
import OtpForm, { OtpContainer } from 'containers/OtpForm';
import RecaptchaHolder from 'components/RecaptchaHolder';
import { IconBefore } from 'components/Icon';
import warning_icon from 'assets/icons/warning_red.svg';
import colors from 'theme/colors';
import LoginForm from './store';
import { ObservedFormGroup } from 'containers/Form';
import { Helmet } from 'react-helmet';
import { InnerLink } from 'components/Link';

let store: LoginForm = new LoginForm();

// reaction(
//   () => authStore.isLoggedIn,
//   (value, preValue) => {
//     if (!preValue && value) {
//       store = new LoginForm();
//     }
//   },
// );

const NumberForm = observer(() => {
  useEffect(() => {
    return () => {
      store = new LoginForm();
    };
  }, []);

  return (
    <Flex direction="row">
      <Flex flex={1}>
        <Flex as="form" margin={{ bottom: 24 }}>
          {authStore.firebaseError || store.form.fields.phone.error ? (
            <Flex margin={{ right: 25, vertical: 15 }} direction="row" alignItems="baseline">
              <IconBefore icon={warning_icon} />
              <Paragraph margin="0" size="sm" color={colors.text.error}>
                {authStore.firebaseError || store.form.fields.phone.error}
              </Paragraph>
            </Flex>
          ) : null}
          <ObservedFormGroup store={store} field="phone" containerProps={{ withMargin: false }} />
        </Flex>
        <Button as="input" type="submit" value="Next" onClick={store.onSubmit} />
      </Flex>
      <Flex flex={1} flexMobile={0} />
    </Flex>
  );
});

const Login = observer(() => {
  return (
    <Page>
      <Helmet>
        <title>Login to MyWorld</title>
      </Helmet>
      <Section bg={BACKGROUNDS.matrix} direction="row-responsive" container>
        <RecaptchaHolder />
        <Flex flex={1} flexMobile={0} />
        <Flex flex={1} direction="column" justifyContent="center">
          {!authStore.isLoggedIn && !authStore.isWaitingOtp ? (
            <Fragment>
              <RetroTitle size="sm">Login</RetroTitle>
              <Text size="md">Login to pin objects in the room</Text>
              <NumberForm />
              <Paragraph>A code will be sent to your mobile number.</Paragraph>
              <Paragraph size="sm">
                Don{"'"}t have a account?{' '}
                <InnerLink color={colors.text.success} href="/signup">
                  Join for Free
                </InnerLink>
              </Paragraph>
            </Fragment>
          ) : authStore.isWaitingOtp ? (
            <OtpContainer signup={false}>
              <OtpForm />
            </OtpContainer>
          ) : null}
        </Flex>
      </Section>
    </Page>
  );
});

export default Login;
