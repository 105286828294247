import React from 'react';
import { observer } from 'mobx-react';
import { authStore } from 'stores';
import Page from 'containers/Page';
import Link from 'components/Link';
import { FaSignOutAlt } from 'react-icons/fa';
import Section from 'components/Section';
import ProfileInformations from './Informations';
import Accordion from 'components/Accordion';
import { LineSeparator } from 'components/Divider';
import { RetroTitle, Subtitle } from 'components/Typography';
import { Helmet } from 'react-helmet';
import SubscriptionSection from 'containers/SubscriptionSection';

const Profile = observer(() => {
  return (
    <Page>
      <Helmet>
        <title>Profile</title>
      </Helmet>
      <Section margin={{ bottom: 100 }}>
        <RetroTitle size="xs" color="#2CAD52" margin={{ bottom: 10, top: 0, mobile: { top: 25 } }}>
          @{authStore.user?.gamerTag}
        </RetroTitle>
        <Subtitle margin="0" size="sm">
          {authStore.user?.email}
        </Subtitle>
        <Accordion title="Profile information">
          <ProfileInformations />
        </Accordion>
        <Accordion title="Your Subscription">
          <SubscriptionSection />
        </Accordion>
        <LineSeparator alignment="horizontal" />
        <Link onClick={authStore.logout}>
          <FaSignOutAlt size={20} />
          Sign Out
        </Link>
      </Section>
    </Page>
  );
});

export default Profile;
