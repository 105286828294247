import React from 'react';
import ProfileForm from './store';
import { observer } from 'mobx-react';
import { authStore } from 'stores';
import Flex from 'components/Flex';
import Button from 'components/Button';
import { Subtitle } from 'components/Typography';
import { LineSeparator } from 'components/Divider';
import { makeObservable, observable, reaction } from 'mobx';
import { ObservedFormGroup } from 'containers/Form';

@observer
class ProfileInformations extends React.Component<any, any> {
  store: ProfileForm;

  constructor(props: any) {
    super(props);
    makeObservable(this, { store: observable });
    this.store = new ProfileForm();
  }

  disposable = reaction(
    () => authStore.user,
    () => {
      this.store = new ProfileForm();

      this.disposable();
    },
  );

  get basicInfo() {
    return Object.keys(this.store.form.fields).slice(0, 5);
  }

  get socialInfo() {
    return Object.keys(this.store.form.fields).slice(5);
  }

  render() {
    return (
      <Flex as="form" onSubmit={this.store.onSubmit} direction="column">
        <Flex alignItems="stretch" direction="row-responsive" wrap="wrap">
          <Flex flex={1}>
            <Subtitle margin={{ bottom: 28 }} size="sm">
              Basic Information
            </Subtitle>
            {this.store &&
              this.basicInfo.map((key: any, index: any) => {
                if (index % 2 === 0) {
                  return (
                    <Flex key={`form-group${index}`} direction="row-responsive">
                      <ObservedFormGroup
                        store={this.store}
                        field={key}
                        whiteLabel
                        labelFromPlaceholder
                      />
                      {this.basicInfo[index + 1] ? (
                        <ObservedFormGroup
                          store={this.store}
                          field={this.basicInfo[index + 1]}
                          labelFromPlaceholder
                          whiteLabel
                        />
                      ) : (
                        <Flex flex={1} margin={{ left: 14, mobile: { left: 0 } }} />
                      )}
                    </Flex>
                  );
                }
              })}
          </Flex>
          <Flex hide="tab-below" padding={{ horizontal: 48 }} justifyContent="space-evenly">
            <LineSeparator alignment="vertical" />
          </Flex>
          <Flex flex={1}>
            <Subtitle margin={{ bottom: 28 }} size="sm">
              On the web
            </Subtitle>
            {this.store &&
              this.socialInfo.map((key: any, index: any) => {
                if (index % 2 === 0) {
                  return (
                    <Flex key={`form-group${index}`} direction="row-responsive">
                      <ObservedFormGroup
                        store={this.store}
                        field={key}
                        whiteLabel
                        labelFromPlaceholder
                      />
                      {this.socialInfo[index + 1] ? (
                        <ObservedFormGroup
                          store={this.store}
                          field={this.socialInfo[index + 1]}
                          labelFromPlaceholder
                          whiteLabel
                        />
                      ) : (
                        <Flex flex={1} margin={{ left: 14, mobile: { left: 0 } }} />
                      )}
                    </Flex>
                  );
                }
              })}
          </Flex>
        </Flex>
        <Flex justifyContent="center" direction="row" margin={{ top: 40 }}>
          <Button as="input" type="submit" value="Update" />
        </Flex>
      </Flex>
    );
  }
}

export default ProfileInformations;
