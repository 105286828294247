import styled, { css } from 'styled-components';
import { NavLink as RLink } from 'react-router-dom';
import { edgeStyle, EdgeStyleData } from 'utils/style';

// @Todo Extend Color to be from palette
interface ILink {
  icon?: string;
  color?: string;
  solid?: boolean;
  margin?: EdgeStyleData;
  padding?: EdgeStyleData;
}

const styledIcon = (icon: string) => {
  return css`
    /* align-self: center; */
    &:before {
      content: '';
      color: inherit;
      padding-left: 20px;
      margin-right: 10px;
      background-repeat: no-repeat;
      background-image: url(${icon});
      background-position: left center;
    }
  `;
};

const styledColor = (color: string) => {
  return css`
    color: ${color};
  `;
};

const styledSolid = css`
  color: black;
  font-size: 14px;
  font-weight: 600;
  min-width: 140px;
  box-sizing: border-box;
  justify-content: center;
  background-color: #2cad52;
  padding: 10px 18px 10px 18px;
  &.active {
    font-weight: bold;
    color: white;
  }
`;

/**
 * @category Styled Component
 */
const Link = styled.a<ILink>`
  color: white;
  font-size: 18px;
  cursor: pointer;
  font-weight: 400;
  line-height: 27px;
  font-style: normal;
  letter-spacing: 0em;
  font-family: Poppins;
  text-decoration: none;
  transition: opacity 500ms;

  display: flex;
  align-items: center;
  > svg {
    margin-right: 10px;
  }

  &.active {
    font-weight: bold;
    color: #2cad52;
  }

  :hover {
    opacity: 0.5;
  }

  ${({ solid }) => solid && styledSolid}
  ${({ icon }) => icon && styledIcon(icon)}
  ${({ color }) => color && styledColor(color)}
  ${({ margin }) => margin && edgeStyle('margin', margin)}
  ${({ padding }) => padding && edgeStyle('padding', padding)}
`;

Link.defaultProps = {
  margin: { left: 25 },
};

export const RouterLink = styled(RLink).attrs({ activeClassName: 'active' })``;
export const HomeLink = styled(RouterLink)`
  font-size: 0;
`;
export const InnerLink = styled(Link).attrs({ margin: '0' })`
  font-size: inherit;
  display: inline-flex;
`;

export default Link;
