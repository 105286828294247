import React from 'react';
import { observer } from 'mobx-react';
import { TutorialButton, TutorialContainer, TutorialThumbnail, TutorialImage } from './styled';
import Flex from 'components/Flex';
import { IRoomTutorial } from 'stores/domain/rooms';
import { Title, Paragraph, Subtitle } from 'components/Typography';

import keyboard from 'assets/images/tutorial/Keyboard.png';
import touchscreem from 'assets/images/tutorial/Touchscreen.png';
import vr from 'assets/images/tutorial/VR.png';

const TITLE = 'In MyWorld You Can...';
const DEFAULT_IMG =
  'https://myworldvirtual-hubs-assets.myworldvirtual.com/files/4ed8234a-daa1-497c-a40a-6b516fb7c089.jpg';

const ChangeYourEntireLifePanel = ({
  openRoom,
  isMobile = false,
}: {
  openRoom: any;
  isMobile: boolean;
}) => (
  <Flex
    flex="1"
    direction="column"
    justifyContent="center"
    alignContent="center"
    margin={{ top: 0 }}
    padding={{ right: isMobile ? 0 : 60, mobile: { right: 0 } }}
  >
    <Flex
      flex="1"
      direction="column"
      justifyContent="center"
      alignContent="center"
      margin={{ top: 0 }}
      padding={{ bottom: 14 }}
    >
      <Paragraph
        size="md"
        margin={{ top: 0, bottom: 0, mobile: { bottom: 0 } }}
        className="tk-raleway"
        align="center"
      >
        Meet, Stream and Video Chat in over 1,000 of our Private Virtual Spaces
      </Paragraph>
      <Paragraph size="md" margin="0" className="tk-raleway" align="center">
        &
      </Paragraph>
      <Paragraph
        size="md"
        margin={{ top: 0, bottom: 30, mobile: { bottom: 30 } }}
        className="tk-raleway"
        align="center"
      >
        Create your own 3D Environments to share with our global community
      </Paragraph>
      <Subtitle size="lg" color="#2CAD52" margin="0" className="tk-raleway" align="center">
        Web 3D will change your world
      </Subtitle>
      <Subtitle size="lg" color="#2CAD52" margin="0" className="tk-raleway" align="center">
        Choose your device and take a tour
      </Subtitle>
    </Flex>
    <Flex direction="row-responsive" justifyContent="space-between" padding={{ bottom: 5 }}>
      <Flex>
        <TutorialImage src={keyboard} />
        <TutorialButton onClick={openRoom}>Keyboard</TutorialButton>
      </Flex>
      <Flex>
        <TutorialImage src={touchscreem} />
        <TutorialButton
          onClick={() =>
            window.open('https://app.myworldvirtual.com/vo2xmab/touch-screen-jump-program/')
          }
        >
          Touchscreen
        </TutorialButton>
      </Flex>
      <Flex>
        <TutorialImage src={vr} />
        <TutorialButton
          onClick={() => window.open('https://app.myworldvirtual.com/LHDP6uy/vr-jump-program/')}
        >
          VR
        </TutorialButton>
      </Flex>
    </Flex>
  </Flex>
);

const Tutorial = observer((props: IRoomTutorial) => {
  const { image = DEFAULT_IMG, openRoom } = props.data || {};
  return (
    <TutorialContainer>
      <Flex hide="mobile">
        <Title align="center" margin="0 0 20" padding="0" className="tk-raleway">
          {TITLE}
        </Title>
      </Flex>
      <Flex flex="1" direction="row" hide="mobile" margin={{ mobile: { bottom: 30 } }}>
        <ChangeYourEntireLifePanel isMobile={false} openRoom={openRoom} />
        <TutorialThumbnail src={image} />
      </Flex>
      <Flex
        flex="1"
        hide="desktop"
        justifyContent="center"
        alignContent="center"
        margin={{ top: 25, mobile: { top: 0 } }}
        padding={0}
      >
        <Flex hide="desktop">
          <TutorialThumbnail src={image} />
          <Title align="center" margin={{ mobile: { top: 30 } }} className="tk-raleway">
            {TITLE}
          </Title>
        </Flex>
        <ChangeYourEntireLifePanel isMobile={false} openRoom={openRoom} />
      </Flex>
    </TutorialContainer>
  );
});

export default Tutorial;
