import { css, FlattenSimpleInterpolation } from 'styled-components';

export const BREAK_POINTS: { [index: string]: number } = {
  mobile: 768,
  tablet: 1280,
  desktop: 1920,
  uhd: 3840,
};

const defaultBreakPoint = BREAK_POINTS.mobile;

export const parseStyleValue = (value: string | number): string => {
  const v: string = value.toString();
  const conditions = ['%', 'px', 'em', 'rem', 'auto'];
  if (new RegExp(conditions.join('|')).test(v)) {
    return v;
  }
  return `${v}px`;
};

type breakpointParams = {
  breakpoint?: {
    value: number;
    type?: 'min' | 'max';
  };
  content: FlattenSimpleInterpolation;
};

export type edgeInsetData =
  | string
  | number
  | {
      horizontal?: string | number;
      vertical?: string | number;
      right?: string | number;
      left?: string | number;
      top?: string | number;
      bottom?: string | number;
    };

export type EdgeStyleData =
  | string
  | number
  | {
      mobile?: edgeInsetData;
      horizontal?: string | number;
      vertical?: string | number;
      right?: string | number;
      left?: string | number;
      top?: string | number;
      bottom?: string | number;
    };

const axisDefinition = {
  horizontal: ['left', 'right'],
  vertical: ['top', 'bottom'],
};

export const edgeStyle = (kind: string, data: EdgeStyleData) => {
  if (typeof data === 'string' || typeof data === 'number') {
    const parsedData = parseStyleValue(data);
    const content = `${kind}: ${parsedData};`; // Fix linting issue
    return css`
      ${content}
    `;
  }

  const results = [];
  const getCSSProps = (key: string, val: string | number, isMobile: boolean) => {
    const value = parseStyleValue(val);

    const isMobileCallback = (content: string) => {
      return isMobile
        ? breakpointStyle({
            content: css`
              ${content}
            `,
          })
        : css`
            ${content}
          `;
    };

    if (key === 'horizontal' || key === 'vertical') {
      return isMobileCallback(`
            ${kind}-${axisDefinition[key][0]}: ${value};
            ${kind}-${axisDefinition[key][1]}: ${value};
          `);
    } else {
      return isMobileCallback(`${kind}-${key}: ${value};`);
    }
  };

  const { mobile, ...desktop } = data;
  // desktop
  for (const [key, value] of Object.entries(desktop)) {
    results.push(getCSSProps(key, value, false));
  }

  // mobile
  if (mobile) {
    for (const [key, value] of Object.entries(mobile)) {
      results.push(getCSSProps(key, value, true));
    }
  }
  return results;
};

export const blockStyle = (kind: string, data: EdgeStyleData) => {
  if (typeof data === 'string' || typeof data === 'number') {
    // Fix linting issue
    return css`
      ${kind}-block-start:0;
      ${kind}-block-end:0;
    `;
  }

  const results = [];
  const getCSSProps = (key: string, val: string | number, isMobile: boolean) => {
    const isMobileCallback = (content: string) => {
      return isMobile
        ? breakpointStyle({
            content: css`
              ${content}
            `,
          })
        : css`
            ${content}
          `;
    };

    if (key === 'vertical') {
      return isMobileCallback(`
      ${kind}-block-start:0;
      ${kind}-block-end:0;
    `);
    } else {
      if (key === 'top') return isMobileCallback(`${kind}-block-start:0;`);
      if (key === 'bottom') return isMobileCallback(`${kind}-block-end:0;`);
    }
  };

  const { mobile, ...desktop } = data;
  // desktop
  for (const [key, value] of Object.entries(desktop)) {
    results.push(getCSSProps(key, value, false));
  }

  // mobile
  if (mobile) {
    for (const [key, value] of Object.entries(mobile)) {
      results.push(getCSSProps(key, value, true));
    }
  }

  return results;
};

export const breakpointStyle = ({ breakpoint, content }: breakpointParams) => {
  const breakPointType = breakpoint?.type ? breakpoint.type : 'max';
  const breakPointValue = breakpoint?.value
    ? breakPointType == 'max'
      ? breakpoint.value - 1
      : breakpoint.value
    : breakPointType == 'max'
    ? defaultBreakPoint - 1
    : defaultBreakPoint;
  return css`
    @media only screen and (${breakPointType}-width: ${breakPointValue}px) {
      ${content}
    }
  `;
};

export const breakpointTab = (content: FlattenSimpleInterpolation) =>
  breakpointStyle({
    breakpoint: {
      value: BREAK_POINTS.desktop,
    },
    content,
  });

export const breakpointMobile = (content: FlattenSimpleInterpolation) =>
  breakpointStyle({
    breakpoint: {
      value: BREAK_POINTS.tablet,
    },
    content,
  });
