import React from 'react';
import Button from 'components/Button';
import { authStore, subscriptionStore } from 'stores';
import { observer } from 'mobx-react';
import { LOGIN_MODAL_TYPE, loginUIStore } from 'stores/ui/modal';

const SubscriptionButton = observer(() => {
  return (
    <Button
      onClick={
        authStore.isLoggedIn
          ? subscriptionStore.subscriptionAction
          : () => loginUIStore.show(LOGIN_MODAL_TYPE.subscribe)
      }
      disabled={subscriptionStore.loading}
    >
      {subscriptionStore.loading
        ? 'Loading...'
        : subscriptionStore.isSubscribed
        ? 'Manage Plan'
        : 'Subscribe Now'}
    </Button>
  );
});

export default SubscriptionButton;
