import { observer } from 'mobx-react';
import React from 'react';
import Button from 'components/Button';
import Flex from 'components/Flex';
import Modal from 'components/Modal';
import { TextHighlight, Span, Subtitle, Paragraph } from 'components/Typography';
import { subscriptionStore } from 'stores';
import { sceneUIStore } from 'stores/ui/modal';

const getPlanName = (plan: string) => {
  return plan.toLowerCase() === 'unpaid' ? 'Free' : plan;
};

const SceneModal = observer(() => {
  return (
    <Modal onClose={sceneUIStore.close} show={sceneUIStore.open}>
      <Modal.Header>
        <TextHighlight size="md" margin="0" bold={false}>
          “
          <Span size="inherit" color="#2cad52" bold={false}>
            {sceneUIStore.name}
          </Span>
          ” is available to All Access Members Only
        </TextHighlight>
      </Modal.Header>
      <Modal.Body>
        <Subtitle size="sm" margin="0">
          You are on a <Span color="#2cad52">{getPlanName(subscriptionStore.userPlan)} plan</Span>
        </Subtitle>
        <Paragraph margin={{ top: 15 }}>
          Your trial period is complete. Subscribe now to regain access the member portal.
        </Paragraph>
        <Paragraph margin={{ top: 0 }}>
          Members can open new private rooms with any scene in MyWorld & Create new scenes with our
          Create VR scene editor tools.
        </Paragraph>
      </Modal.Body>
      <Modal.Footer direction="row-responsive" justifyContent="space-between">
        <Flex padding={{ right: 15, mobile: { right: 0, bottom: 10 } }} width="100%">
          <Button wide={false} light onClick={sceneUIStore.close}>
            Cancel
          </Button>
        </Flex>
        <Flex padding={{ left: 15, mobile: { left: 0, top: 10 } }} width="100%">
          <Button wide={false} onClick={() => sceneUIStore.redirect('/subscribe')}>
            Subscribe Now
          </Button>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
});

export default SceneModal;
