import { action, makeObservable } from 'mobx';
import { IRegBody } from 'types/user';
import ValidationBaseStore from 'stores/validationBase';
import { countryNames, getCountryNames } from 'utils/countries';
import { authStore } from 'stores';

class SignupForm extends ValidationBaseStore {
  form = {
    fields: {
      username: {
        value: '',
        error: false,
        rule: 'required|social_media_username|gamer_tag',
        placeholder: 'Username/Alias',
        type: 'text',
      },
      email: {
        value: '',
        error: false,
        rule: 'required|email|registration_email',
        placeholder: 'Email Address',
        type: 'email',
      },
      country: {
        value: 'United States',
        error: false,
        rule: `required|in:${countryNames}`,
        placeholder: 'Country',
        type: 'country',
        list: 'country-list',
        listData: getCountryNames(),
      },
      state: {
        value: '',
        error: false,
        rule: 'required|min:2',
        placeholder: 'State / Province',
        type: 'text',
      },
      city: {
        value: '',
        error: false,
        rule: 'required|min:4',
        placeholder: 'City',
        type: 'text',
      },
      phone: {
        value: '+1',
        error: false,
        rule: 'required|phone|unregistered_phone',
        placeholder: 'Mobile Number Login',
        type: 'phone',
      },
      referrer_tag: {
        value: '',
        error: false,
        rule: 'social_media_username|referrer_tag',
        placeholder: 'Referrer tag',
        type: 'text',
      },
    },
  };

  constructor() {
    super();
    makeObservable(this, {
      onSubmit: action.bound,
    });
  }

  onSubmit = (e: any) => {
    e.preventDefault();
    this.validateForm();
    if (this.isValid) authStore.requestOTP(this.form.fields['phone'].value);
  };

  serializeData = (): IRegBody => {
    return {
      gamer_tag: this.form.fields['username'].value,
      city: this.form.fields['city'].value,
      state: this.form.fields['state'].value,
      country: this.form.fields['country'].value,
      email: this.form.fields['email'].value,
      firebase_token: '',
      ...(this.form.fields.referrer_tag.value
        ? { referrer: this.form.fields.referrer_tag.value }
        : {}),
    };
  };
}

export default SignupForm;
