import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import bgMatrix from 'assets/images/bg_matrix_mid.png';
import bgRomulus from 'assets/images/bg_romulus_all.svg';
import bgGirl from 'assets/images/bg_girl_large.svg';
import bgHolo from 'assets/images/bg_bottom_green.svg';
import bgMobileRomulus from 'assets/images/bg_romulus_small.svg';
import Flex, { IFlex } from './Flex';
import { BREAK_POINTS, breakpointStyle } from 'utils/style';
import { HTMLAttributes, ReactNode } from 'react';
import React from 'react';
import Container from './Container';

export enum BACKGROUNDS {
  matrix,
  matrixTop,
  romulus,
  girl,
  holo,
}

const backgroundImage = ({ bg }: ISection): FlattenSimpleInterpolation | null => {
  switch (bg) {
    case BACKGROUNDS.matrix:
      return css`
        background: url(${bgMatrix});
        background-size: contain;
        background-position: 0 0;
        background-repeat: no-repeat;
      `;
    case BACKGROUNDS.matrixTop:
      return css`
        background: url(${bgMatrix});
        background-size: initial;
        background-position: 0 0;
        background-repeat: no-repeat;
        ${breakpointStyle({
          content: css`
            background-position: 50px 0;
          `,
        })}
      `;
    case BACKGROUNDS.romulus:
      return css`
        background: url(${bgRomulus});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        ${breakpointStyle({
          content: css`
            background-size: contain;
            background-image: url(${bgMobileRomulus});
          `,
        })}
      `;
    case BACKGROUNDS.girl:
      return css`
        :after {
          content: '';
          display: flex;
          overflow: visible;
          flex: 1;
          background-size: 750px;
          background-position: left;
          background-repeat: no-repeat;
          background-image: url(${bgGirl});
        }
        ${breakpointStyle({
          breakpoint: {
            type: 'max',
            value: BREAK_POINTS.tablet,
          },
          content: css`
            display: flex;
            flex-direction: column-reverse;
            align-items: flex-start;
            & * {
              max-width: none;
            }
            :after {
              content: '';
              width: 100%;
              height: 750px;
              background-size: auto 750px;
              background-position: center;
              background-repeat: no-repeat;
              background-image: url(${bgGirl});
            }
          `,
        })}
        ${breakpointStyle({
          content: css`
            :after {
              height: calc(100vw + 80px);
              background-size: 120%;
              background-position: bottom;
            }
          `,
        })}
      `;
    case BACKGROUNDS.holo:
      return css`
        background-position: center;
        background-size: 80%;
        background-repeat: no-repeat;
        background-image: url(${bgHolo});
      `;
  }

  return null;
};

interface ISection extends IFlex {
  bg?: BACKGROUNDS;
  container?: boolean;
  children?: ReactNode;
}

/**
 * @category Styled Component
 */
const SectionContainer = styled(Flex).attrs((props) => ({
  as: 'section',
  ...props,
}))<ISection>`
  min-height: 100vh;
  ${(props) => props.bg !== null && backgroundImage(props)}
`;

const Section = ({
  children,
  container,
  margin,
  bg,
  ...props
}: ISection & HTMLAttributes<HTMLDivElement>) => {
  if (container)
    return (
      <SectionContainer bg={bg} direction="row" justifyContent="center" flex={1} margin={margin}>
        <Container.Content {...props}>{children}</Container.Content>
      </SectionContainer>
    );
  else
    return (
      <SectionContainer bg={bg} {...props}>
        {children}
      </SectionContainer>
    );
};

Section.defaultProps = {
  container: true,
};

export default Section;
