import { RootStore } from '../index';
import { AuthStore } from '../auth';
import { ApiResponse } from 'apisauce';
import { action, flow, makeObservable, observable } from 'mobx';
import type { JsonObject } from 'types/general';
import { Resource } from 'services/api';

/**
 * Base store that defined basic requirements for a store model
 * @category Stores
 */
export abstract class BaseStore {
  parent: RootStore | any | AuthStore;
  isLoading = false;
  id = 0;
  createdAt?: Date;
  updatedAt?: Date;

  updater?: (data: JsonObject) => Promise<ApiResponse<any, any>>;
  creator?: (data: JsonObject) => Promise<ApiResponse<any, any>>;
  deleter?: () => Promise<ApiResponse<any, any>>;
  loader?: () => Promise<ApiResponse<any, any>>;

  protected constructor(parent: RootStore | any | AuthStore) {
    this.parent = parent;
    makeObservable(this, {
      isLoading: observable,
      id: observable,
    });
  }

  /**
   * Initializer, should be used in the constructor when extended
   * @param data The response received from the http request
   *
   * ```typescript
   * constructor(parent: RootStore,data: JsonObject){
   *     this.initialize(data);
   * }
   * ```
   */
  initialize(data: JsonObject) {
    this.consume(data);
  }

  update = flow(function* (this: BaseStore) {
    if (this.updater) {
      this.isLoading = true;
      const response = yield this.updater(this.serialize());
      if (response.ok) {
        this.consume(response.data);
      }

      this.isLoading = false;
    }
  });

  create = flow(function* (this: BaseStore) {
    if (this.creator) {
      this.isLoading = true;
      const response = yield this.creator(this.serialize());
      if (response.ok) {
        this.consume(response.data);
        if (this.parent instanceof BaseListStore) {
          this.parent.add(this);
        }
      }

      this.isLoading = false;
    }
  });

  load = flow(function* (this: BaseStore) {
    if (this.loader) {
      this.isLoading = true;
      const response = yield this.loader();
      if (response.ok) {
        this.consume(response.data);
      }

      this.isLoading = false;
    }
  });

  delete = flow(function* (this: BaseStore) {
    if (this.deleter) {
      this.isLoading = true;

      const response = yield this.deleter();
      if (response.ok) {
        if (this.parent instanceof BaseListStore) {
          this.parent.remove(this);
        }
      }

      this.isLoading = false;
    }
  });

  abstract serialize: () => JsonObject;

  abstract consume(data: JsonObject): void;

  bindService(service: Resource) {
    this.creator = (data) => service.create(data);
    this.updater = (data) => service.update(this.id, data);
    this.deleter = () => service.delete(this.id);
    this.loader = () => service.get(this.id);
  }
}

export abstract class BaseListStore<T extends BaseStore> {
  parent?: RootStore;
  sortKey?: string;
  sortType: 'ASC' | 'DESC' = 'ASC';
  isLoading = false;
  isInitialized = false;
  page = 0;
  anchor = 0;
  totalPages = 0;
  filterKeyword = '';
  rawData: Array<T>;

  loader?: () => Promise<ApiResponse<any, any>>;

  protected constructor(parent: RootStore) {
    this.parent = parent;
    this.rawData = [];
    makeObservable(this, {
      isLoading: observable,
      isInitialized: observable,
      page: observable,
      anchor: observable,
      totalPages: observable,
      filterKeyword: observable,
      rawData: observable,
    });
  }

  get data(): Array<T> {
    return this.rawData.filter((f) => {
      // TODO: Improve implementation
      return JSON.stringify(f.serialize()).toLowerCase().includes(this.filterKeyword.toLowerCase());
    });
  }

  load = flow(function* (this: BaseListStore<T>) {
    if (this.loader && !this.isLoading) {
      this.isLoading = true;
      const response = yield this.loader();
      if (response.ok) {
        const { data } = response;
        data.forEach((entry: any) => {
          this.add(this.createNewObject(entry));
        });
      }

      this.isLoading = false;
      this.isInitialized = true;
    }
  });

  add = action((entry: T) => {
    this.rawData.push(entry);
  });

  remove = action((entry: T) => {
    delete this.rawData[this.rawData.indexOf(entry)];
  });

  setFilterKeyword = action((keyword: string) => (this.filterKeyword = keyword));

  abstract createNewObject(entry: any): T;
  bindService(service: Resource) {
    this.loader = () => service.getAll(this.sortKey, this.sortType);
  }
}
