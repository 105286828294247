import React from 'react';
import { observer } from 'mobx-react';
import Page from 'containers/Page';
import Flex from 'components/Flex';
import { authStore, navStore, sceneStore, subscriptionStore } from 'stores';
import Section from 'components/Section';
import { SceneContainer } from 'containers/HubContainer';
import { Title, Span } from 'components/Typography';
import Button from 'components/Button';
import Accordion from 'components/Accordion';
import Divider, { LineSeparator } from 'components/Divider';
import Container from 'components/Container';
import SceneSearch from './Components/SceneSearch';
import { Helmet } from 'react-helmet';
import List from './styled';

const MemberPortal = observer(() => {
  const onClick = () => {
    if (!authStore.isLoggedIn) {
      navStore.push('/signup');
    } else {
      navStore.push('/subscribe');
    }
  };

  return (
    <Page>
      <Helmet>
        <title>MyWorld Member Portal</title>
      </Helmet>
      <Section container={false}>
        <Container
          padding={{ bottom: '0 !important', mobile: { horizontal: 20 } }}
          direction="column"
        >
          <Flex
            direction="row-responsive"
            alignItems="center"
            justifyContent="space-between"
            margin={{ mobile: { bottom: 30 } }}
          >
            <Title size="sm">
              Member Portal <small>– Choose a scene below to create a new private room</small>
            </Title>
            {!subscriptionStore.isPremium && <Button onClick={onClick}>Master Key</Button>}
          </Flex>
          <SceneContainer data={sceneStore.featured} alignment="stack" showDetails />
        </Container>
        <Divider color="green" />
        <Container
          padding={{ bottom: '0 !important', mobile: { horizontal: 20 } }}
          direction="column"
        >
          <SceneSearch />
          <Accordion title="How it Works">
            <Flex flex="1" padding={{ left: 30, mobile: { left: 0 } }}>
              <List>
                <List.Item>Step 1</List.Item>
                <List.Content>
                  Find a place then select &quot;create a room with this scene&quot;
                </List.Content>
                <List.Item>Step 2</List.Item>
                <List.Content>
                  Open a private space for up to 30 Guests. Your room will remain open for 24 hrs or
                  until you open a new room
                </List.Content>
                <List.Item>Step 3</List.Item>
                <List.Content>
                  Share the room link to invite friends. You can share the link on social networks,
                  but be sure to limit your key to welcomed guests for private parties or intimate
                  gatherings.
                </List.Content>
              </List>
              <LineSeparator alignment="horizontal" />
              <List>
                <List.Item>
                  Tips
                  <List.Content>
                    Search Scene Tags by City <Span color="#23d23d"> (NYC) </Span>, Scene Name
                    <Span color="#23d23d"> (The Loft) </Span>, or by Theme
                    <Span color="#23d23d"> (Beats - Meet - Office - Movies - Beyond).</Span>
                    <List.Content></List.Content>
                    Search by username (<Span color="#23d23d">Romulus718</Span>) to locate places
                    designed by your favorite Creators.
                  </List.Content>
                  <List.Content>
                    Use the Tag &quot;<Span color="#23d23d">Remix</Span>&quot; to search places you
                    can customize and open with Scene Editor.
                  </List.Content>
                  <List.Content>
                    When you enter your private room, you can change the scene by using the room
                    menu located at the top left, without having to open a new room.
                  </List.Content>
                  <List.Content>
                    To Access your personal Create VR Scenes. Create a room with any Scene then,
                    from the room menu select &quot;choose a scene&quot; then, click the My Scenes
                    tab.
                  </List.Content>
                  <List.Content>
                    Use The Construct Scene finder, to navigate through the MyWorld Matrix and find
                    scenes not always listed on the Member Portal.
                  </List.Content>
                  <List.Content>
                    Any MyWorld domains you discover in your travels are live. You can type them
                    into your browser to find secret locations, events, and additional scenes not
                    always listed here<Span color="#23d23d"> (e.g. www.myworldportals.com)</Span>
                  </List.Content>
                </List.Item>
              </List>
            </Flex>
          </Accordion>
          <Flex margin={{ top: 35 }}>
            <SceneContainer data={sceneStore.public} alignment="stack" showDetails />
          </Flex>
        </Container>
      </Section>
    </Page>
  );
});

export default MemberPortal;
