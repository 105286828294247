import React from 'react';
import { Helmet } from 'react-helmet';
import Page from 'containers/Page';
import Section from 'components/Section';
import { RetroTitle, Paragraph } from 'components/Typography';

export default function PrivacyPolicy() {
  return (
    <Page>
      <Helmet>
        <title>MyWorld Privacy Policy</title>
      </Helmet>
      <Section>
        <RetroTitle size="sm">Privacy Notice for MyWorld Global Inc.</RetroTitle>
        <Paragraph size="sm">Version 1.0, May 1, 2020</Paragraph>
        <p>
          <strong>
            At MyWorld Virtual (that’s us), we believe that privacy is fundamental to a healthy
            internet.
          </strong>
        </p>
        <p>
          In this Privacy Notice, we explain what data may be accessible to MyWorld Virtual or
          others when you use <u>MyWorld Virtual.</u>
        </p>
        <p>
          <strong>Things you should know:</strong>
        </p>
        <p>We do not share or sell your personal data.</p>
        <p>We are focused on privacy and value trust, for everyone.</p>
        <p>We value privacy over profit. </p>
        <p>Your presence and communications are sent to MyWorld Virtual and other Room users.</p>
        <ul>
          <li>
            Avatar data: We receive and send to others in the Room the name and likeness of your
            Avatar/Digi Me, its position in the Room, and your interactions with objects in the
            Room.
          </li>
          <li>
            Custom Avatars/Digi Me: You may create a custom avatar/Digi Me. If you create an
            account, MyWorld Virtual will store your custom avatar/Digi Me, associated with your
            account.
          </li>
          <li>
            Stock Avatars: If you choose one of the regular avatars/Digi Me, you may login to store
            information about your avatar with MyWorld Virtual. You can optionally store information
            Avatar/Digi Me in your browser’s local storage if you do not log in to your account.
          </li>
          <li>
            Room data: Rooms are publicly accessible to anyone with the URL. MyWorld Virtual
            receives data about the virtual objects and Avatars/Digi Me in a Room and shares that
            data with others in the Room.
          </li>
          <li>
            Voice data: If your microphone is on, MyWorld Global Inc receives and sends audio to
            other users in the Room. Mozilla does not record or store the audio.
            <i>
              Be aware that once you agree to let MyWorld Virtual use your microphone, it will stay
              on as long as you remain in a MyWorld Virtual room, unless you turn it off.
            </i>
          </li>
          <li>
            In-Room Photos and Video: You can use the camera tool to take photos and video unless
            the room owner has disabled this feature. The camera tool displays a red icon during
            filming. Your avatar/Digi Me will also indicate to others with a red icon that you are
            filming and capturing audio from enabled microphones in the room.
          </li>
          <li>
            Data retention: If you take a photo or video, the file is stored locally on your device
            and uploaded to MyWorld Virtual’s servers so that it can be shared within the room.
            Pinned files are stored permanently in your room unless you remove it. Unpinned files
            are deleted from MyWorld Virtual’s servers after 72 hours.
          </li>
          <li>
            Third-Party Communications Services: MyWorld Virtual allows you to connect some
            third-party services to a MyWorld Virtual room.
          </li>
          <li>
            Twitter: Logging into your Twitter account from MyWorld Virtual allows you to tweet and
            share 2D images. Any content you tweet will be shared with Twitter and published
            publicly on the Twitter platform. You can see Twitter’s Privacy Policy for more
            information.
          </li>
          <li>
            Discord: MyWorld Virtual allows you to connect Discord to a MyWorld Virtual room. When
            someone has connected Discord to a MyWorld Virtual room, we store access tokens and the
            server and channel IDs that have been connected. We will synchronize chat messages, room
            changes, 2D and 3D objects you create, and whether a user joins or leaves with the
            connected Discord channel, but do not log any of these synchronized messages. For
            diagnostics, we log aggregated counts such as the number of messages and users who have
            joined relevant channels. You can see Discord’s Privacy Policy for more information.
          </li>
        </ul>
        <p>MyWorld Virtual receives data you create and share with MyWorld Virtual.</p>
        <ul>
          <li>
            Images, Video, and Objects: MyWorld Virtual receives video and image file links to
            process and display them in the MyWorld Virtual Room. MyWorld Virtual stores this data
            as long as you remain in the Room. If you pin a video, image, or object in the Room,
            MyWorld Virtual will store that information until the object or room is deleted.
          </li>
          <li>
            Scenes and Avatars/Digi Me You Share: MyWorld Virtual receives 3D Room model links, 3D
            Avatar/Digi Me information, and the name of the Room or Avatar in order to process and
            display the Room or Avatar/Digi Me. MyWorld Virtual stores the name and the URL for the
            link you share so you and others with the link to the Room can use it again.
          </li>
          <li>
            Search: You can search for images, GIFs, and 3D Models to share in MyWorld Virtual. We
            use third-party search providers. When you search, we will send your searches to these
            third parties, which have their own privacy policies.
          </li>
          <li>
            Microsoft Bing Video & Image Search:{' '}
            <a href="https://privacy.microsoft.com/en-us/privacystatement" rel="nofollow">
              https://privacy.microsoft.com/en-us/privacystatement
            </a>
          </li>
          <li>
            Tenor:{' '}
            <a href="https://tenor.com/legal-privacy" rel="nofollow">
              https://tenor.com/legal-privacy
            </a>
          </li>
          <li>
            Sketchfab:{' '}
            <a href="https://sketchfab.com/privacy" rel="nofollow">
              https://sketchfab.com/privacy
            </a>
          </li>
          <li>
            Google Poly & YouTube:{' '}
            <a href="https://policies.google.com/privacy" rel="nofollow">
              https://policies.google.com/privacy
            </a>
          </li>
          <li>
            Twitch:{' '}
            <a href="https://www.twitch.tv/p/legal/privacy-policy/" rel="nofollow">
              https://www.twitch.tv/p/legal/privacy-policy/
            </a>
          </li>
        </ul>
        <p>
          MyWorld Virtual receives data when you create and publish Scenes with CreateVR with Spoke
          by Mozilla or create a custom avatar/Digi Me.
        </p>
        <p>
          MyWorld Virtual receives technical and interaction data to improve performance and
          stability for MyWorld Virtual.
        </p>
        <ul>
          <li>
            Account data: You do not need to create an account to use MyWorld Virtual. If you decide
            to create an account with your email address, we receive your email address to send you
            a login link, but stores only a hashed version of your email address.{' '}
          </li>
          <li>
            Technical data: We receive and store data about Room URLs and names; the type of device
            you use to interact with MyWorld Virtual, as well as its operating system, language, the
            name and version of browser; and other data to load and operate the Room.
          </li>
          <li>
            Interaction data: We receive data about your interactions with the MyWorld Virtual
            service itself such as the number of Rooms created, messages sent through or to
            third-party services like Slack and Discord, the maximum number of users in a particular
            room at one same time, the start and end time of a user’s interaction with MyWorld
            Virtual, the amount of time a user interacts with MyWorld Virtual through Virtual
            Reality, the first time in a particular month or day that a user begins to use MyWorld
            Virtual. MyWorld Virtual uses third party services to store and analyze these
            operational messages. We also use Google Analytics, which places a cookie on your
            device, to obtain metrics on how users engage with our websites. This helps us to
            improve site content.
          </li>
          <li>
            Website data: We use Google Analytics to better understand how you interact with MyWorld
            Virtual apps and websites. For example, we may use cookies to track de-identified
            information such as the number of MyWorld Virtual rooms you create or enter,
            interactions with buttons and menus, and session length. To consistently improve the
            MyWorld Virtual experience, we collect information about browser type and version,
            viewport size, and screen resolution. This allows us to understand how people interact
            with MyWorld Virtual so we can improve it. We also collect your location, and the
            language setting in your browser to make sure it looks right for you. If you’d prefer to
            opt out of Google Analytics data collection, you can install the Google Analytics
            Opt-out Browser Add-on, which stops data transmission to Google Analytics.
          </li>
          <li>
            Error Data: In order to diagnose problems, MyWorld Virtual sends MyWorld Virtual logs of
            error messages (which include the Room URL, response time for requests, the page you
            were on when you encountered the error, your operating system, browser information, and
            may include your IP address).
          </li>
        </ul>
      </Section>
    </Page>
  );
}
