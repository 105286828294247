import * as React from 'react';
import Home from 'pages/Home';
import Login from 'pages/Login';
import Signup from 'pages/Signup';
import Profile from 'pages/Profile';
import MwgHandler from 'pages/MwgHandler';
import MemberPortal from 'pages/MemberPortal';
import { RouteComponentProps } from 'react-router';
import CitizensOfMyWorld from 'pages/CitizensOfMyWorld';
import Subscribe from 'pages/Subscribe';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import TermsAndConditions from 'pages/TermsAndConditions';
type Route = {
  name: string;
  path: string;
  exact: boolean;
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
};

export const commonRoutes: Array<Route> = [
  {
    name: 'home',
    path: '/',
    exact: true,
    component: Home,
  },
  {
    name: 'signup',
    path: '/signup',
    exact: true,
    component: Signup,
  },
  {
    name: 'login',
    path: '/login',
    exact: true,
    component: Login,
  },
  {
    name: 'portal',
    path: '/portal',
    exact: true,
    component: MemberPortal,
  },
  {
    name: 'mwg',
    path: '/mwg',
    exact: true,
    component: MwgHandler,
  },
  {
    name: 'privacy-policy',
    path: '/privacy-policy',
    exact: true,
    component: PrivacyPolicy,
  },
  {
    name: 'terms-and-conditions',
    path: '/terms-and-conditions',
    exact: true,
    component: TermsAndConditions,
  },
];

export const authRoutes: Array<Route> = [
  {
    name: 'citizens',
    path: '/citizens',
    exact: true,
    component: CitizensOfMyWorld,
  },
  {
    name: 'subscribe',
    path: '/subscribe',
    exact: true,
    component: Subscribe,
  },
  {
    name: 'profile',
    path: '/profile',
    exact: true,
    component: Profile,
  },
];

export const routes: Array<Route> = [...commonRoutes, ...authRoutes];

type RouteObject = { [f in string]: Route };

export const toObject = (routeArray: Array<Route>): RouteObject => {
  const object: RouteObject = {};
  routeArray.forEach((route) => {
    object[route.name] = route;
  });

  return object;
};

export const toPaths = (routeArray: Array<Route>): Array<string> => {
  return [...routeArray.map((r) => r.path), ...routeArray.map((r) => `${r.path}/`)];
};
