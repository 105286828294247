import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import Flex from 'components/Flex';
import { breakpointStyle } from 'utils/style';
export interface IContainer extends HTMLAttributes<HTMLDivElement> {
  thumbnail?: string;
  base?: 'dynamic' | number;
  size?: 'small' | 'medium';
}

const containerStyle = css<IContainer>`
  background-image: url(${(props) => props.thumbnail});
  background-size: cover;
`;

const containerSizing = ({ base, size }: IContainer) => {
  if (base == 'dynamic') {
    if (size == 'small') {
      return css`
        width: 278px;
        height: 170px;
        margin-right: 30px;
        margin-bottom: 30px;
      `;
    }
    return css`
      width: 365px;
      height: 223px;
      margin-right: 35px;
    `;
  } else if (typeof base == 'number') {
    return css`
      width: calc((100% / ${base}) - 35px);
      padding-top: calc((((100% / ${base}) - 35px) * 56.25) / 100);
      margin-right: 35px;
    `;
  }
};

export const RoomContainer = styled(Flex)<IContainer>`
  border: 1px solid #2cad52;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  margin-bottom: 35px;
  ${containerSizing}
  ${(props) => props.thumbnail && containerStyle}
  ${breakpointStyle({
    content: css`
      width: 100%;
      padding-top: 56.25%;
      margin-right: 0;
    `,
  })}
`;

RoomContainer.defaultProps = {
  base: 'dynamic',
};

export interface IHubContainer {
  data?: Array<any>;
  alignment?: 'fill' | 'stack' | 'center';
  columns?: 'dynamic' | number;
  size?: 'small' | 'medium';
  showDetails?: boolean;
}

export const Container = styled(Flex).attrs({
  direction: 'row-responsive',
  wrap: 'wrap',
})<IHubContainer>`
  max-width: ${({ size }) => (size == 'small' ? 640 : 1600)}px;
  ${breakpointStyle({
    content: css`
      align-self: auto;
    `,
  })}
`;
