import { observer } from 'mobx-react';
import React from 'react';
import { Container, IHubContainer } from './styled';
import Room from '../Room';
import Scene from '../Scene';

export const RoomContainer = observer((props: IHubContainer) => {
  return (
    <Container
      justifyContent="space-between"
      alignSelf={props.columns === 'dynamic' && props.alignment == 'center' ? 'center' : 'auto'}
      size={props.size}
    >
      {props.data &&
        props.data.map((entry) => (
          <Room
            key={`room-${entry.hubsId}`}
            base={props.columns}
            size={props.size}
            room={entry}
            showDetails={props.showDetails}
          />
        ))}
    </Container>
  );
});

export const SceneContainer = observer((props: IHubContainer) => {
  return (
    <Container
      size={props.size}
      justifyContent="space-between"
      alignSelf={props.columns === 'dynamic' && props.alignment == 'center' ? 'center' : 'auto'}
    >
      {props.data &&
        props.data.map((entry) => (
          <Scene
            scene={entry}
            key={`scene-${entry.hubsId}`}
            base={props.columns}
            size={props.size}
            showDetails={props.showDetails}
          />
        ))}
    </Container>
  );
});

export default Room;
