import React, { useEffect } from 'react';
import Button from 'components/Button';
import Flex, { IFlex } from 'components/Flex';
import { FormGroup, FormInput, FormLabel } from 'components/Input';
import { observer } from 'mobx-react';
import SignUpStore from './store';
import RecaptchaHolder from 'components/RecaptchaHolder';
import { authStore } from 'stores';
import OtpForm, { OtpContainer } from '../OtpForm';
import { ObservedFormGroup } from '../Form';
import { LineSeparator } from 'components/Divider';
import { Paragraph, Subtitle } from 'components/Typography';
import { InnerLink } from 'components/Link';
interface ISignUpForm {
  containerProps?: IFlex;
}

let store: SignUpStore = new SignUpStore();

const SignUpForm = observer((props: ISignUpForm) => {
  useEffect(() => {
    return () => {
      store = new SignUpStore();
    };
  }, []);

  return (
    <Flex as="form" onSubmit={store.onSubmit} direction="column" {...props.containerProps}>
      {Object.keys(store.form.fields)
        .slice(0, 5)
        .map((key, index) => {
          if (index % 2 === 0) {
            return (
              <Flex key={`form-group${index}`} direction="row-responsive">
                <ObservedFormGroup store={store} field={key} labelFromPlaceholder />
                <ObservedFormGroup
                  store={store}
                  field={Object.keys(store.form.fields)[index + 1]}
                  labelFromPlaceholder
                />
              </Flex>
            );
          }
        })}
      <Flex direction="row-responsive">
        <Flex flex={1} />
        <Flex flex={1}>
          <Paragraph size="sm" margin="0">
            For login purposes only, MyWorld will NEVER, share or sell your data.
          </Paragraph>
        </Flex>
      </Flex>
      <LineSeparator alignment="horizontal" margin={{ vertical: 30 }} />
      <Flex direction="column">
        <Subtitle margin={{ vertical: 0 }}>Referrer Tag</Subtitle>
        <Paragraph size="sm" margin={{ top: 0, bottom: 25 }}>
          Were you referred by a friend? Add their username below so we can thank them!
        </Paragraph>
        <ObservedFormGroup store={store} field={'referrer_tag'} labelFromPlaceholder />
      </Flex>
      <Flex direction="row" alignItems="center" margin={{ top: 22, bottom: 8 }}>
        <FormInput required id="age_confirmation" type="checkbox" value="age" />
        <FormLabel htmlFor="age_confirmation">I confirm I am over 13 years old.</FormLabel>
      </Flex>
      <Flex direction="row" alignItems="center" margin={{ top: 22, bottom: 8 }}>
        <FormInput required id="terms_confirmation" type="checkbox" value="terms" />
        <FormLabel htmlFor="terms_confirmation">
          I have read and approved the{' '}
          <InnerLink href="/terms-and-conditions" target="_blank" color="#2cad52">
            terms and conditions
          </InnerLink>
        </FormLabel>
      </Flex>
      <Flex direction="row-responsive" margin={{ top: 14 }}>
        <FormGroup flex={1}>
          <Button wide={false} as="input" type="submit" value="Sign Up" />
        </FormGroup>
        <FormGroup flex={1} />
      </Flex>
    </Flex>
  );
});

const SignUpContainer = observer(() => {
  return (
    <>
      <RecaptchaHolder />
      {!authStore.isWaitingOtp ? (
        <SignUpForm />
      ) : (
        <OtpContainer signup={true}>
          <OtpForm registrationData={store.serializeData()} />
        </OtpContainer>
      )}
    </>
  );
});

export default SignUpContainer;
