import { action, computed, makeObservable, observable } from 'mobx';
import Validator from 'validatorjs';
import Api from 'services/api';

export type fieldType = {
  value: any;
  error: boolean | string;
  rule: string | Array<string>;
  type?: string;
  placeholder?: string;
  list?: string;
  listData?: Array<any>;
};

export type formType = { fields: { [f in string]: fieldType } };

Validator.register(
  'phone',
  function (value) {
    if (typeof value === 'string') {
      return /^(\+\d{3})\(?\d{3,6}\)?\d{3}$/.test(value);
    } else return false;
  },
  'Please enter a valid phone number',
);

const profileUrlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,63}\b([-a-zA-Z0-9@:%_\+.~#?&/=]*\/(?=.{5,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?![_.]))/i;
const socialNameRegex = /^(?=.{3,20}$)[a-zA-Z0-9]*$/;
const gamerTagRegex = /^(?=.{3,20}$)(?![_.#])(?!.*[_.#]{2})[a-zA-Z0-9._#\-@]+(?![_.#])$/;

Validator.register(
  'social_media_username',
  function (value) {
    if (typeof value === 'string') return socialNameRegex.test(value);
    return false;
  },
  'A :attribute may contain letters and numbers. [3-20]',
);

Validator.register(
  'social_media',
  function (value) {
    if (typeof value === 'string')
      return profileUrlRegex.test(value) || socialNameRegex.test(value);
    return false;
  },
  'Please enter a valid social media username or your profile url',
);

Validator.registerAsync(
  'gamer_tag',
  async function (value, attribute, req, passes) {
    if (typeof value === 'string') {
      if (!socialNameRegex.test(value))
        passes(false, 'A username may contain letters and numbers. [3-20]');
      else {
        const resp = await Api.checkAvailability({ gamer_tag: value });
        if (resp.ok) {
          if (resp.data) passes(true);
          else passes(false, `${value} already exists`);
        } else {
          passes(false, resp.data as string);
        }
      }
    }
  },
  'Please enter a valid social media username or your profile url',
);

Validator.registerAsync(
  'referrer_tag',
  async function (value, attribute, req, passes) {
    if (typeof value === 'string') {
      if (!gamerTagRegex.test(value)) passes(false, 'Please enter a valid :attribute');
      else {
        const resp = await Api.checkAvailability({ gamer_tag: value });
        if (resp.ok) {
          if (!resp.data) passes(true);
          else passes(false, `${value} doesn't exist`);
        } else {
          passes(false, resp.data as string);
        }
      }
    }
  },
  'Please enter a valid social media username or your profile url',
);

Validator.registerAsync(
  'registration_email',
  async function (value, attribute, req, passes) {
    if (typeof value === 'string') {
      const resp = await Api.checkAvailability({ email: value });
      if (resp.ok) {
        if (resp.data) passes(true);
        else passes(false, `${value} already exists`);
      } else {
        passes(false, resp.data as string);
      }
    }
  },
  'Please enter a valid social media username or your profile url',
);

Validator.registerAsync(
  'unregistered_phone',
  async function (value, attribute, req, passes) {
    if (typeof value === 'string') {
      const resp = await Api.checkAvailability({ phone_number: value });
      if (resp.ok) {
        if (resp.data) passes(true);
        else passes(false, `${value} already exists`);
      } else {
        passes(false, resp.data as string);
      }
    }
  },
  'Please enter a valid phone number',
);

Validator.registerAsync(
  'registered_phone',
  async function (value, attribute, req, passes) {
    if (typeof value === 'string') {
      const resp = await Api.checkAvailability({ phone_number: value });
      if (resp.ok) {
        if (resp.data) passes(false, `${value} is not registered`);
        else passes(true);
      } else {
        passes(false, resp.data as string);
      }
    }
  },
  'Please enter a valid phone number',
);

abstract class ValidationBaseStore {
  form: formType = {
    fields: {},
  };

  get isValid(): boolean {
    const errors = Object.keys(this.form.fields).map((field) => {
      return this.form.fields[field].error;
    });
    return !errors.find((e) => e !== false);
  }

  constructor() {
    makeObservable(this, {
      form: observable,
      isValid: computed,
      onFieldChange: action.bound,
      validateForm: action.bound,
    });
  }

  onFieldChange = (field: string, element: any) => {
    if (this.form.fields[field]) {
      this.form.fields[field].value = element.target.value;
      const values: any = {};
      const rules: any = {};
      values[field] = this.form.fields[field]?.value;
      rules[field] = this.form.fields[field]?.rule;

      const validation = new Validator(values, rules);

      const passes = () => {
        this.form.fields[field].error = validation.errors.first(field);
        return;
      };
      validation.checkAsync(passes, passes);
      // this.form.fields[field].error = validation.errors.first(field);
    }
  };

  validateForm = () => {
    const values: any = {};
    const rules: any = {};
    Object.keys(this.form.fields).forEach((key) => {
      values[key] = this.form.fields[key]?.value;
      rules[key] = this.form.fields[key]?.rule;
    });
    const validation = new Validator(values, rules);
    const assignErrors = () =>
      Object.keys(this.form.fields).forEach((field) => {
        this.form.fields[field]
          ? (this.form.fields[field].error = validation.errors.first(field))
          : null;
      });
    const passes = () => {
      assignErrors();
    };
    validation.fails(passes);
  };

  bindInput = (field: string) => {
    const { value, type, placeholder, list, listData, rule } = this.form.fields[field];
    return {
      list,
      type,
      value,
      listData,
      placeholder,
      required: rule.includes('required'),
      onChange: (e: any) => {
        this.onFieldChange(field, e);
      },
    };
  };

  abstract onSubmit: (e: any) => void;
}

export const isUrl = (str: string) => profileUrlRegex.test(str);
export default ValidationBaseStore;
