import { makeAutoObservable, action, flow, runInAction } from 'mobx';
import Api from '../services/api';
import firebase from '../config/firebase';
import { RootStore } from './index';
import { ApiResponse } from 'apisauce';
import { IRegBody } from 'types/user';
import { v4 as uuid } from 'uuid';
import { UserStore } from './domain/user';
import { isIFrame } from '../utils/iframe';
import { hubsAuthModalStore, hubsAuthPopupModalStore } from './ui/modal';

export class AuthStore {
  userId = 0;
  phoneNumber = '';
  user?: UserStore;
  token = '';
  parent: RootStore;
  isWaitingOtp = false;
  isLoading = false;
  isAuthenticating = false;
  fbInstance?: firebase.auth.ConfirmationResult;
  // Captcha
  refCaptcha?: any;
  reCaptcha?: firebase.auth.RecaptchaVerifier;
  reCaptchaKey?: string;
  firebaseError: string | null = null;
  isInitialized = false;

  constructor(parent: RootStore) {
    makeAutoObservable(this);
    this.parent = parent;
    if (!Api.client) {
      Api.createNewApi();
    }

    this.hookFirebaseListener();
  }

  get isLoggedIn(): boolean {
    return !!this.token;
  }

  get role(): string {
    return this.user ? this.user.role : 'anonymous';
  }

  get isAdmin(): boolean {
    return this.role === 'admin' || this.role === 'super_admin';
  }

  @action.bound
  resetCaptcha() {
    try {
      this.reCaptchaKey = uuid();
      this.firebaseError = null;
    } catch (e) {
      console.log('Captcha reset failed', e);
    }
  }

  initAuthCaptcha(): firebase.auth.RecaptchaVerifier {
    return new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      size: 'invisible',
    });
  }

  hookFirebaseListener = () => {
    firebase.auth().onIdTokenChanged((user) => {
      if (user) {
        user.getIdToken(true).then((token) => {
          runInAction(() => {
            this.firebaseError = null;
            this.token = token;
            Api.addAuthHeader(this.token);
            if (!isIFrame && !this.user) this.fetchUserInfo();
            this.parent.subscriptionStore.verifySubscription();
            this.isInitialized = true;
          });
        });
      } else {
        this.isInitialized = true;
      }
    });
  };

  requestOTP = flow(function* (this: AuthStore, phoneNumber: string) {
    if (!this.isAuthenticating) {
      this.firebaseError = null;
      this.isAuthenticating = true;
      try {
        this.resetCaptcha();
        this.reCaptcha = this.initAuthCaptcha();
        this.phoneNumber = phoneNumber;
        this.fbInstance = yield firebase
          .auth()
          .signInWithPhoneNumber(this.phoneNumber, this.reCaptcha);
        this.isWaitingOtp = true;
      } catch (e) {
        console.error('request OTP failed', e);
        this.firebaseError = 'Please enter a valid phone number.';
      } finally {
        this.isAuthenticating = false;
      }
    }
  });

  verifyOtp = flow(function* (
    this: AuthStore,
    otp: string,
    registrationData?: IRegBody,
    onVerificationFail?: () => void,
  ) {
    try {
      if (this.fbInstance) {
        const res = yield this.fbInstance.confirm(otp);
        if (res?.user) {
          const token = yield res.user.getIdToken();
          if (token) {
            this.token = token;
            Api.addAuthHeader(token);
            if (registrationData) {
              registrationData.firebase_token = this.token;
              yield this.registerUser(registrationData);

              // Only authenticate to Hubs if in production
              // and not an iFrame to prevent any loop
              // if (!isIFrame && isProduction) {
              //   yield hubsAuth.authenticate(this.userId);
              // }
              hubsAuthModalStore.close();
              hubsAuthPopupModalStore.close();
              this.parent.navStore.history.replace('/subscribe');
            } else {
              yield this.fetchUserInfo();

              // Only authenticate to Hubs if in production
              // and not an iFrame to prevent any loop
              // if (!isIFrame && isProduction) {
              //   yield hubsAuth.authenticate(this.userId);
              //   // this.parent.navStore.history.replace('/portal');
              // }
              hubsAuthModalStore.close();
              hubsAuthPopupModalStore.close();
              this.parent.navStore.history.replace('/portal');
            }
            this.isAuthenticating = false;
            this.isWaitingOtp = false;
          }
        }
      }
    } catch (e) {
      if (onVerificationFail) onVerificationFail();
      this.firebaseError = 'The OTP you entered could not be authenticated. Please try again.';
    }
  });

  fetchUserInfo = flow(function* (this: AuthStore) {
    if (!this.isLoading) {
      this.isLoading = true;
      const resp: ApiResponse<any> = yield Api.getUserInfo();
      if (resp.ok) {
        const { id } = resp.data;
        this.userId = id;
        this.user = new UserStore(this, resp.data);
        this.isWaitingOtp = false;
      }
      this.isLoading = false;
    }
  });

  registerUser = flow(function* (this: AuthStore, data: IRegBody) {
    this.isLoading = true;
    const resp: ApiResponse<any> = yield Api.registerUser(data);
    if (resp.ok) {
      const { id } = resp.data;
      this.userId = id;
      this.user = new UserStore(this, resp.data);
      this.isWaitingOtp = false;
    }
    this.isLoading = false;
  });

  logoutWithoutRedirection = () => {
    firebase.auth().signOut();
    this.phoneNumber = '';
    this.user = undefined;
    this.token = '';
    Api.clearToken();
    this.parent.subscriptionStore.clearSubscriptionStatus();
  };

  logout = () => {
    firebase.auth().signOut();
    this.phoneNumber = '';
    this.user = undefined;
    this.token = '';
    Api.clearToken();
    this.parent.navStore.replace('/');
    this.parent.subscriptionStore.clearSubscriptionStatus();
  };
}
