import React from 'react';
import Router from './router';
import { appStore } from './stores';
import { observer } from 'mobx-react';
import { isIFrame } from './utils/iframe';
import GlobalStyle from './theme/globalStyle';
import ModalContainer from './modals';
import { Helmet } from 'react-helmet';
import logo from './assets/images/inmyworld.png';
if (!isIFrame) appStore.initialize();

const logoUri =
  (process.env.REACT_APP_ENV === 'staging'
    ? 'https://storkparties.com'
    : 'https://myworldvirtual.com') + logo;

const App = observer(() => {
  return (
    <>
      <Helmet
        titleTemplate="%s, Interactive Internet Where Virtuality Meets Actuality"
        defaultTitle="MyWorld, Interactive Internet Where Virtuality Meets Actuality"
      >
        <meta property="og:locale" content="en_US" />
        <meta property="og:image" content={logoUri} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="200" />
        <meta property="og:image:height" content="200" />
        <meta property="og:image:alt" content="MWG-Logo" />
        <meta property="og:locale:alternate" content="en_GB" />
        <meta property="og:site_name" content="MyWorld" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://myworldvirtual.com" />
        <meta
          name="description"
          content="Over 100 of our public Destinations, Events & Meetups. View 360 VR Videos & Web 3D On Any Device. Create VR With Our Scene Editor Tools"
        />
        <meta
          property="og:description"
          content="Over 100 of our public Destinations, Events & Meetups. View 360 VR Videos & Web 3D On Any Device. Create VR With Our Scene Editor Tools"
        />
        <meta
          name="keywords"
          content="Video Chat, Voice Chat, Text Chat, 3D environmnet, meetups, virtual events, Stream movies, stream video, stream media, VR, Create VR, 3D scene editor, 360 VR Video, Zoom fatigue, Zoom equivalent."
        />
      </Helmet>
      <GlobalStyle />
      <Router />
      <ModalContainer />
    </>
  );
});

export default App;
