import { AuthStore } from './auth';
import RoomStore from './domain/rooms';
import SceneStore from './domain/scenes';
import { NavStore } from './nagivation';
import AppStore from './app';
import UsersStore from './domain/users';
import { SubscriptionStore } from './subscription';

export class RootStore {
  authStore: AuthStore;
  roomStore: RoomStore;
  sceneStore: SceneStore;
  usersStore: UsersStore;
  navStore: NavStore;
  appStore: AppStore;
  subscriptionStore: SubscriptionStore;

  constructor() {
    this.authStore = new AuthStore(this);
    this.roomStore = new RoomStore(this);
    this.sceneStore = new SceneStore(this);
    this.usersStore = new UsersStore(this);
    this.navStore = new NavStore(this);
    this.appStore = new AppStore(this);
    this.subscriptionStore = new SubscriptionStore(this);
  }
}

const rootStore = new RootStore();
declare global {
  interface Window {
    mwg: any;
  }
}

window.mwg = window.mwg || {};
window.mwg.rootstore = rootStore;

export default rootStore;
export const authStore = rootStore.authStore;
export const roomStore = rootStore.roomStore;
export const sceneStore = rootStore.sceneStore;
export const usersStore = rootStore.usersStore;
export const navStore = rootStore.navStore;
export const appStore = rootStore.appStore;
export const subscriptionStore = rootStore.subscriptionStore;
