import styled, { css } from 'styled-components';
import bg from 'assets/images/background.svg';
import { breakpointStyle } from 'utils/style';

interface IPageContainer {
  isHome?: boolean;
  withHeader?: boolean;
}

/**
 * @category Styled Component
 */
const PageContainer = styled.div<IPageContainer>`
  background-image: url(${bg});
  background-size: cover;
  min-height: 100%;
  display: block;

  & section:first-of-type {
    padding-top: 173px;
    box-sizing: border-box;
    ${breakpointStyle({
      content: css`
        padding-top: 110px;
      `,
    })}
  }

  & section:last-of-type {
    padding-bottom: 80px;
    ${breakpointStyle({
      content: css`
        padding-bottom: 170px;
      `,
    })}
  }
`;

export default PageContainer;
