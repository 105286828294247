import React from 'react';
import { observer } from 'mobx-react';
import Flex from 'components/Flex';
import Page from 'containers/Page';
import SignUpForm from 'containers/SignUpForm';
import Section, { BACKGROUNDS } from 'components/Section';
import { Paragraph, RetroTitle } from 'components/Typography';
import { Helmet } from 'react-helmet';

const Signup = observer(() => {
  return (
    <Page>
      <Helmet>
        <title>Join MyWorld</title>
      </Helmet>
      <Section bg={BACKGROUNDS.matrix} container>
        <Flex direction="row-responsive" padding={{ vertical: 60, mobile: { vertical: 0 } }}>
          <Flex flex={1} flexMobile={0} />
          <Flex flex={1}>
            <Flex maxWidth={600} padding={{ mobile: { horizontal: 24 } }}>
              <RetroTitle margin="0" size="sm">
                JOIN THE VIRTUAL REVOLUTION
              </RetroTitle>
            </Flex>
            <Flex>
              <Paragraph size="sm" margin={{ top: 5, bottom: 30 }}>
                Enjoy a 7-day All-Access membership for free! No Credit Card Required.
              </Paragraph>
              <SignUpForm />
            </Flex>
          </Flex>
        </Flex>
      </Section>
    </Page>
  );
});

export default Signup;
