// TODO: SET APPRORIATE NAMING WITH DESIGNER

const card = {
  bg: '#060941',
  shadowColor: '#2CAD52',
  borderPrimary: '#2CAD52',
  borderSecondary: '#2CAD52',
};

const text = {
  primary: 'rgba(255, 255, 255, 1)',
  secondary: 'rgba(0, 0, 0, 1)',
  success: '#2CAD52',
  error: '#F1001E',
};

const background = {
  bg: '#421664',
};

const button = {
  primary: 'rgba(44, 173, 82, 1)',
  secondary: 'rgba(67, 66, 66, 1)',
};

const accordion = {
  bgPrimary: '#060941',
  bgSecondary: '#071B5C',
  shadowPrimary: '#0B3A99',
  shadowSecondary: '#2CAD52',
};

const sidebar = {
  bg: '#060941',
  test: 'rgba(11, 58, 153, 0.6)',
};

const colors = {
  card,
  text,
  button,
  sidebar,
  accordion,
  background,
};

export default colors;
