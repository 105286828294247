import React, { useEffect } from 'react';
import Card from './Components/Card';
import { observer } from 'mobx-react';
import Flex from 'components/Flex';
import Page from 'containers/Page';
import Section from 'components/Section';
import { RetroTitle } from 'components/Typography';
import { usersStore } from 'stores';
import { Helmet } from 'react-helmet';
import Search from './Components/Search';

const CitizensOfMyWorld = observer(() => {
  useEffect(() => {
    if (!usersStore.isInitialized) {
      usersStore.load();
    }
  }, []);

  return (
    <Page>
      <Helmet>
        <title>MyWorld Citizens</title>
      </Helmet>
      <Section direction="column" container>
        <RetroTitle margin={{ bottom: 70 }} align="center" alignMobile="center" size="sm">
          Global Citizens
        </RetroTitle>
        <Search />
        <Flex wrap="wrap" justifyContent="center" direction="row-responsive">
          {usersStore.data.map((user) => {
            return <Card key={user.id} model={user} />;
          })}
        </Flex>
      </Section>
    </Page>
  );
});

export default CitizensOfMyWorld;
