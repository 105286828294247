import React from 'react';
import HubAuthModal, { HubAuthPopupModal } from './HubAuthModal';
import LoginModal from './LoginModal';
import SceneModal from './SceneModal';

const ModalContainer = () => {
  return (
    <>
      <LoginModal />
      <SceneModal />
      <HubAuthModal />
      <HubAuthPopupModal />
    </>
  );
};

export default ModalContainer;
