import React from 'react';
import PageContainer from 'components/PageContainer';
import Header from '../Header';
import Footer from '../Footer';

interface IPage {
  isHome?: boolean;
  withHeader?: boolean;
  withFooter?: boolean;
  children?: any;
}

const Page = (props: IPage) => {
  return (
    <PageContainer {...props}>
      {props.withHeader ? <Header /> : null}
      {props.children}
      {props.withFooter ? <Footer size={props.isHome ? 'large' : 'medium'} /> : null}
    </PageContainer>
  );
};

Page.defaultProps = {
  withHeader: true,
  withFooter: true,
};

export default Page;
