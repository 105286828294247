import { observer } from 'mobx-react';
import React from 'react';
import Button from 'components/Button';
import Flex from 'components/Flex';
import Modal from 'components/Modal';
import { Title, Paragraph } from 'components/Typography';
import { LOGIN_MODAL_TYPE, loginUIStore } from 'stores/ui/modal';

const LoginModal = observer(() => {
  return (
    <Modal onClose={loginUIStore.close} show={loginUIStore.open}>
      <Modal.Header>
        <Title size="sm" margin="0">
          Login to access
        </Title>
      </Modal.Header>
      <Modal.Body>
        <Paragraph>
          {loginUIStore.type === LOGIN_MODAL_TYPE.subscribe
            ? 'You need to be logged in to subscribe'
            : 'The access to the Portals is exclusive to MyWorld members.'}
        </Paragraph>
      </Modal.Body>
      <Modal.Footer direction="row-responsive" justifyContent="space-between">
        <Flex padding={{ right: 15, mobile: { right: 0, bottom: 10 } }} width="100%">
          <Button wide={false} light onClick={() => loginUIStore.redirect('/login')}>
            Login
          </Button>
        </Flex>
        <Flex padding={{ left: 15, mobile: { left: 0, top: 10 } }} width="100%">
          <Button wide={false} onClick={() => loginUIStore.redirect('/signup')}>
            Join Us
          </Button>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
});

export default LoginModal;
