import styled, { css } from 'styled-components';
import separator_blue from 'assets/images/separator/blue.svg';
import separator_green from 'assets/images/separator/green.svg';
import { edgeStyle, EdgeStyleData } from 'utils/style';

type TColor = 'blue' | 'green';

interface IDivider {
  color?: TColor;
  margin?: EdgeStyleData;
  padding?: EdgeStyleData;
}

const getBackgroundImage = (color: TColor) => {
  return css`
    background-image: url(${color === 'blue' ? separator_blue : separator_green});
  `;
};

/**
 * @category Styled Component
 */
const Divider = styled.div<IDivider>`
  height: 136px;
  position: top;
  background-repeat: repeat;
  box-sizing: border-box;
  ${({ color }) => color && getBackgroundImage(color)};
  ${({ margin }) => margin && edgeStyle('margin', margin)};
  ${({ padding }) => padding && edgeStyle('padding', padding)};
`;

Divider.defaultProps = {
  color: 'blue',
  margin: { vertical: 100, mobile: { bottom: 0 } },
};

export default Divider;

interface ILineSeparator {
  alignment?: 'horizontal' | 'vertical';
  hasOpacity?: boolean;
  margin?: EdgeStyleData;
}

const styleHorizontal = () => css`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 24px;
`;

const styleVertical = () => css`
  width: 0;
  height: 100%;
  margin-right: 14px;
  margin-left: 14px;
`;

const styleOpacity = () => css`
  opacity: 0.2;
`;

/**
 * @category Styled Component
 */
export const LineSeparator = styled.div<ILineSeparator>`
  height: 0px;
  border: 1px solid #ffffff;
  ${({ alignment }) => (alignment === 'horizontal' ? styleHorizontal() : styleVertical())}
  ${({ hasOpacity }) => hasOpacity && styleOpacity()}
  ${(props) => props.margin && edgeStyle('margin', props.margin)}
`;

LineSeparator.defaultProps = {
  hasOpacity: true,
  alignment: 'vertical',
};
