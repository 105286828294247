import React from 'react';
import { authStore } from 'stores';
import { reaction } from 'mobx';
const hubsBase = process.env.REACT_APP_HUBS_URL;
console.log('[Hubs URL]', hubsBase);

const dispatch = (type: string, payload: any) => {
  if (hubsBase) window.parent.postMessage({ type, payload }, hubsBase);
};
window.addEventListener('message', (event) => {
  const { type } = event.data;
  switch (type) {
    case 'auth_token':
      dispatch('auth_token', authStore.token);
      break;
    case 'user':
      dispatch('user', authStore.user);
  }
});
const MwgHandler = () => {
  console.log('[Frame Status]', 'loaded');
  reaction(
    () => authStore.isInitialized,
    () => {
      if (authStore.isLoggedIn) dispatch('auth_token', authStore.token);
      else {
        dispatch('auth_token', null);
      }
      reaction(
        () => authStore.token,
        () => dispatch('auth_token', authStore.token),
      );
    },
  );
  reaction(
    () => authStore.user,
    (user) => {
      if (user) dispatch('user', user.serialize());
    },
  );
  return <></>;
};

export default MwgHandler;
