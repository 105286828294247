import React from 'react';
import Section from 'components/Section';
import Flex from 'components/Flex';
import { Span, Subtitle, Title } from 'components/Typography';
import colors from 'theme/colors';
import { BgContainer, CheckList, FooterParagraph, Logo } from './styled';
import { ShadowCard } from 'components/Cards';
import { LineSeparator } from 'components/Divider';
import surpriseIcon from 'assets/icons/surprise.svg';
import logo from 'assets/icons/mwg_logo.svg';
import { observer } from 'mobx-react';
import EnterTheMatrixButton from '../EnterTheMatrixButton';
import SubscriptionButton from '../SubscriptionButton';
import { authStore, subscriptionStore } from 'stores';

const AccessMembership = () => (
  <Flex padding={{ horizontal: 30, vertical: 22, mobile: { left: 27, right: 20 } }}>
    <Title align="center" size="sm">
      All-access membership
    </Title>
    <CheckList>
      <li>Custom Avatar, Profile and access to the MyWorld Global Community</li>
      <li>Unlimited private room creation and hosting permissions.</li>
      <li>
        Access to all of our current and future places, with new scenes and experiences added daily
      </li>
      <li>Video Chat & Screen Sharing capability in all private locations</li>
      <li>Add videos, music & images seamlessly into your private spaces</li>
      <li>Add 3D objects and models from Sketchfab, community projects or your private library</li>
      <li>
        Exclusive access to (G) Search 3D web browser - Curated, secure search results that enable
        members to surf, shop and play together
      </li>
      <li>Access to 24/7 Virtual Meetups & Public Social Spaces</li>
      <li>Exclusive Access to Public & Members only events</li>
      <li>Host or attend Live Events- Live Stream With Twitch - YouTube Live - Vimeo and more</li>
    </CheckList>
  </Flex>
);

const EarlyAccessBonus = () => (
  <Flex padding={{ horizontal: 30, vertical: 30, mobile: { left: 27, right: 20 } }}>
    <Flex direction="row" justifyContent="center">
      <img src={surpriseIcon} width={59} height={59} />
    </Flex>
    <Title align="center" size="sm" margin={{ bottom: 5 }}>
      Early access Bonus
    </Title>
    <Subtitle align="center" margin={{ top: 0 }}>
      Receive 12 months Create VR & Scene Editor tools at no extra cost
    </Subtitle>
    <CheckList>
      <li>Create New VR Spaces, Models & Experiences</li>
      <li>Customize and Remix our Existing projects to meet your needs and style.</li>
      <li>Access community photospheres, 360 backgrounds and community assets</li>
      <li>Access to Sketchfab and community 3D models</li>
      <li>Have your scene featured and shared with our entire Global Community</li>
      <li>Compete in Creator Challenges - Join us while we build the future together</li>
    </CheckList>
  </Flex>
);

const SubscriptionMemberShipStatus = () => {
  let text = '';
  switch (subscriptionStore.userPlan.toLowerCase()) {
    case 'trialing':
      text = '7-day All-Access Pass';
      break;
    case 'premium':
    case 'paid':
      text = 'All-Access';
      break;
    case 'unpaid':
    default:
      text = 'Free Plan';
      break;
  }

  return <Span color={colors.text.success}>{text}</Span>;
};

const SubscriptionSection = ({ isPage }: { isPage?: boolean }) => {
  return (
    <Section padding={!isPage ? '0px !important' : undefined} container={isPage}>
      <Flex direction="row-responsive">
        <Flex flex={1}>
          {isPage ? <Title size="sm">Welcome to MyWorld</Title> : null}
          <Subtitle size="sm">
            <Span color={colors.text.success}>At MyWorld, we value privacy over profit.</Span> We
            take internet privacy very seriously and are taking a stand against big data and the
            status quo. We offer a promise that{' '}
            <Span color={colors.text.success}>
              MyWorld will NEVER share or sell your data or personal information.
            </Span>
          </Subtitle>
          <Subtitle size="sm">
            <Span color={colors.text.success}>
              MyWorld Global is an independent studio in Brooklyn NY.
            </Span>
            &nbsp;We value our community and rely on the support of users like you. Thanks again and
            feel free to contact our founder for any reason or to join our team! Contact info is
            available on the Global Citizens page&nbsp;
            <Span color={colors.text.success}>@Romulus718</Span>
          </Subtitle>
          {authStore.isLoggedIn ? (
            <Subtitle size="sm">
              Membership Status = <SubscriptionMemberShipStatus />
            </Subtitle>
          ) : null}
          <Subtitle size="sm">
            <Span color={colors.text.success}>After your 7 day trial - </Span>You can still login
            and remain a part of our community. You will keep your Username and Avatar and are
            welcome to use our Public Spaces.
          </Subtitle>
          <Subtitle size="sm">
            To retain access to the member portal and your Create VR scene editor projects, return
            here and <Span color={colors.text.success}>subscribe at the bottom of this page!</Span>
            &nbsp;We hope to see you next week!
          </Subtitle>
          <Subtitle size="sm">Click here to get started & Enter the MyWorld Matrix</Subtitle>
          <Flex alignItems="flex-start" margin={{ top: 10 }}>
            <EnterTheMatrixButton />
          </Flex>
        </Flex>
        <BgContainer flex={1} />
      </Flex>
      <Flex direction="column" margin={{ top: 60, bottom: 120, mobile: { bottom: 70 } }}>
        <Flex margin={{ bottom: 30 }}>
          <Title size="sm" alignMobile="center">
            Benefits of a subscription:
            <small>
              <Span color={colors.text.success}>&nbsp;All Access Membership is $12.99 / month</Span>
              <small> cancel at anytime </small>
            </small>
          </Title>
        </Flex>
        <Flex direction="row-responsive">
          <Flex flex={1} margin={{ mobile: { bottom: 37 } }}>
            <ShadowCard>
              <AccessMembership />
            </ShadowCard>
          </Flex>
          <LineSeparator margin={{ horizontal: 37 }} />
          <Flex flex={1}>
            <ShadowCard>
              <EarlyAccessBonus />
            </ShadowCard>
          </Flex>
        </Flex>
      </Flex>
      <Flex alignItems="center">
        <Logo src={logo} />
        <Flex alignItems="center" maxWidth={650}>
          <FooterParagraph>We appreciate your business!</FooterParagraph>
          <FooterParagraph>
            MyWorld Global is a new, self funded, and independent studio in Brooklyn NY.
          </FooterParagraph>
          <FooterParagraph>
            We are extremely excited to begin this journey with you and look forward to delivering a
            truly interactive internet experience.
          </FooterParagraph>
          <FooterParagraph>The best is yet to come.</FooterParagraph>
        </Flex>
        <Flex
          direction="row"
          justifyContent="center"
          width="100%"
          padding={{ bottom: 150, top: 50 }}
        >
          <SubscriptionButton />
        </Flex>
      </Flex>
    </Section>
  );
};

SubscriptionSection.defaultProps = {
  isPage: false,
};

export default observer(SubscriptionSection);
