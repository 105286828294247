import React from 'react';
import { observer } from 'mobx-react';
import Flex from 'components/Flex';
import Button from 'components/Button';
import Tutorial from 'containers/Tutorial';
import { Paragraph, Title } from 'components/Typography';
import { RoomContainer } from 'containers/HubContainer';
import { roomStore, authStore, navStore } from 'stores';
import Section, { BACKGROUNDS } from 'components/Section';

const Welcome = observer(() => {
  return (
    <Section bg={BACKGROUNDS.matrixTop} container margin={{ bottom: 100 }}>
      <Title margin={{ bottom: 60, mobile: { bottom: 40 } }} align="center">
        Welcome to MyWorld Global
      </Title>
      <Flex>
        <Tutorial {...roomStore.tutorial} />
        <Flex alignItems="center" margin={{ bottom: 30, mobile: { bottom: 10 } }}>
          <Title size="md" margin="0" className="tk-raleway">
            Meet Me In MyWorld
          </Title>
          <Paragraph
            size="md"
            margin={{ top: 20, bottom: 0, mobile: { bottom: 0 } }}
            className="tk-raleway"
          >
            Select a Portal Below to Explore Hundreds of Public Destinations, Events, & Meetup
            Spaces
          </Paragraph>
          <Paragraph size="md" margin="0" className="tk-raleway">
            <ul style={{ display: 'inline-block', paddingLeft: 15 }}>
              <li>
                Discover &quot;Reality Virtually&quot; Visit Virtual Spaces in Actual Places. Travel
                Over 35 Cities and We’re Just Getting Started!
              </li>
              <li>
                Join for Free to Unlock Features: Video Chat, Screen Share, Add 3D objects &
                Customize your avatar
              </li>
              <li>
                Sign in and Visit the Member Portal to Open a Private Room With Any Scene in
                MyWorld.
              </li>
              <li>Invite Friends by Sharing the Room Url.</li>
            </ul>
          </Paragraph>
          {!authStore.isLoggedIn && (
            <Flex alignItems="center" minWidth="100%" margin={{ vertical: 60 }}>
              <Button onClick={() => navStore.push('/signup', true)}>Join Now</Button>
            </Flex>
          )}
        </Flex>
        <RoomContainer showDetails columns="dynamic" alignment="center" data={roomStore.featured} />
      </Flex>
      {!authStore.isLoggedIn && (
        <Flex alignItems="center" margin={{ top: 60 }}>
          <Button onClick={() => navStore.push('/signup', true)}>Join for Free</Button>
        </Flex>
      )}
    </Section>
  );
});

export default Welcome;
