import styled, { css } from 'styled-components';
import Flex from 'components/Flex';
import girlBg from 'assets/images/bg_girl_large.svg';
import faCheck from 'assets/icons/fa_check.svg';
import { breakpointStyle } from 'utils/style';
export const BgContainer = styled(Flex)`
  position: relative;
  :before {
    content: '';
    width: 100%;
    height: 140%;
    position: absolute;
    background-image: url('${girlBg}');
    background-repeat: no-repeat;
    background-size: auto auto;
    background-position-x: center;
    ${breakpointStyle({
      content: css`
        height: 332px;
        position: relative;
        margin-top: 70px;
      `,
    })}
  }
`;

export const CheckList = styled.ul`
  list-style: none;
  padding: 0;
  li {
    font-size: 16px;
    margin-bottom: 35px;
    display: flex;
  }

  li::before {
    content: url('${faCheck}');
    display: block;
    margin-right: 18px;
  }
`;

export const FooterParagraph = styled.p`
  font-size: 16px;
  text-align: center;
`;

export const Logo = styled.img`
  height: 105px;
  ${breakpointStyle({
    content: css`
      height: 50px;
    `,
  })}
`;
