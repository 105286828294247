import { observer } from 'mobx-react';
import React, { Fragment, ReactNode, useCallback } from 'react';
import Flex from 'components/Flex';
import { IconBefore } from 'components/Icon';
import { BoxInputGroup } from 'components/Input';
import { TextAction, Text, RetroTitle } from 'components/Typography';
import { authStore } from 'stores';
import colors from 'theme/colors';
import warning_icon from 'assets/icons/warning_red.svg';
import type { IRegBody } from 'types/user';

interface IOtpForm {
  registrationData?: IRegBody;
}

const OtpForm = observer((props: IOtpForm) => {
  const inp = React.createRef<BoxInputGroup>();
  const handleOTPReset = (e: any) => {
    e.preventDefault();
    authStore.requestOTP(authStore.phoneNumber);
  };

  const onCodeCompleted = (value: string) => {
    authStore.verifyOtp(value, props.registrationData, inp.current?.clear);
  };

  return (
    <Fragment>
      <Flex flex={1}>
        <Text size="md">To continue, enter the code you received.</Text>
        <Flex direction="row">
          <BoxInputGroup ref={inp} length={6} onFilled={onCodeCompleted} />
        </Flex>
        {authStore.firebaseError ? (
          <Flex margin={{ right: 25, vertical: 15 }} direction="row" alignItems="baseline">
            <IconBefore icon={warning_icon} />
            <Text margin="0" size="sm" color={colors.text.error}>
              {authStore.firebaseError}
            </Text>
          </Flex>
        ) : null}
        <Flex margin={{ top: 20 }}>
          <Text size="sm">
            Having trouble?
            <TextAction onClick={handleOTPReset}>Request a new OTP.</TextAction>
          </Text>
        </Flex>
      </Flex>
      <Flex flex={1} flexMobile={0} />
    </Fragment>
  );
});

interface IOtpContainer {
  signup: boolean;
  children?: ReactNode;
}

export const OtpContainer = observer((props: IOtpContainer) => {
  const text = props.signup ? 'Edit your registration info.' : 'Back to login and edit you number.';
  const handleEditForm = useCallback(() => {
    authStore.resetCaptcha();
    authStore.isWaitingOtp = false;
  }, []);

  return (
    <Flex direction="column" padding={{ right: 75, mobile: { right: 0 } }}>
      <RetroTitle size="sm">Verify</RetroTitle>
      <Text margin="0">A 6-digits verification code has been sent to</Text>
      <Text color={colors.text.success}>{authStore.phoneNumber}.</Text>
      <Text size="sm">
        Not your number?
        <TextAction onClick={handleEditForm}>{text}</TextAction>
      </Text>
      {props.children}
    </Flex>
  );
});

export default OtpForm;
