import React from 'react';
import { Paragraph, Subtitle } from 'components/Typography';
import styled from 'styled-components';

/**
 * @category Styled Component
 */
export default function List(props: any) {
  return <ul>{props.children}</ul>;
}

/**
 * @category Styled Component
 */
const ListItem = styled(Subtitle).attrs({ as: 'li', size: 'sm' })``;

List.Item = function Content({ children, ...rest }: any) {
  return <ListItem {...rest}>{children}</ListItem>;
};
List.Content = function Content({ children, ...rest }: any) {
  return (
    <Paragraph size="md" {...rest}>
      {children}
    </Paragraph>
  );
};
