import styled, { css } from 'styled-components';
import Flex from './Flex';
import { breakpointStyle } from 'utils/style';
import React, { InputHTMLAttributes, LabelHTMLAttributes } from 'react';
import colors from 'theme/colors';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';

export const FormGroup = styled(Flex).attrs({ direction: 'column' })<{
  error?: boolean | string;
  withMargin?: boolean;
}>`
  ${({ withMargin }) =>
    withMargin &&
    css`
      margin-right: 14px;
      margin-bottom: 14px;
    `}
  ${breakpointStyle({
    content: css`
      margin-right: 0;
    `,
  })}
  ${({ error }) =>
    error
      ? css`
          ${FormInput} {
            border: 2px solid #f1001e !important;
          }
          ${FormLabel} {
            color: #f1001e;
            :before {
              background-color: #f1001e;
            }
          }
        `
      : null}
`;

FormGroup.defaultProps = {
  withMargin: true,
};

/**
 * @category Styled Component
 */
export const FormLabel = styled.label<
  LabelHTMLAttributes<HTMLLabelElement> & { white?: boolean; required?: boolean }
>`
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 10px;
  color: ${colors.text.primary};
  ${({ required }) =>
    required &&
    css`
      ::after {
        content: '*';
      }
    `}

  ::before {
    content: '';
    margin-right: 5px;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: 1px;
    background-color: ${colors.text.primary};
  }
`;

/**
 * @category Styled Component
 */
export const FormInput = styled.input<InputHTMLAttributes<HTMLInputElement>>`
  ${(props) =>
    props.type && ['text', 'password', 'email', 'date', 'phone', 'country'].indexOf(props.type) > -1
      ? css`
          font-family: 'Poppins';
          padding: 8px 16px;
          font-size: 14px;
          line-height: 21px;
          border: 2px solid white;
          box-sizing: border-box;
          outline: none;

          :focus {
            border: 2px solid #2cad52;
          }
        `
      : props.type && props.type == 'checkbox'
      ? css`
          //appearance: none;
          position: relative;
          width: 24px;
          height: 20px;
          margin: 0;
          border-radius: 0;
          &:before {
            position: absolute;
            top: 0;
            left: 0;
            content: ' ';
            width: 20px;
            height: 20px;
            border: 1px ${colors.button.primary} solid;
            background-color: white;
          }

          &:checked:after {
            position: absolute;
            width: 12px;
            height: 22px;
            transform: rotateZ(45deg);
            box-sizing: border-box;
            content: '';
            top: -2px;
            left: 6px;
            border-bottom: 6px solid ${colors.button.primary};
            border-right: 6px solid ${colors.button.primary};
          }

          & ~ ${FormLabel} {
            color: white;
            margin-left: 8px;
            margin-bottom: 0;

            :before {
              display: none;
            }
          }
        `
      : null}
`;

export interface IBoxInput extends InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
}

const errorStyle = css<IBoxInput>`
  border: ${({ error }) => (error ? `2px solid #F1001E;` : `none;`)};
`;

/**
 * @category Styled Component
 */
export const BoxInput = styled.input<IBoxInput>`
  width: 40px;
  height: 40px;
  font-size: 18px;
  line-height: 27px;
  margin-right: 12px;
  text-align: center;
  ${({ error }) => error && errorStyle}
  &:disabled {
    background-color: white;
    color: black;
    border-style: solid;
    border-color: white;
  }
`;

export interface IBoxInputGroup {
  length: number;
  onChange?: (value: string) => void;
  onFilled?: (value: string) => void;
}

@observer
export class BoxInputGroup extends React.Component<IBoxInputGroup, any> {
  inputs = observable([...Array(this.props.length).fill('')]);

  public clear = action(() => {
    this.inputs.forEach((i, index) => (this.inputs[index] = ''));
    this.adaptFocus();
  });

  private onKeyPress = (index: number) =>
    action((e: any) => {
      const { key } = e;

      if (key === 'Backspace' && index > 0) {
        if (this.inputs[index] === '') {
          const domElement = document.getElementById(`otp-${index - 1}`);
          if (domElement !== null) {
            // Hack to fix moving cursor
            this.inputs[index - 1] = '';
            domElement.focus();
            this.triggerCallbacks();
          }
        }
      } else {
        const reg = new RegExp('\\b[0-9]\\b');
        if (!reg.test(key)) {
          e.preventDefault();
        } else {
          if (this.inputs[index].length > 0) {
            e.preventDefault();
          }
        }
      }
    });

  private onChange = (index: number) =>
    action((e: any) => {
      const { value } = e.target;
      this.inputs[index] = value;
      const domElement = document.getElementById(`otp-${this.inputs.indexOf('')}`);
      if (domElement !== null) {
        domElement.focus();
      }

      this.triggerCallbacks();
    });

  private triggerCallbacks = () => {
    const totalValue = this.inputs.join('');
    if (this.props.onChange !== undefined) {
      this.props.onChange(totalValue);
    }

    if (totalValue.length === this.props.length) {
      if (this.props.onFilled !== undefined) {
        this.props.onFilled(this.inputs.join(''));
      }
    }
  };

  private adaptFocus = () => {
    const domElement = document.getElementById(`otp-${this.inputs.indexOf('')}`);
    if (domElement !== null) {
      domElement.focus();
    } else {
      const lastElement = document.getElementById(`otp-${this.inputs.length - 1}`);
      if (lastElement !== null) {
        lastElement.focus();
      }
    }
  };

  render() {
    return (
      <>
        {this.inputs.map(({}, index: number) => (
          <BoxInput
            key={`otp-${index}`}
            value={this.inputs[index]}
            id={`otp-${index}`}
            onKeyDown={this.onKeyPress(index)}
            onChange={this.onChange(index)}
            autoFocus={index === 0}
            onClick={this.adaptFocus}
          />
        ))}
      </>
    );
  }
}
