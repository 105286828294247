import React from 'react';
import Flex from 'components/Flex';
import {
  CloseButton,
  HeaderContainer,
  HeaderLogo,
  MenuButton,
  MobileLink,
  MobileNavContainer,
  MobilNavSubContainer,
} from './styled';
import Link, { RouterLink, HomeLink } from 'components/Link';
import { observer } from 'mobx-react';
import { appStore, authStore, navStore } from 'stores'; //removed subscriptionStore
import { withRouter } from 'react-router';
import Container from 'components/Container';
import profileIcon from 'assets/icons/profile.svg';

import { FaSignInAlt, FaUserAstronaut } from 'react-icons/fa';

const MobileNav = withRouter(
  observer(({ match }: { match: any; location: any; history: any }) => {
    return (
      <MobileNavContainer active={appStore.menuActive}>
        <MobilNavSubContainer>
          <Flex padding={24}>
            <CloseButton onClick={appStore.closeMenu} />
          </Flex>
          <Flex direction="column" padding={27}>
            <MobileLink as={RouterLink} isActive={() => match.path === '/'} to="/">
              Home
            </MobileLink>
            {authStore.isAdmin ? (
              <MobileLink
                onClick={() => {
                  navStore.push('/admin/', true);
                }}
              >
                Admin
              </MobileLink>
            ) : null}
            <MobileLink as={RouterLink} to="/portal">
              Member Portal
            </MobileLink>
            <MobileLink as={RouterLink} to="/citizens">
              Global Citizens
            </MobileLink>
            {!authStore.isLoggedIn ? (
              <>
                <MobileLink as={RouterLink} to="/profile" icon={profileIcon}>
                  Profile
                </MobileLink>
                <MobileLink as={RouterLink} to="/login">
                  Login
                </MobileLink>
                <MobileLink as={RouterLink} to="/signup">
                  Sign Up
                </MobileLink>
              </>
            ) : (
              <>
                <MobileLink as={RouterLink} to="/profile" icon={profileIcon}>
                  Profile
                </MobileLink>
                <MobileLink as={RouterLink} to="/subscribe">
                  Subscribe
                </MobileLink>
              </>
            )}
            {authStore.isLoggedIn ? (
              <>
                <MobileLink onClick={authStore.logout}>Logout</MobileLink>
              </>
            ) : null}
          </Flex>
        </MobilNavSubContainer>
        <Flex flex={1} onClick={appStore.closeMenu} />
      </MobileNavContainer>
    );
  }),
);

const Header = observer(() => {
  return (
    <>
      <MobileNav />
      <HeaderContainer
        padding={{
          vertical: 60,
          mobile: { vertical: 24 },
        }}
        direction="row"
      >
        <Container direction="row-responsive">
          <Flex hide="desktop" direction="row" alignItems="center">
            <Flex flex={1}>
              <MenuButton onClick={appStore.openMenu} />
            </Flex>
            <Flex flex={1}>
              <HomeLink to="/">
                <HeaderLogo />
              </HomeLink>
            </Flex>
            <Flex flex={1} />
          </Flex>

          <Flex hide="mobile" direction="row" alignItems="center" justifyContent="start">
            <HomeLink to="/">
              <HeaderLogo />
            </HomeLink>
          </Flex>

          <Flex
            hide="mobile"
            flex={1}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            wrap="wrap"
          >
            {authStore.isAdmin ? (
              <Link
                onClick={() => {
                  navStore.push(process.env.REACT_APP_ADMIN_URL || '/admin/', true);
                }}
              >
                Admin
              </Link>
            ) : null}
            <Link
              onClick={() => {
                navStore.push(`${process.env.REACT_APP_HUBS_URL}/spoke/`, true);
              }}
            >
              Create VR
            </Link>
            <Link as={RouterLink} to="/portal">
              Member Portal
            </Link>
            <Link as={RouterLink} to="/citizens">
              Global Citizens
            </Link>
            <Link as={RouterLink} to="/profile">
              <FaUserAstronaut size={20} />
              Profile
            </Link>
            {!authStore.isLoggedIn ? (
              <>
                <Link as={RouterLink} to="/login">
                  Login
                </Link>
                <Link as={RouterLink} solid="true" to="/signup">
                  <FaSignInAlt size={20} />
                  Sign Up
                </Link>
              </>
            ) : null}
            {authStore.isLoggedIn ? (
              <>
                <Link as={RouterLink} to="/subscribe">
                  Subscribe
                </Link>
              </>
            ) : null}
          </Flex>
        </Container>
      </HeaderContainer>
    </>
  );
});

export default Header;
