import type { JsonObject } from 'types/general';
import { AuthStore } from '../auth';
import { makeObservable, observable } from 'mobx';
import { Resource } from 'services/api';
import { UserModel } from './users';

const service = new Resource('users');

interface ISubscription {
  id: number;
  status: string;
}

export class UserStore extends UserModel {
  firstName = '';
  state = '';
  email = '';
  role = '';
  subscription?: ISubscription;

  constructor(parent: AuthStore, data?: JsonObject) {
    super(parent);
    makeObservable(this, {
      email: observable,
      firstName: observable,
      state: observable,
      role: observable,
      subscription: observable,
    });
    if (data) this.consume(data);
    this.bindService(service);
  }

  serialize: () => JsonObject = () => {
    return {
      email: this.email,
      gamer_tag: this.gamerTag,
      info: {
        first_name: this.firstName,
        city: this.city,
        country: this.country,
        state: this.state,
        social_media: this.socialMedia,
      },
      subscription: this.subscription,
    };
  };

  consume(data: JsonObject) {
    super.consume(data);
    this.role = data.role;
    this.email = data.email;
    const { info } = data;
    this.firstName = info.first_name;
    this.state = info.state;
    this.subscription = data.subscription;
  }

  bindService(service: Resource) {
    super.bindService(service);
    this.loader = () => service.get('me');
  }
}
