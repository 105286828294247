import { observer } from 'mobx-react';
import { authStore, navStore } from 'stores';
import { Route, Switch, Router as ReactRouter } from 'react-router-dom';
import NotFound from 'pages/404';
import React from 'react';
import { authRoutes, commonRoutes } from './routes';

const Router = observer(() => {
  return (
    <ReactRouter history={navStore.history}>
      <Switch>
        {commonRoutes.map((route) => (
          <Route key={`routes-${route.name}`} {...route} />
        ))}
        {authStore.isLoggedIn
          ? authRoutes.map((route) => <Route key={`routes-${route.name}`} {...route} />)
          : null}
        <Route path="/404" exact component={NotFound} />
        <Route component={NotFound} />
      </Switch>
    </ReactRouter>
  );
});

export default Router;
