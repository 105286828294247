import countries from 'assets/json/countries.json';

/**
 * Get countries as objects without modifications
 *
 * @returns array of country names
 * @category Country Helpers
 */
export const getFlatCountryNames = () => countries.map(({ name }) => name);
export const getCountryNames = () => countries.map(({ name }) => ({ key: name, value: name }));
export const getCountryExtNames = () => {
  const list = new Array<{ key: string; value: string; text: string }>();

  // TODO: Clean up this loops.
  countries.forEach(({ callingCodes, name }) => {
    callingCodes.forEach((ext) => {
      if (ext) {
        list.push({
          value: `+${ext}`,
          key: `+${ext} (${name})`,
          text: `+${ext} (${name})`,
        });
      }
    });
  });

  return list;
};
export const getCountryExtension = (name: string) => {
  return countries.filter((country) => country.name === name)[0].callingCodes[0];
};

export const countryNames = getFlatCountryNames().join(',');
