import React from 'react';
import Button from 'components/Button';
import { observer } from 'mobx-react';

const EnterTheMatrixButton = observer(() => {
  return (
    <Button
      onClick={() => {
        window.location.assign(
          'https://app.myworldvirtual.com/scenes/9rAvVxg/enter-the-myworld-matrix',
        );
      }}
      disabled={false}
    >
      {'Enter The MyWorld Matrix'}
    </Button>
  );
});

export default EnterTheMatrixButton;
