import React from 'react';
import Page from 'containers/Page';
import Section from 'components/Section';
import { withRouter } from 'react-router';
import { authRoutes, toPaths } from 'router/routes';
import notFound from 'assets/icons/not_found.svg';
import { Subtitle } from 'components/Typography';
import Flex from 'components/Flex';
import Button from 'components/Button';
import { navStore } from 'stores';
const authRoutePaths = toPaths(authRoutes);

const NotFound = withRouter(({ location }) => {
  if (!authRoutePaths.includes(location.pathname))
    return (
      <Page>
        <Section alignItems="center" justifyContent="center">
          <img alt="Not found icon" src={notFound} />
          <Flex maxWidth={300} margin={{ vertical: 30 }}>
            <Subtitle align="center" size="sm">
              We can’t seem to find the page you are looking for.
            </Subtitle>
          </Flex>
          <Button onClick={() => navStore.push('/')}>Back to Home page</Button>
        </Section>
      </Page>
    );
  return (
    <Page>
      <Section />
    </Page>
  );
});

export default NotFound;
